<template>
    <v-dialog persistent max-width="500px" v-model="show">
        <v-card>
            <v-card-title>
                <span class="headline">Confirm Impersonate</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="mockEmail" label="Email" />
                <v-text-field v-model="mockAgency" label="Account" />
                <v-text-field v-model="mockAgencyGroup" label="Parent Account" />
                <div class="mt-3">
                    <v-menu ref="startDateEdit" v-model="isShowEditMockDate" :close-on-content-click="false"
                        :return-value.sync="mockDate
                            " transition="scale-transition" offset-y max-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="mockDate
                                " label="Simulate Login Date" prepend-icon="mdi-calendar-clock" v-bind="attrs"
                                v-on="on" dense autocomplete="off" @click="resetTime" />
                        </template>
                        <v-date-picker :min="new Date()
                            .toISOString()
                            .substr(0, 10)
                            " v-model="mockDate
        " @input="
        mockDate = changeDatetime(
            mockDate
        )
        " color="duo-primary" no-title>
                            <div class="d-flex flex-column" style="width: 100%">
                                <div class="d-flex flex-row align-space-arround">
                                    <span>Time</span>
                                    <v-select v-model="hour" :items="Array(24)
                                        .fill()
                                        .map((x, i) =>
                                            (
                                                '' + i
                                            ).padStart(
                                                2,
                                                '0'
                                            )
                                        )
                                        " class="mx-2" style="width: 10px" dense append-icon="false" @input="
        mockDate = changeDatetime(
            mockDate
        )
        ">
                                        <template v-slot:selection="{
                                            item,
                                        }">
                                            <span v-if="item" class="d-flex justify-center pl-6" style="width: 100%">{{ item
                                            }}</span>
                                        </template> </v-select>:
                                    <v-select v-model="minute" :items="Array(60)
                                        .fill()
                                        .map((x, i) =>
                                            (
                                                '' + i
                                            ).padStart(
                                                2,
                                                '0'
                                            )
                                        )
                                        " class="mx-2" style="width: 10px" dense append-icon="false" @input="
        mockDate = changeDatetime(
            mockDate
        )
        ">
                                        <template v-slot:selection="{
                                            item,
                                        }">
                                            <span v-if="item" class="d-flex justify-center pl-6" style="width: 100%">{{ item
                                            }}</span>
                                        </template> </v-select>:
                                    <v-select v-model="second" :items="Array(60)
                                        .fill()
                                        .map((x, i) =>
                                            (
                                                '' + i
                                            ).padStart(
                                                2,
                                                '0'
                                            )
                                        )
                                        " class="mx-2" style="width: 10px" dense append-icon="false" @input="
        mockDate = changeDatetime(
            mockDate
        )
        ">
                                        <template v-slot:selection="{
                                            item,
                                        }">
                                            <span v-if="item" class="d-flex justify-center pl-6" style="width: 100%">{{ item
                                            }}</span>
                                        </template>
                                    </v-select>
                                </div>
                                <v-divider></v-divider>
                                <div class="d-flex justify-end pa-1">
                                    <v-btn small width="20px" class="mr-2" color="duo-secondary" @click="
                                    isShowEditMockDate = false
                                        ">Cancel</v-btn>
                                    <v-btn small width="20px" color="duo-primary" @click="
                                        $refs.startDateEdit.save(
                                            (mockDate = changeDatetime(
                                                mockDate
                                            ))
                                        )
                                        ">OK</v-btn>
                                </div>
                            </div>
                        </v-date-picker>
                    </v-menu>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onCloseDialog">Cancel</v-btn>
                <v-btn :disabled="mockAgency.length == 0 && mockEmail.length == 0 && mockAgencyGroup.length == 0 && mockDate == null" @click="onClickConfirm" color="primary">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/* eslint-disable no-console */

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mockEmail: "",
            mockAgency: "",
            mockAgencyGroup: "",
            mockDate: null,
            isShowEditMockDate: false,
            hour: "00",
            minute: "00",
            second: "00",
        }
    },
    methods: {
        resetTime() {
            this.hour = "00";
            this.minute = "00";
            this.second = "00";
        },
        changeDatetime(dateTime) {
            if (dateTime === undefined || dateTime === "") {
                return new Date().toISOString().substr(0, 10) + " " + this.hour + ":" + this.minute + ":" + this.second;
            } else {
                return dateTime.substr(0, 10) + " " + this.hour + ":" + this.minute + ":" + this.second;
            }
        },
        onCloseDialog() {
            this.$emit("onCloseDialog");
        },
        onClickConfirm() {
            let url = window.location.href + "?";
            if (this.mockEmail.length > 0) {
                url += ("email=" + this.mockEmail + '&');
            }

            if (this.mockAgency.length > 0) {
                url += ("agency=" + this.mockAgency + '&');
            }

            if (this.mockAgencyGroup.length > 0) {
                url += ("agencyGroup=" + this.mockAgencyGroup + '&');
            }

            if (this.mockDate != null && this.mockDate.length > 0) {
                url += ("date=" + this.mockDate + '&');
            }

            if (url.endsWith('&')) {
                url.substring(0, url.length - 1);
            }


            window.location.href = url;
        },
    },
}
</script>