<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1296px; padding: 0 0 0 0">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <div :style="{ backgroundPosition: '50% 50%', height: '476px', width: '100%', maxWidth: '1271px', backgroundImage: 'url(' + $bucket + '/image/dashboard-tiles/Homepage.' + agency.replace(' ', '') + '.png', backgroundSize: 'cover' }">
            <div style="font-size: 25px; padding-top: 103px; padding-left: 61px; padding-bottom: 9px">
              <span><b>Kia ora</b> {{ agency }}</span>
            </div>
            <div style="margin-top: -40px; height: 126px; font-size: 118px; padding-left: 53px; font-family: 'AirNZ Black Italic'">
              <span>Sell, log and win</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-col cols="12" style="padding: 0 100px 34px 20px">
        <h1>Sell, log and WIN!</h1>
        <p>To celebrate the launch of <b>duo</b>, we’re rewarding {{ agency }} consultants for every North America ticket issued and logged through duosales between 2-29 May 2022, and we’ll put the cash back in your pocket.</p>
        <h3>Here’s what’s up for grabs:</h3>
        <ul>
          <li>$50 per Economy return ticket</li>
          <li>$75 per Premium Economy return ticket</li>
          <li>$100 per Business Premier return ticket</li>
        </ul>
        <p>Flights must be departing and returning to Australia, operated by Air New Zealand to all of our North America destinations including Hawaii. Includes both adult and children tickets, excluding infants.</p>
        <p>
          Throughout 2022, we’ll be flying to even more destinations in North America, connecting your customers from Australia via Auckland to more places more frequently, in fact over 100 destinations in North America. Recently awarded safest
          airline in the world for 2022 by AirlineRatings.com, your customers will reach new heights of comfort onboard and experience the best in Kiwi hospitality, arriving in North America refreshed and ready to explore.
        </p>
        <p>To refresh and upskill on everything North America with Air New Zealand, and test your knowledge, complete our <a @click="onStartTraining('50332')">North America training module.</a></p>
        <h3>Hurry, this epic giveaway ends on 29 May 2022.</h3>
        <p style="font-size: 14px; padding-top: 42px">
          <b>Terms and conditions apply</b> Valid for tickets issued and logged during the campaign period 2 May-29 May 2022. Tickets logged outside the campaign period will be ineligible for entry. Tickets must be issued on 086 ticket stock as
          return itineraries departing and arriving in Australia on Air New Zealand operated services. Includes children and adults, excluding infants. Includes flights to all Air New Zealand operated North America destinations including Honolulu,
          Los Angeles, San Francisco, Houston, Chicago, New York, and Vancouver. Agents will be rewarded with a bounty to be paid in the form of a gift card at the discretion of Air New Zealand within one month of the incentive period ending. Reward
          will be for every valid booking by way of AU$50 per Economy return ticket. AU$75 per Premium Economy return ticket and AU$100 per Business Premier return ticket. Air New Zealand will review bookings on conclusion of campaign and manage the
          distribution of the bounty paid out in the form of a gift card at Air New Zealands’ discretion. Prizes are non transferable and any FBT implications are the responsibility of the winning agent. We recommend you seek your own independent tax
          advice to assess any impact.
        </p>
      </v-col>

      <v-dialog v-model="dialogRetake" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>Finish</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">You have finished this training</h2>
            <h2 class="text-h6 my-4">Your Score: {{ finalScore }}</h2>
          </v-card-text>

          <v-card-actions class="justify-center pb-6">
            <v-btn @click="handleRetakeModule()" class="duo-primary">Retake</v-btn>
            <v-btn @click="handleReviewModule()" class="duo-secondary">Review</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<style scoped>
h1 {
  padding-top: 34px;
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 17px;
}

ul {
  padding-top: 36px;
  padding-bottom: 21px;
  padding-left: 60px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";
import axios from "axios";

export default {
  components: {
    subHeader
  },
  mounted() {
    if (this.$router.currentRoute.path.includes("/travelManagers")) {
      this.agency = "TravelManagers";
    }
    window.scrollTo(0, 0);
  },
  data() {
    return {
      agency: "MTA Travel"
    };
  },
  methods: {
    onStartTraining(id) {
      this.moduleId = id;
      this.checkPersonScore();
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push(`/training/module/${this.moduleId}/`);
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push(`/training/module/${this.moduleId}/`);
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.dialogRetake = true;
          } else {
            this.$router.push(`/training/module/${this.moduleId}/`);
          }
        })
        .catch(() => {});
    }
  }
};
</script>