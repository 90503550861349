<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px; overflow: hidden;">
      <v-row class="d-flex justify-center text-center">
        <iframe src="https://x.wayin.com/display/container/dc/2b1aae75-d372-4cd0-b355-5b8f157b37dd?mode=adaptive" frameborder="0" scrolling="no" width="100%" height="600" id="ngxFrame2b1aae75-d372-4cd0-b355-5b8f157b37dd" allowTransparency="true"></iframe>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
