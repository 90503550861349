<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/UA - Week 3 - Landing Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            Air New Zealand, United Airlines and Brand USA are excited to launch a four-week sales and training incentive to drive sales to the USA.
          </h2>
          <p>
            Welcome to week three of Air New Zealand, United Airlines and Brand USA 's Passport to Possibilities! Keep logging those USA sales and complete a Brand USA discovery programme badge to earn points!
          </p>
          <p>
            Agents must log any 086 ticketed booking to the USA in duosales and complete a Brand USA Discovery programme badge to be in to win.
          </p>
          <p>
            Scroll down to check out our week three incentive!
          </p>
          <p class="pt-3 pb-2">
            <b>Sounds good right?</b>
          </p>
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;" class="pl-12">
            <p>
              <b>To enter in our Grand Prize, you must:</b>
            </p>
            <ul >
              <li style="margin-top: 10px; padding-left: 10px;">
                Book, and ticket flights to the USA on either Air New Zealand or United Airlines, on 086 ticket stock only, between 29 October and 24 November 2024. 
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Agents must register their ticketed Air New Zealand booking references through Duosales.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Each ticket issued will earn you one point.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Bookings logged on behalf of another agent are ineligible for entry
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Agents must enter the correct PNR creation date for the entry to be valid.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Booked travel may be availed for any time.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Participants must also sign up and complete at least one badge on USA Discovery Program (Brand USA's Training Platform). Platinum agents that have already completed all the badges will need to retrain in one of the badges and/or use the retail/ sell features on the platform to qualify.
              </li>
            </ul>
            <p style="font-size: 13px" class="pt-2">
              <a
                style="font-size: 13px; color: black; font-style: italic"
                :href="$window.location.origin + '/#/page/unitedAirlinesWeek1Tc'"
                target="_blank"
                >Terms and Conditions apply.
              </a>
            </p>
          </div>
          <br />

          <!-- Discover North America Training Module -->
          <div style="width: 1266px !important; padding-bottom: 1rem">
            <v-row class="d-flex">
              <duo-training-tile 
                          style="margin-left: 4px; max-width: 872px;"
                          @onClickEdit="showEditDialog(item)" :map="item.map"
                          @onClickHide="showDialogConfirmation('unpublish', item.id)"
                          v-for="item in tileList" :img="item.image" :title="item.title" :content="item.content"
                          :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :link="item.link"
                          :status="getModuleStatus(item.link)" @onClickButton="onStartTraining(item.link, item.title)" />
              
            </v-row>
            <v-row class="d-flex pt-5">
              <v-col class="pa-2 mx-3" style="height: 376px; background-color: #221651;">
                <div class="white--text d-flex flex-column justify-center px-6" style="height: 100%;">
                  <p>
                    Week Three Incentive
                  </p>
                  <h2>
                    <b>Welcome to week three of Air New Zealand, United Airlines and Brand USA 's Passport to Possibilities!</b>
                  </h2>
                  <p style="height: 30px;">
                    Keep logging those USA sales and complete a Brand USA discovery programme badge to earn points! Agents must log any 086 ticketed booking to the USA in duosales and complete a Brand USA Discovery programme badge to be in to win.
                  </p>
                  <p>
                    Week three also brings another chance to win. Complete the new United Airlines module to be in to win 1 of 5 lots of 100 Airpoints Dollars!
                  </p>
                  <p style="font-size: 13px" class="pt-3">
                    <a
                      style="font-size: 13px; color: white; font-style: italic"
                      :href="$window.location.origin + '/#/page/unitedAirlinesWeek1Tc'"
                      target="_blank"
                      >Terms and Conditions apply.
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-row>
      <br /> <br /> 

      <!-- California -->
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/UA - Week 3 - Spotlight.Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2 style="height: 15px;">
              <b>Discover the Magic of Anaheim</b>
          </h2>
          <p style="font-size: 20px;">
            California
          </p>
          
          <v-row>
            <v-col
              v-for="tile in 3"
              :key="tile.title"
              cols="4"
              md="4"
              sm="12"
            >
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Week 3 - California.Image'+ (tile)+'.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <p class="pt-5">
            <b>Anaheim, only 1 hour south of LAX, gateway to Southern California with Air New Zealand is home to the original Disneyland Park. It is the quintessential basecamp for the perfect Southern California holiday, offering enchanting attractions, sunny beaches, and endless entertainment for all. This is a dream destination for families, couple's and thrill-seekers alike.</b>
          </p>
          <p>
            <b>Downtown Anaheim:</b> At the heart of the city, you'll find Centre Street Promenade and the Packing District. Artisan eateries featuring everything from smoked barbecue to vegan offerings in the historical Anaheim Packing House, a citrus packing plant turned food hall. The marketplace setting makes for a great lunch or dinner stop with many options available for all to enjoy. With several Anaheim Packing House businesses on the free Anaheim Savings Pass, make sure your clients know how to sign up to enjoy a deal.
          </p>
          <p>
            <b>Theme Parks:</b> Anaheim's theme parks, including Disneyland Park and Disney California Adventure Park, along with Knotts Berry Farm the original theme park, offer enchanting attractions, thrilling rides, and magical experiences for all ages. Based in Anaheim you can easily day trip to Universal Studios, or to San Diego to visit Legoland, Sea World or the San Diego Zoo.         
          </p>
          <p>
            <b>Anaheim Sports:</b> With easy access and freely available tickets, experiencing a sporting event in Anaheim is trouble-free. During the American summer, see the Angels' Baseball® based out of the Angels Stadium - always a fun and interactive event. Anaheim Ducks® is the local ice hockey winter sport and located at the easily accessible Honda Centre.
          </p>
          <p>
            <b>Anaheim Specialist Program:</b> The Anaheim Specialist Program is designed to help you learn more about Anaheim and the greater Orange County region - your base for a Southern California Holiday.
          </p>
          <p>
            Click on the below link to gain access to this program which will give you modules on what makes Anaheim a unique and attractive destination, along with insider tips and tricks.
          </p>
          <p>
            <a href="https://www.anaheimspecialist.com/login" target="_blank" style="color: black;"><u>https://www.anaheimspecialist.com/login</u></a>
          </p>
          <p>
            Anaheim's central location makes it an excellent base for exploring Southern California. The stunning beaches of Orange County, the vibrant city of Los Angeles, and the picturesque mountains are all within a short distance. Whether your clients are looking for adventure, culture, or relaxation, Anaheim has something for everyone.
          </p>

          <h2 class="pt-4">
              <b>See more of the USA with Alamo</b>
          </h2>
          <p>
            Alamo Rent A Car brings fun, freedom, and adventure to travellers heading to the USA! Known for its friendly, award-winning service, Alamo makes exploring new destinations easy and exciting. With over 240 locations across the US, you can find an Alamo depot at all major airports, downtown locations and a wide range of rural locations too. Alamo is all about convenience, affordability, and giving renters more time to enjoy the journey.
          </p>
          <p>
            Alamo's fleet is crafted to suit every kind of traveller. Whether you're after a zippy compact car to breeze through city streets, a roomy sedan for comfort on long road trips, or an SUV perfect for family adventures, Alamo has you covered.
            <br />
            Families and groups can spread out in Alamo's spacious SUVs and minivans, with extra storage for bags, souvenirs, and road trip snacks!
          </p>
          <p>
            For travellers craving a touch of luxury, Alamo's premium and luxury vehicles offer sleek designs and high-end interiors that make any trip feel like a special occasion. Outdoor lovers and adventurers can choose rugged pick-up trucks and utility vehicles, built for exploring off the beaten path or carrying extra gear.
          </p>
        </div>
      </v-row>
      <!-- Expedia TAAP -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;" class="pl-12">
            <v-row>
              <v-col cols="3">
                <v-card tile elevation="0">
                  <v-img
                    :src="$bucket + '/image/dashboard-pages/UA - Expedia TAAP.png'"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="9">
                <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                  <p style="height: 30px;"> 
                    <b>For the Luxury seekers - 63% of Expedia TAAP bookings to the USA are booking through 4-5 Star Accommodation rating and that's a growing sector.</b>
                  </p>
                  <p>
                    Learn more <a href="https://www.expediataap.co.nz/" target="_blank" style="color: black;"><u>here!</u></a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-row>
      <br /><br /><br />
      <!-- Discover Open Road -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <v-row>
            <v-col cols="8">
              <h2>
                Discover the Open Road with Brand USA's Road Trips USA Collection!
              </h2>
              <p class="pt-2">
                <b>Get ready to inspire your clients to explore the diverse landscapes of the USA with Brand USA's exciting Road Trips USA collection. Featuring a wealth of trip inspiration and essential travel information the Brand USA self-drive itineraries will empower your clients to confidently hit the highways and byways.</b>
              </p>
            </v-col>
            <v-col cols="4">
              <v-img
                width="200px"
                :src="$bucket + '/image/dashboard-pages/UA - VisitTheUSA.png'"
                class="ml-6"
              ></v-img>
            </v-col>
          </v-row>
          <br />
          <!-- The collection includes 38 -->
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 1.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                <p style="height: 60px;"> 
                  The collection includes 38 self-drive itineraries available as downloadable PDFs, perfect for sharing with your clients. Each road trip begins and ends in a gateway city, tailored specifically for international travellers.
                </p>
                <p>  
                  To make planning even easier, driving times and distances are provided in kilometres-ideal for New Zealand customers. Plus, each itinerary is fully customisable, allowing clients to select their start and end points and choose routes based on their preferred trip length.<br />From cultural-themed road trips to multi-state journeys, historic trails and scenic wanders, these 38 inspired itineraries will set your clients on a path to discover the USA.
                </p>
              </div>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Nature lovers -->
          <v-row>
            <v-col cols="7">
              <div class="black--text d-flex flex-column justify-center" style="height: 100%;">
                <p> 
                  <b>Nature lovers</b> will be on a natural high on the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Rocky%20Mountains-Great%20Plains.pdf" target="_blank" style="color: black;"><u>Rocky Mountains & the Great Plains</u></a>, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/ENGLISH_Mountains-Glaciers-Parks_Global.pdf" target="_blank" style="color: black;"><u>Alaska - Mountains, Glaciers & Parks</u></a> 
                      and 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_CoastsAndMountains.pdf" target="_blank" style="color: black;"><u>Coasts & Mountains of the South itineraries.</u></a>
                  <br /><br />
                  For otherworldly landscapes, encourage them to hit the road from Las Vegas, Nevada on the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Canyons-Mesas-Hoodoos.pdf" target="_blank" style="color: black;"><u>Canyons, Mesas & Hoodoos</u></a>, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/RoadTripsUSA_VolcanicPeaksSparklingShores_ENG.pdf" target="_blank" style="color: black;"><u>Volcanic Peaks to Sparkling Shores</u></a>
                    in Oregon and Washington and 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Geological%20Wonders.pdf" target="_blank" style="color: black;"><u>Geological Wonders</u></a>
                    in Utah, Wyoming and Idaho.
                  <br /><br />
                  Steer forest bathers to the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_National-parks-American%20India%20culture.pdf" target="_blank" style="color: black;"><u>National Parks & American Indian Cultures</u></a> 
                    roundtrip from Phoenix, Arizona or the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_GiantTreesAncientForests.pdf" target="_blank" style="color: black;"><u>Giant Trees & Ancient Forests</u></a> 
                    loop from San Francisco, California.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 2.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Music Loving Clients -->
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 3.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                <h2>
                  Music-loving clients?
                </h2>
                <p> 
                  Inspire a deep dive into America's songbook with the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Bourbon-Bluegrass%20and%20Bloodstock.pdf" target="_blank" style="color: black;"><u>Bourbon, Bluegrass & Horse Country</u></a> 
                    roundtrip from Nashville, Tennessee. There's also the eight-state 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Musical%20Roots%20of%20the%20South.pdf" target="_blank" style="color: black;"><u>Musical Roots of the South</u></a> 
                    odyssey from Dallas, Texas to Charleston, South Carolina.
                  <br /> <br />
                  Road-trippers can also follow the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Rhythms%20of%20the%20River.pdf" target="_blank" style="color: black;"><u>Rhythms of the River</u></a>, 
                    tracing the Mississippi from Chicago, Illinois down to New Orleans, Louisiana through eight states. Or embark on a 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_OnTheShoresOfTheGreatLakes.pdf" target="_blank" style="color: black;"><u>Great Lakes loop</u></a> 
                    through Wisconsin, Minnesota and Michigan from Chicago.
                </p>
              </div>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Stars in their eyes -->
          <v-row>
            <v-col cols="7">
              <div class="black--text d-flex flex-column justify-center" style="height: 100%;">
                <h2>
                  Stars in their eyes?
                </h2>
                <p> 
                  They'll be awestruck by silver screen destinations on the fly-and-drive 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_At%20the%20Movies.pdf" target="_blank" style="color: black;"><u>At The Movies</u></a> 
                    cross-country trip from NYC to LA. And ogle the bright lights and big-city sights of 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_IconicCitiesBeyond.pdf" target="_blank" style="color: black;"><u>Iconic Cities & Beyond</u></a> 
                    from Boston, Massachusetts to Washington, D.C.
                  <br /><br />
                  For a slice of small-town life, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Quaint%20New%20England%20Towns.pdf" target="_blank" style="color: black;"><u>Quaint New England Towns</u></a> 
                    through Massachusetts, New Hampshire, Maine, Vermont, Connecticut and Rhode Island serves up the scenery and charm we love from TV and movies.
                  <br /><br />
                  Classic road trippers and foodies can tick off the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Route66-TexasHill_Country.pdf" target="_blank" style="color: black;"><u>Route 66 & Texas Hill Country</u></a> or 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/RoadTripsUSA_HistoricRoute66_ENG%20%283%29_0.pdf" target="_blank" style="color: black;"><u>Historic Route 66 - Curios, Pit Stops & Diners</u></a> 
                    itineraries from their bucket list. Meanwhile, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_TheCivilRights-HistoryTrail.pdf" target="_blank" style="color: black;"><u>The Civil Rights History Trail</u></a> 
                    puts place-making landmarks of the South into context.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 4.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <br /> <br />
          <!-- More Road trips -->
          <p>
            <b>More road trips inspiration can be found <a href="https://traveltrade.visittheusa.com.au/USAItineraries" target="_blank" style="color: black;"><u>here</u></a> </b>
          </p>
          <p>
            Brand USA's official Visit the USA travel trade site is equipped with everything you need to sell travel to the USA with confidence.
          </p>
          <p>
            <b>
              You'll find Road Trip USA itineraries, tour operators and destination contacts along with general US travel info including visa and entry policies and getting around: <a href="https://traveltrade.visittheusa.com" target="_blank" style="color: black;"><u>traveltrade.visittheusa.com</u></a>
            </b>
          </p>
        </div>
      </v-row>
      <br /><br /><br />
      <!-- Become a USA expert -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="background-color: #ffffff; margin-bottom:1.5vw; padding:1.5vw;" class="px-12">
            <h2 class="pt-9">
              Become a USA expert AND win with Brand USA and Air New Zealand USA Discovery Program!
            </h2>
            <p class="pt-3">
              From 29 October to 20 November, every badge completed on Brand USA's Discovery Program will earn an entry into a draw to win incredible prizes or to attend the Air New Zealand VIP training day.
            </p>
            <p>
              There are over 60-plus badges to earn across all regions and states. Once you have completed the Brand USA badge training, you can download and add badge icons to any client communications, demonstrating their USA expertise.
            </p>
            <v-row>
              <v-col
                v-for="tile in 4"
                :key="tile.title"
                cols="3"
                md="3"
                sm="3"
              >
                <v-card tile elevation="0">
                  <v-img
                    :src="$bucket + '/image/dashboard-pages/UA - Badge '+ (tile)+'.png'"
                    width="200px"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <p>
              The Brand USA training platform lets you enhance your USA knowledge in four key ways: earn badges, access valuable resources, share inspiring content with clients, and utilise specialised tools to boost your sales.
            </p>
            <p>
              <b>
                Don't miss out - access the USA Discovery Program <a href="https://usadiscoveryprogram.co.nz/main" target="_blank" style="color: black;"><u>here</u></a>
              </b>
            </p>
            <br />
          </div>
        </div>
      </v-row>

    </v-container>

    <v-dialog v-model="dialogRetake" max-width="400">
      <v-card class="mx-auto" max-width="400">
        <v-toolbar flat color="#221551" dark>
          <v-toolbar-title>{{moduleStatus == 'Resume' ? 'Resume' : 'Finish' }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <h2 class="text-h6 my-4">{{ moduleStatus == 'Resume' ? 'You haven\'t finished this training yet' : 'You have finished this training' }}</h2>
          <h2 class="text-h6 my-4" v-if="moduleStatus != 'Resume'">Your Score: {{ finalScore }}</h2>
        </v-card-text>

        <v-card-actions class="justify-center pb-6">
          <v-btn @click="handleRetakeModule()" class="duo-primary">Retake</v-btn>
          <v-btn @click="handleReviewModule()" class="duo-secondary">{{moduleStatus == 'Resume' ? 'Resume' : 'Review'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Training Module is disabled -->
    <v-dialog v-model="dialogModuleDisable" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">The training module is disabled</h2>
          </v-card-text>

          <v-card-actions class="justify-center pb-6">
            <v-btn @click="closeDialogModule" class="duo-primary">Cancel</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";
import duoTrainingTile from './creator/duoTrainingTile.vue';
import axios from "axios";

export default {
  components: {
    subHeader,
    duoTrainingTile
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getList();
    if (this.$store.state.principal.administrator == false) {
      this.listCompletedModule();
    }
  },
  data() {
    return {
      retakeModule: false,
      dialogRetake: false,
      moduleId: 0,
      moduleStatus: "",
      finalScore: 0,
      showScore: false,
      dialogModuleDisable: false,
      tileList: [],
      completedModuleList: [],
      choosenTitle: "",
    };
  },
  methods: {
    getList() {
      axios
          .get("/system/tile/training")
          .then((response) => {
              this.tileList = response.data.filter(function (el) {
                  return el.sequence;
              })
              this.tileList = response.data.filter(function (el) {
                  if (window.location.origin.includes("duo.airnzagent.co.nz")) {
                    return el.link === "231001"; //UA Module training module linkid on production
                  } else {
                    return el.link === "148008";
                  }
              })
          })
          .catch(() => { });
    },
    listCompletedModule() {
      axios
        .get("/system/score/completed")
        .then((response) => {
          this.completedModuleList = response.data;
        }).catch(() => { });
    },
    onStartTraining(id, title) {
      this.choosenTitle = title;
      this.moduleId = id;
      // axios.get('/system/trainingModule/list').then((response) => {
      axios.get('/system/trainingModule/list/all').then((response) => {
        const res = response.data.find((v) => Number(v.id) === Number(id))
        if (res.active) {
          this.checkPersonScore(this.choosenTitle);
        } else {
          this.dialogModuleDisable = true;
        }
      });
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.moduleStatus = this.getModuleStatus(response.data.moduleId);
            this.dialogRetake = true;
            this.moduleId && this.getModulePageQuiz(this.moduleId);
          } else {
            this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
          }
        })
        .catch(() => { });
    },
    getModuleStatus(moduleId) {
      let result = this.completedModuleList.find(score => Number(score.moduleId) === Number(moduleId));
      if (result != null) {
        return result.transitMap.scoreStatus;
      } else {
        return null;
      }
    },
    getModulePageQuiz(id) {
      if (id !== undefined) axios.get(`/system/page/module/list?moduleId=${id}`).then((response) => {
        this.showScore = response.data.some((v) => v.name.includes('choice') || v.name.includes('answer'))
      });
    },
    closeDialogModule() {
      this.dialogModuleDisable = false;
    }
  },
};
</script>
