<template>
	<v-container fluid>
		<subHeader />

		<v-container fluid style="max-width: 1271px">
			<v-row class="pb-8">
				<v-col cols="12">
					<v-row>
						<h2><b>Meet your BDMs</b></h2>
					</v-row>
				</v-col>
			</v-row>
			
			<v-row>
				<bdmTiles :states="stateList" :selectedIdList="selectedIdList" :isResources="true" />
			</v-row>
		</v-container>
	</v-container>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "./subHeader.vue";
import bdmTiles from "./bdmTiles.vue";
import bdmData from './bdmData.vue';

export default {
	components: {
		subHeader,
		bdmTiles
	},
	data() {
		return {
			selectedIdList: ["wa", "nt", "qld", "sa", "tas", "nsw", "act", "vic"],
			stateList: bdmData.data().stateList
		};
	},
	mounted: function () {
	},
	methods: {
	},
};
</script>
