<template>
	<v-main>
		<v-overlay z-index="5"></v-overlay>
		<div style="background-size:cover;background-attachment:fixed;min-height:100vh;display:flex;flex-direction:column;padding-bottom:80px;" :style="{ backgroundImage:'url(' + $bucket + '/image/background/Login.BG.jpg' + ')' } ">
			<v-layout style="z-index:6;" column align-center justify-center>
				<v-form ref="form" v-model="valid" style="transform-origin:center center 0px;width:500px;" v-if="!dialogNotification">
					<div style="padding: 7px 7px 50px 7px;background-color:white;border-radius:4px;">
						<div style="text-align:right;">
							<v-btn icon color="secondary" to="/">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>

						<h2 class="duo" style="text-align: center">
							<b>duo</b> Password Reset
						</h2>

						<div style="padding: 0 18%">
							<p class="duo" style="line-height:normal;text-align:center;">Reset your <b>duo</b> password below.</p>
							<v-text-field
								v-model="newPassword"
								:append-icon="passwordType1 ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passwordType1 = !passwordType1)"
								:type="passwordType1 ? 'password' : 'text'"
								persistent-hint
								:rules="[(v) => !!v || 'Enter a strong password', (v) => (v && v.match('^(?=.*\\d)(?=.*[^A-Za-z0-9]).{8,}$') && true) || 'Your password must be STRONG']"
							>
								<template v-slot:label>
									<span class="duo">New password*</span>
								</template>
								<template v-slot:message>
									<span>Your password must be <b>STRONG</b></span>
									<ul>
										<li>Passwords must have at least one non-alphanumeric character</li>
										<li>Passwords must have at least one digit ('0'-'9')</li>
										<li>Minimum 8 characters</li>
									</ul>
								</template>
							</v-text-field>

							<!-- <div
								v-bind:class="{'password-strength-initial':true,'password-strength-veryweak':passwordStrength.isVeryWeak,'password-strength-weak':passwordStrength.isWeak,'password-strength-strong':passwordStrength.isStrong}"
								style="display:flex;justify-content:center;align-items:center;"
							>
								<div>{{passwordStrength.message}}</div>
							</div> -->
							<v-row v-if="!$root.isBlank(newPassword)" no-gutters justify="center">
								<p style="font-size: 13px !important; font-family: 'AirNZ Sans Regular' !important">Password strength</p>
								&nbsp;
								<p style="font-size: 13px !important; font-family: 'AirNZ Sans Semibold' !important">{{ passwordStrength.message }} password</p>
							</v-row>

							<v-text-field
								v-model="confirmPassword"
								:append-icon="passwordType2 ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="() => (passwordType2 = !passwordType2)"
								:type="passwordType2 ? 'password' : 'text'"
								:rules="[(v) => !!v || 'Please confirm your password', (v) => (v && v === newPassword) || 'Password does not match']"
							>
								<template v-slot:label>
									<span class="duo">Confirm password*</span>
								</template>
							</v-text-field>
						</div>

						<div style="text-align: center; margin-top: 30px">
							<v-btn :disabled="!valid" large class="duo-primary" @click="submitResetPassword">Submit</v-btn>
						</div>
					</div>
				</v-form>

				<v-card v-if="dialogNotification" max-width="500">
					<v-card-text class="pa-3">
						<v-layout column align-center justify-center>
							<span class="title mt-6 px-6">
								<p class="duo">
									Your
									<b>duo</b> password has been updated.
								</p>
							</span>
						</v-layout>
					</v-card-text>

					<v-card-actions class="justify-center pb-6">
						<v-btn style="max-width:120px;" to="/" class="duo-primary ml-2 mr-2">Login</v-btn>
					</v-card-actions>
				</v-card>
			</v-layout>
		</div>
	</v-main>
</template>

<style scoped>
.v-text-field {
	width: 300px !important;
}
</style>

<script>
/* eslint-disable no-console */
import axios from "axios";

export default {
	data() {
		return {
			valid: true,
			newPassword: "",
			confirmPassword: null,
			passwordType1: true,
			passwordType2: true,
			dialogNotification: false
		};
	},
	methods: {
		submitResetPassword() {
			axios
				.post("/system/authentication/password/reset", { newPassword: this.newPassword, confirmPassword: this.confirmPassword, token: this.$route.query.token })
				.then(() => {
					this.dialogNotification = true;
				})
				.catch(() => {});
		}
	},
	computed: {
		passwordStrength() {
			let data = {
				message: "Weak",
				isVeryWeak: false,
				isWeak: false,
				isFair: false,
				isStrong: false
			};
			const regex = /^(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/g;
			let isSpecial = this.newPassword.match(regex);
			// data.isVeryWeak = this.newPassword.length >= 1 && this.newPassword.length < 8 ? true : data.isVeryWeak;
			data.isWeak = this.newPassword.length >= 1 && this.newPassword.length < 8 ? true : data.isWeak;

			// data.message = data.isVeryWeak ? "Very Weak" : data.message;
			// data.isWeak = isSpecial || this.newPassword.length >= 8 ? true : data.isWeak;
			data.message = data.isWeak ? "Weak" : data.message;
			// data.isStrong = isSpecial && this.newPassword.length > 12 ? true : data.isStrong;
			// data.message = data.isStrong ? "Strong" : data.message;
			data.isStrong = isSpecial && this.newPassword.length >= 8 ? true : data.isStrong;
			data.message = data.isStrong ? "Strong" : data.message;

			return data;
		}
	}
};
</script>