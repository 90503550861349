<template>
	<v-container fluid>
		<subHeader />
		<v-container fluid style="max-width: 1296px" class="ma-0 pa-0 pl-6">
			<v-row>
				<v-col cols="12">
					<div class="pt-4 pl-2 d-flex justify-center">
						<h1 class="duo white--text" style="font-weight:300 !important; font-family: 'AirNZ Sans Semibold'; font-size: 28px;">PRIZE DRAW</h1>
					</div>
					<div class="pt-1 pb-4 pl-2 d-flex justify-center">
						<h1 class="duo white--text" style="font-weight:300 !important; font-family: 'AirNZ Sans Semibold'; font-size: 28px;"><i>October 2024 Sweetstake Terms and Conditions - NZ Trade Promotion</i></h1>
					</div>
					<ol type="1">
						<li class="duo paragraph">
							<p>
								Information on how to enter the promotion forms part of the conditions of entry. Entry into the promotion is deemed as acceptance of its terms and conditions. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								Entry is only open to New Zealand based Travel agents and Brokers aged 18 years or over who are current Air New Zealand AirpointsTM members. Travel Agents and Brokers must be IATA-accredited or part of the Travel Industry Designated Service only. The Promoters reserve the right to verify the validity of entries.  
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								<b>Conditions of Entry:</b>
								<br />
								How to Enter:
								<br /><br />
								To enter an eligible participant must visit <a target="_blank" href="https://duo.airnzagent.co.nz">duo.airnzagent.co.nz</a> and guess the number of lollies in the jar. Each eligible guess will give the eligible participant one entry into the Daily Prize draw and one entry into the Grand Draw. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								Participation in the Promotion is limited to one entry into each draw per person per day.
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								Air New Zealand (the <b>“Promoter”</b>) employees and their immediate families are ineligible to enter.  
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								Promotion commences 8:00am Tuesday 1<sup>st</sup> October and closes 11.59pm Friday 18<sup>th</sup> October (business days only) (the <b>“Promotional Period”</b>). Each business day, a new tile will open for entries and the tile for the day prior will close. Entries received after this closing time will be ineligible for entry.  
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								<b>Daily prize draw:</b> 
								<br />
								The winners will be randomly drawn from the daily prize pool as set out below: 
							</p>
							<table style="border-collapse: collapse; width: 100%; height: 594.923px;" border="1">
								<tbody>
									<tr style="height: 23.7969px;">
										<td style="width: 14.9704%; height: 23.7969px; padding-left: 10px;"><b>Day</b>&nbsp;</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;">&nbsp;</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;"><b>Prize</b>&nbsp;</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;"><b>Prize winners drawn</b>&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 1&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Monday 30<sup>th</sup> September&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each</td>
										<td style="height: 237.969px; width: 24.9704%; padding-left: 10px;" rowspan="5"><b>Monday 7<sup>th</sup> October 8am.</b></td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 2&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Tuesday 1<sup>st</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 3</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Wednesday 2<sup>nd</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 4&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Thursday 3<sup>rd</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 5&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Friday 4<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 6&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Monday 7<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
										<td style="width: 24.9704%; height: 237.969px; padding-left: 10px;" rowspan="5"><b>Monday 14<sup>th</sup> October 8am&nbsp;</b></td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 7&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Tuesday 8<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 8&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Wednesday 9<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 9&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Thursday 10<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 47.5938px; padding-left: 10px;">Day 10&nbsp;</td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;"><b>Friday 11<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 47.5938px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 23.7969px; padding-left: 10px;">Day 11&nbsp;</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;"><b>Monday 14<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
										<td style="height: 23.7969px; width: 24.9704%; padding-left: 10px;" rowspan="4"><b>Friday 18<sup>th</sup> October 8am</b></td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 23.7969px; padding-left: 10px;">Day 12&nbsp;</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;"><b>Tuesday 15<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 23.7969px; padding-left: 10px;">Day 13</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;"><b>Wednesday 16<sup>th</sup> October</b></td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
									<tr style="height: 47.5938px;">
										<td style="width: 14.9704%; height: 23.7969px; padding-left: 10px;">Day 14&nbsp;</td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;"><b>Thursday 17<sup>th</sup> October&nbsp;</b></td>
										<td style="width: 24.9704%; height: 23.7969px; padding-left: 10px;">5 winners of 100 Airpoints Dollars each&nbsp;</td>
									</tr>
								</tbody>
							</table>
							<br />
							<p>
								Attempts will be made to notify the winner by phone at the contact telephone number provided in their duo profile by the above dates. If the Promoter, having used reasonable efforts, is unable to contact the winner prior to the above dates, then the prize is forfeited, and a further draw(s) will be conducted. The judge’s decision is final, and no correspondence will be entered into. Winners will also be announced via a Wingtips. 
							</p>
							<p>
								The winners are responsible for paying all additional costs associated with the prize that are not specifically included (as stated in this clause 7), including (where applicable) transport, meal costs, spending money, insurance and all other incidentals.   
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								<b>Grand prize draw:</b>
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								One winner will be drawn from the grand prize pool on Friday 18th October at 8am. The winner of the grand prize draw will be selected as having the guess with the exact number of lollies in the Lolly Jar. Where an exact guess hasn’t been submitted, the winner will be determined to be the closest to the exact number of lollies.   
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The first valid entry drawn will win the prize of 3,000 Airpoints DollarsTM. Where there is more than one (1) person eligible as the winner, the prize will be shared equally among all eligible winners.  The winner is responsible for paying all additional costs associated with the prize that are not specifically included (as stated in this clause 8), including (where applicable) transport, meal costs, spending money, insurance and all other incidentals. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								All Airpoints Dollars™ awarded under this Promotion will accrue to the member's Airpoints™ account and expire as per Airpoints™ terms and conditions. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The prize cannot be converted into cash and is not transferable. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The Promoter accepts no responsibility for any variation in prize value.   
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The Promoter reserves the right to substitute the prize in whole (or any of its components), with a substitute prize of equal or greater value.  
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								Airpoints™ Terms and Conditions apply - Airpoints terms and conditions. 
								<br />
								<a target="_blank" href="https://www.airnewzealand.co.nz/airpoints-terms-and-conditions">https://www.airnewzealand.co.nz/airpoints-terms-and-conditions</a> 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The Promoter assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorised access to or alteration of entries. The Promoter assumes no responsibility for any injury or damage to participants or any other person’s computer related to or resulting from participation in or downloading any materials in this promotion. Entries into this promotion will be stored only in the duo database. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								Neither the Promoter nor any other person or party associated with this promotion shall be liable for any loss or damage whatsoever suffered (including but not limited to indirect or consequential loss) or personal injury suffered or sustained in connection with either participation in this promotion or with any prizes offered. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The Promoter collects entrants’ personal information in order to conduct the promotion. If the information requested is not provided, the entrant may not participate in the promotion. Acceptance of the prize is deemed consent for the Promoter to use the winner’s details and photographs for promotional and media purposes without any further reference, payment or other compensation to the entrant.  Please refer to Air New Zealand’s Privacy Statement at <a target="_blank" href="https://www.airnewzealand.co.nz/privacy">https://www.airnewzealand.co.nz/privacy</a> regarding the collection, use, disclosure and storage of personal information. 
							</p>
						</li>
						<li class="duo paragraph">
							<p>
								The Promoter of this competition is Air New Zealand Limited, Private Bag 92007, Auckland, New Zealand. 
							</p>
						</li>
						<br />
					</ol>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<style scoped>
.paragraph {
	margin-bottom:20px;
	line-height: 21px;
	font-size: 14px;
	color: white;
}
.bullet {
	margin-bottom:20px;
	margin-left: 20px;
}
.subtitle {
	margin-bottom:10px;
	font-weight: bold !important;
	font-family: 'AirNZ Sans Semibold';
	font-size: 17px;
}
ul li,
ol li {
	line-height: 17px;
	font-size: 14px;
	margin-top: 6px;
}
@counter-style my-new-list-style {
  system: extends lower-alpha;
  suffix: ') ';
}

.ol-container ol {
  list-style: my-new-list-style;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
};
</script>