<template>
  <v-main style="margin: 10px">
    <v-data-table :headers="headers" :items="agencyList" :items-per-page="15" :search="keyword">
      <template v-slot:top>
        <v-row no-gutters style="padding: 20px 10px 15px">
          <v-col cols="12" sm="6">
            <div class="d-flex px-2">
              <h2>Agency list</h2>
            </div>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-if="['bdm', 'admin'].includes($store.state.principal.role)"
              v-model="selectedFilter"
              :items="filter"
              label="Filter"
              class="duo-description rounded-0"
              append-icon="mdi-chevron-down"
              @change="getByFilter"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="d-flex px-2">
              <v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details style="margin-right: 10px"></v-text-field>
              <v-btn v-if="$store.state.principal.role != 'bdm'" small fab dark color="secondary" title="Add" @click="dialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="$store.state.principal.role != 'bdm'">
          <v-col cols="12" class="pt-0">
            <div class="d-flex justify-end px-4">
              <v-btn medium color="primary" @click="downloadAgencyInfoReport()" style="text-transform: none;"><v-icon left>mdi-export</v-icon> Export Agency Info Report</v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex flex-row">
          <v-icon @click="$router.push(`/administration/agency/${item.id}/person`)">mdi-account-group-outline</v-icon>
          <v-icon class="ml-2" @click="showEditDialog(item)">mdi-pencil</v-icon>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{entity.id != null ? 'Edit' : 'Add'}} Agency</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" style="padding: 0 40px" v-model="valid" autocomplete="off">
            <v-text-field
              v-model="entity.code"
              :rules="[ruleAgencyCode]"
              label="Code*"
              :disabled="entity.id && $store.state.principal.role === 'bdm'"
              :error="agencyCodeHint != null"
							:error-messages="agencyCodeHint != null ? agencyCodeHint : ''"></v-text-field>
            <v-autocomplete
              v-model="entity.agencyGroupId"
              :items="agencyGroupList"
              :rules="[(v) => !!v || 'Enter Parent Account']"
              item-text="name"
              item-value="id"
              label="Account & Parent Account*"
              :class="{ 'line-blue': entity.agencyGroupId != null }"
              class="duo-description rounded-0"
              append-icon="mdi-chevron-down"
            >
              <template v-slot:item="{ item }">
                <span class="duo-description">{{ item.name }}</span>
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-model="entity.bdmId"
              :items="accountManagerList"
              item-text="name"
              item-value="id"
              label="Account Manager*"
              :class="{ 'line-blue': entity.bdmId != null }"
              class="duo-description rounded-0"
              append-icon="mdi-chevron-down"
              :disabled="$store.state.principal.role === 'bdm'"
            >
              <template v-slot:item="{ item }">
                <span class="duo-description">{{ item.name }}</span>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-model="entity.crmStatus"
              :items="crmStatus"
              label="Status*"
              class="duo-description rounded-0"
              append-icon="mdi-chevron-down"
              :disabled="$store.state.principal.role === 'bdm'"
            />
            <v-text-field :rules="[(v) => !!v || 'Enter Account Name']" v-model="entity.name" label="Account*"></v-text-field>
            <!-- <v-text-field v-model="entity.map.storeBranch" label="Store Branch"></v-text-field> -->
            <!-- Street, Road etc. -->
            <v-col cols="12">
              <v-text-field v-model="entity.unit" label="Unit" class="duo-description rounded-0"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-menu v-if="!manualEditPostalAddress" ref="streetPickerPostalAddress" v-model="streetPickerPostalAddress" :close-on-content-click="false" offset-overflow>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="entity.street" label="Street*" :class="{ 'line-blue': entity.street != null }" :loading="addressFetching" class="duo-description rounded-0 pb-n1 mb-n1" v-on="on" v-bind="attrs" readonly></v-text-field>
                  </template>
                  <v-card color="rgba(255, 255, 255, 1) !important">
                    <v-row no-gutters>
                      <vue-google-autocomplete
                        ref="addressGoogle"
                        types="address"
                        id="addressGoogleId"
                        style="border-style: none !important; background-color: white !important; width: 100% !important; height: 100% !important; padding: 5px 10px"
                        class="form-control duo-description rounded-0"
                        placeholder="Search Address"
                        v-on:placechanged="fetchPostalAddress"
                        :country="['au', 'nz']"
                        v-on:inputChange="isInputAddress = true"
                        v-on:blur="googleAddressFocus = false"
                        v-on:focus="googleAddressFocus = true"
                      ></vue-google-autocomplete>
                    </v-row>
                    <v-row no-gutters>
                      <a
                        v-if="isInputAddress && !isTypingInputAddress"
                        @click="
                          manualEditPostalAddress = true;
                          streetPickerPostalAddress = false;
                          selectnotfindAddress();
                        "
                      >
                        Click here if you can't find your address
                      </a>
                    </v-row>
                  </v-card>
                </v-menu>
                <v-text-field v-else v-model="entity.street" label="Street*" :class="{ 'line-blue': entity.street != null }" class="duo-description rounded-0" :loading="addressFetching"></v-text-field>
              </v-row>
            </v-col>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="entity.city" label="City" :class="{ 'line-blue': entity.city != null }" class="duo-description rounded-0"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="entity.suburb" label="Suburb" :class="{ 'line-blue': entity.suburb != null }" class="duo-description rounded-0"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="entity.state"
                  :rules="[(v) => !!v || 'Enter State']"
                  :items="stateList"
                  label="State*"
                  :class="{ 'line-blue': entity.state != null }"
                  class="duo-description rounded-0"
                  append-icon="mdi-chevron-down"
                >
                  <template v-slot:item="{ item }">
                    <span class="duo-description">{{ item }}</span>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="entity.postCode" label="Postcode*" type="number" :class="{ 'line-blue': entity.postCode != null }" class="duo-description rounded-0"></v-text-field>
              </v-col>
            </v-row>

            <!-- Street, Road etc. end -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn @click="save" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import lodash from "lodash";

export default {
  components: {
    VueGoogleAutocomplete
  },
  data: function () {
    return {
      isInit: true,
      agencyList: [],
      accountManagerList: [],
      crmStatus: [
        'Active', 'Inactive'
      ],
      agencyGroupId: 0,
      keyword: "",
      filter: ["State", "Account"],
      selectedFilter: "Account",
      headers: [
        {
          text: "Code",
          align: "start",
          sortable: false,
          filterable: true,
          value: "code"
        },
        { text: "Account Name", value: "name" },
        { text: "Parent Account", value: "agencyGroupName" },
        { text: "Account Manager", value: "bdmName" },
        { text: "Status", value: "statusDisplay" },
        //{ text: "Store/Branch", value: "storeBranch" },
        { text: "Unit", value: "unit" },
        { text: "Street", value: "street" },
        { text: "Suburb", value: "suburb" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Post Code", value: "postCode" },
        { text: "", value: "action", sortable: false }
      ],
      agencyGroupList: [],
      entity: {
        map: {}
      },
      dialog: false,
      valid: true,
      addressFetching: false,
      streetPickerPostalAddress: false,
      manualEditPostalAddress: false,
      isTypingInputAddress: false,
      googleAddressFocus: false,
      isInputAddress: false,
      addressChangeFlag: false,
      agencyCodeValid: false,
			bannedAgencyCode: false,
      agencyCodeHint: null,
      agencyCodeBannedList: [],
      stateList : ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT'],
    };
  },
  mounted: function () {
    if ('bdm' === this.$store.state.principal.role) {
      this.stateList = this.$store.state.principal.states;
      this.getByFilter();
    } else {
      this.getList();
    } 
    
    this.getGroupList();
    this.getAccountManagerList();
  },
  created: function () {
    this.debouncedFindAgency = lodash.debounce(this.findAgency, 500);
  },
  methods: {
    getGroupList() {
      axios
        .get("/system/agency/group/list")
        .then((response) => {
          this.agencyGroupList = response.data;
        })
        .catch(() => {});
    },

    getAccountManagerList() {
      axios
        .get("/administrator/account/manager")
        .then((response) => {
          this.accountManagerList = response.data;
        })
        .catch(() => {});
    },

    getByFilter() {
      if(this.selectedFilter === "Account"){
        this.getListByAccountManager();
      } else {
        this.getList();
      }
    },

    getList() {
      axios
        .get("/system/agency/list")
        .then((response) => {
          if (this.$store.state.principal.role != "bdm") {
            this.agencyList = response.data;
          } else {
            this.agencyList = response.data.filter((value) => this.$store.state.principal.states.includes(value.state));
          }
        })
        .catch(() => {});
    },

    getListByAccountManager() {
      axios
        .get("/system/agency/list/crm/manager")
        .then((response) => {
            this.agencyList = response.data;
            if(this.isInit == true && this.agencyList.length == 0){
              this.isInit = false;
              this.selectedFilter="State"
              this.getList();
            }
        })
        .catch(() => {});
    },

    showAddDialog() {
      this.entity = { map: {} };
      this.dialog = true;
    },

    showEditDialog(item) {
      this.entity = Object.assign({}, item);
      if (item.storeBranch != null) {
        this.entity.map = { storeBranch: item.storeBranch };
      }

      this.dialog = true;
    },

    ruleAgencyCode(v) {
			this.agencyCodeValid = false;
			this.bannedAgencyCode = false;
      this.debouncedFindAgency(v);
			if (v) {
				if ((v.length == 3 || v.length == 4 || v.length == 6 || v.length == 9) && v.match(/^[a-z0-9]+$/i)) {
					this.agencyCodeValid = true;
					return true;
				} else {
					return "A valid PCC must be a combination of 3,4,6 or 9 numbers and characters";
				}
			} else {
				return "Enter a valid PCC";
			}
		},

    findAgency(v) {
			// form is being validated, do not call back-end
			if (this.registering) {
				this.registering = false;
				return;
			}
			this.agencyCodeHint = null;
			if (!v) {
				return;
			}
			if ((v.length == 3 || v.length == 4 || v.length == 6 || v.length == 9) && v.match(/^[a-z0-9]+$/i)) {
				if (this.agencyCodeBannedList.includes(v)) {
					return;
				}
			} else {
				return;
			}
			this.agencyCodeFetching = true;

      if(this.entity.id == null){
        axios
				.post("/system/common/agency/find", {
					code: v,
				})
				.then((response) => {
					// have we ever fetched ever?
					this.agencyCodeFetched = true;
					let data = response.data;

					if (data != null || data === "Banned") {
            this.agencyCodeHint = "This code already exists"
          } else {
            this.agencyCodeHint = null;
          }
				})
				.catch(() => {})
				.finally(() => {
					this.agencyCodeFetching = false;
				});
      }
		},

    save() {
      if (this.$refs.form.validate() && this.agencyCodeHint == null) {
        axios.post(this.entity.id != null ? "/system/agency/edit" : "/system/agency/add", { entity: this.entity }).then(() => {
          this.closeDialog();
          this.getList();
        });
      }
    },

    closeDialog() {
      this.entity = { map: {} };
      this.dialog = false;
    },

    fetchPostalAddress(addressData) {
      this.address = addressData;
      console.log(this.address);
      this.entity.postCode = this.address.postal_code ? this.address.postal_code : "";
      if (this.address.street_number && this.address.route) {
        this.entity.street = this.address.street_number + " " + this.address.route;
      } else if (this.address.route) {
        this.entity.street = this.address.route;
      } else {
        this.entity.street = "";
      }
      this.entity.suburb = this.address.locality ? this.address.locality : "";
      this.entity.state = this.address.administrative_area_level_1 ? this.address.administrative_area_level_1 : "";
      this.entity.unit = "";
      this.entity.city = "";
      this.$forceUpdate();
    },

    downloadAgencyInfoReport() {
      window.open(axios.defaults.baseURL + "/system/agency/list/export?dateStart=" + this.dateStart + "&dateEnd=" + this.dateEnd);
    },
  }
};
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>