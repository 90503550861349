<template>
  <div v-if="show">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn @click="$router.push('/administration/creator/tile/training')" color="duo-secondary"
            class="mr-2">Back</v-btn>
          <v-btn @click="save" color="duo-primary">Save</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0" fluid>
      <div class="navbar d-flex flex-wrap pt-5">
        <img style="width: 216px" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
        <v-spacer />
        <img style="width: 182px" :src="$bucket + '/image/logo-airnz.svg'" />
      </div>
    </v-container>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row>
        <duo-training-tile :map="entity.map" :title="entity.title" :content="entity.content" :img="entity.image"
          :size="Number(entity.size)" :btn-label="entity.buttonLabel" :is-preview="1" />
        <v-col v-if="!entity.imageOnly" cols="12" :md="Number(entity.size) >= 2 ? 12 : 8">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios';
import duoTrainingTile from './duoTrainingTile.vue';
import subHeader from "../subHeader.vue";

export default {
  components: {
    subHeader,
    duoTrainingTile,
  },
  props: {
    show: {
      default: true,
    },
    entity: {
      default: {
        type: 'Dashboard',
        size: 1,
        map: {
          allFilter: false,
          domainFilter: false,
          agencyGroupFilter: false,
        },
        transitMap: {
          dateStart: null,
          dateEnd: null,
        },
        buttonLabel: "Find out more",
      },
    },
  },
  methods: {
    save() {
      let url = '/system/creator/tile/add';

      this.entity.link = String(this.entity.link);

      if (this.entity.id) {
        url = '/system/creator/tile/edit';
      }

      // clean preference
      if (this.entity.map.agencyGroupFilter == false &&
        this.entity.map.domainFilter == false) {
        this.entity.map.allFilter = true;
      } else if (this.entity.map.allFilter == true) {
        this.entity.map.agencyGroupFilter = false;
        this.entity.map.domainFilter = false;
      } else {
        this.entity.map.agencyGroupList = this.$root.trimSpaces(this.entity.map.agencyGroupList);
        this.entity.map.domainList = this.$root.trimSpaces(this.entity.map.domainList);
      }
      // end clean preference

      let formData = new FormData();
      formData.append("entity", JSON.stringify(this.entity));
      if (this.attachment) {
        formData.append("attachment", this.attachment);
      }

      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        (response) => {
          if (response.status == 200) {
           this.$router.push('/administration/creator/tile/training')
          }
        }
      )

    },
  },
};
</script>
