<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center mt-5">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card width="100%" height="549" color="transparent-card" rounded="0" :elevation="0">
					<v-card-title class="py-0 pl-12 duo-custom" :style="{ backgroundColor: parsedHeaderBackgroundColor, color: 'white', minHeight: '58px', height: 'auto' }">
						<div v-html="page.valueMap.headerTitle" class="align-center text-wrap"></div>
						<!-- Logo -->
						<v-avatar v-if="$root.isBlank(page.valueMap.headerIcon)" :color="parsedHeaderIconBackgroundColor" size="73" class="mt-15" style="position: absolute; right: 30px; z-index:10;">
							<v-icon :color="parsedHeaderIconColor" size="33" class="duo-icon-training"></v-icon>
						</v-avatar>
						<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" class="mt-13" style="position: absolute; right: 30px"></v-img>
					</v-card-title>
					<v-card-text class="pa-0">
						<v-row no-gutters>
							<v-col cols="12" class="d-flex flex-wrap">
								<template v-if="page.type == 'info-image-3'">
									<img v-if="$root.isBlank(page.valueMap.bodyImage)" :src="'/template/anz-training-wing.jpg'" class="mx-auto" height="312px" />
									<v-row v-else-if="!$root.isBlank(page.valueMap.bodyImage) && !$root.isSvg(page.valueMap.bodyImage)" class="justify-center">
										<v-col class="d-flex justify-center">
											<v-img contain max-width="1071" max-height="490" :src="$base + '/asset/file/' + page.valueMap.bodyImage" />
										</v-col>
									</v-row>
									<object v-else :data="$base + '/asset/file/' + page.valueMap.bodyImage" type="image/svg+xml" style="max-height: 312px;" class="mx-auto"></object>
									<div class="duo-custom-link" v-html="page.valueMap.bodySummary" style="line-height: 21px; font-family: AirNZ Sans Regular !important; color: #2D2926; margin-top: 30px; width: 100%;"></div>
								</template>
								<template v-else>
									<div v-html="page.valueMap.bodySummary" style="line-height: 21px; font-family: AirNZ Sans Regular !important; color: #2D2926; width: 100%;"></div>
									<img v-if="$root.isBlank(page.valueMap.bodyImage) && !$root.isSvg(page.valueMap.bodyImage)" :src="$root.isBlank(page.valueMap.bodyImage) ? '/template/anz-training-wing.jpg' : $base + '/asset/file/' + page.valueMap.bodyImage" class="mx-auto" height="312px" />
									<object v-else :data="$base + '/asset/file/' + page.valueMap.bodyImage" type="image/svg+xml" style="max-height: 312px;" class="mx-auto"></object>
								</template>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-row class="d-flex justify-space-between">
					<v-col v-if="page.valueMap.footerButtonConfig=='Default'">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="duo-text-button" @click="previousPage()">Previous</v-btn>
					</v-col>
					<v-col class="text-right pr-0">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="ml-auto duo-text-button" :right="true" @click="nextPage()">
							<div v-if="page.valueMap.footerButtonConfig=='End Page'">Back to&nbsp;<b style="font-size: 16px !important;">duo</b>training</div>
							<div v-if="page.valueMap.footerButtonConfig!='End Page'">Next</div>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa;
			} else {
				return "#2D2926";
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				return "#221551";
			}
		}
	},
	data: function () {
		return {};
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
#D3D3D3 .transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.grey-box {
	background-color: rgba(211, 211, 211, 0.5) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>