<template>
  <v-container fluid>
    <subHeaderMobile />

    <v-container style="border: 1px solid #221651 !important;" class="mb-2">
			<div class="d-flex justify-center align-center">
				<div>
					<span style="color: #221651 !important;">For the best experiences,</span> <br />
					<span style="color: #221651 !important;">please use your desktop or laptop.</span>
				</div>
			</div>
		</v-container>
  </v-container>
</template>
<style scoped>
</style>


<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
// import axios from "axios";
import subHeaderMobile from "./subHeaderMobile.vue";
export default {
  components: {
    subHeaderMobile
  },
};
</script>