<template>
    <v-sheet v-if="status != null" class="d-flex align-center justify-center completion-label rounded-circle mx-2 mt-2"
        width="72" height="72">
        <v-icon color="#C5B4E3" large v-if="status == 'Completed'">mdi-check</v-icon>
        <div v-else>{{ status }}</div>
    </v-sheet>
</template>
<style scoped>
.completion-label {
    font-size: 12px !important;
    font-family: 'AirNZ Black Italic' !important;
    background-color: transparent !important;
    border-style: solid !important;
    color: #C5B4E3;
    border-color: #C5B4E3;
}
</style>
<script>
export default {
    props: {
        status: {
            type: String,
            default: null,
        },
    }

}

</script>