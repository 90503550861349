<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center mt-5">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card width="100%" height="549" color="transparent-card" rounded="0" :elevation="0">
					<v-card-title class="py-0 pl-12 duo-custom" style="color: white; min-height: 58px; height: auto" :style="{ backgroundColor: parsedHeaderBackgroundColor }">
						<div v-html="page.valueMap.headerTitle" class="align-center text-wrap"></div>
					</v-card-title>
					<v-card-text class="pa-0">
						<v-row no-gutters>
							<v-col cols="7" class="d-flex pa-12">
								<div class="duo-custom-link" v-html="page.valueMap.bodySummary" style="line-height: 21px; font-family: AirNZ Sans Regular !important; color: #2D2926;"></div>
							</v-col>
							<!-- Image + Logo -->
							<v-col cols="5" class="pb-0 shrink">
								<div style="position: relative">
									<v-avatar v-if="$root.isBlank(page.valueMap.headerIcon)" :color="parsedHeaderIconBackgroundColor" size="73" :class="page.type == 'info-image-1' ? 'mt-12 ml-n9' : 'mt-n9'" style="position: absolute;" :style="page.type == 'info-image-1' ? '' : 'right: 30px;'">
										<v-icon :color="parsedHeaderIconColor" size="28" class="duo-icon-training"></v-icon>
									</v-avatar>
									<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" class="mt-n8" style="position: absolute; right: 30px;"></v-img>
								</div>
								<img :src="$root.isBlank(page.valueMap.bodyImage) ? '/template/anz-training-wing.jpg' : $base + '/asset/file/' + page.valueMap.bodyImage" style="object-fit: cover;" height="491px" width="100%" />
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-row class="d-flex justify-space-between">
					<v-col v-if="page.valueMap.footerButtonConfig=='Default'">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="duo-text-button" @click="previousPage()">Previous</v-btn>
					</v-col>
					<v-col class="text-right pr-0">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="ml-auto duo-text-button" :right="true" @click="nextPage()">
							<div v-if="page.valueMap.footerButtonConfig=='End Page'">Back to&nbsp;<b style="font-size: 16px !important;">duo</b>training</div>
							<div v-if="page.valueMap.footerButtonConfig!='End Page'">Next</div>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa;
			} else {
				return "#2D2926";
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				return "#221551";
			}
		}
	},
	data: function () {
		return {};
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>