<template>
	<v-app>
		<router-view :key="$route.fullPath"></router-view>

		<notification></notification>

		<img v-show="$root.loader.value" src="loader.svg" class="loader" />
	</v-app>
</template>

<script>
// import duoFooter from "./duoFooter.vue";
import notification from "./notification.vue";

export default {
	components: {
		// duoFooter
		notification
	},
	methods: {
	}
};
</script>