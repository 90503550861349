<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1296px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <div
            :style="{
              height: '476px',
              maxWidth: '100%',
              backgroundImage:
                'url(' + $bucket + '/image/dashboard-pages/NY_HeroBanner.jpg',
              backgroundSize: 'cover',
            }"
          >
            <div
              style="
                font-size: 25px;
                padding-top: 103px;
                padding-left: 61px;
                padding-bottom: 9px;
              "
            >
              <span><b>Air New Zealand</b> is off to the…</span>
            </div>
            <div
              class="white--text"
              style="
                margin-top: -60px;
                height: 167px;
                font-size: 167px;
                padding-left: 53px;
                font-family: 'AirNZ Black Italic';
              "
            >
              <span>Big Apple</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-col cols="12" style="max-width: 1200px; padding: 20px 0px 34px 24px">
        <h1>Air New Zealand is now flying to New York City</h1>
        <p>
          Air New Zealand is flying the 787-9 Dreamliner three times per week*
          on Monday, Thursday and Saturday, between Auckland and New York.
          Flights land at Terminal 1, John F. Kennedy International Airport,
          located just 45- 60 minutes from New York City.
        </p>
        <p>
          This ultra-long-haul service is Air New Zealand’s longest route, and
          one of the longest in the world. <br /><b
            >Economy, Economy Skycouch™, Premium Economy</b
          >
          and <b>Business Premier™</b> cabins are available on this flight.
        </p>
      </v-col>
      <v-sheet
        class="table-sheet"
        color="white"
        elevation="0"
        style="height: 357px; width: 1226px"
      >
        <v-row style="background-color: transparent !important">
          <v-col cols="12" style="padding-top: 60px; padding-left: 102px">
            <h3>Flight Schedules as of Feb 2023:</h3>
          </v-col>
          <v-col cols="12" style="padding-left: 102px">
            <v-simple-table style="width: 1020px" class="transparent-bg">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      style="width: 160px"
                      class="header side-border text-center white--text"
                    >
                      Flight Number
                    </th>
                    <th
                      style="width: 168px"
                      class="header side-border text-center white--text"
                    >
                      Operated By
                    </th>
                    <th
                      style="width: 160px"
                      class="header side-border text-center white--text"
                    >
                      Aircraft Type
                    </th>
                    <th
                      style="width: 167px"
                      class="header side-border text-center white--text"
                    >
                      Departs
                    </th>
                    <th
                      style="width: 168px"
                      class="header side-border text-center white--text"
                    >
                      Arrives
                    </th>
                    <th
                      style="width: 165px"
                      class="header text-center white--text"
                    >
                      Frequency
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="height: 48px"
                    v-for="item in effectiveFlights"
                    :key="item.flightNumber"
                  >
                    <td class="side-border text-center">
                      {{ item.flightNumber }}
                    </td>
                    <td class="side-border text-center">
                      {{ item.operatedBy }}
                    </td>
                    <td
                      style="line-height: 1.5"
                      class="side-border text-center"
                      v-html="item.aircraftType"
                    />
                    <td
                      style="line-height: 1.5"
                      class="side-border text-center"
                      v-html="item.departs"
                    />
                    <td
                      style="line-height: 1.5"
                      class="side-border text-center"
                      v-html="item.arrives"
                    />
                    <td class="text-center">{{ item.frequency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" style="padding-left: 102px">
            <span style="font-size: 14px"
              >*Schedules are subject to change.</span
            >
          </v-col>
        </v-row>
      </v-sheet>
      <div class="d-inline-flex" style="padding-top: 94px">
        <div style="padding-right: 42px">
          <v-img
            :src="$bucket + '/image/dashboard-pages/NY_Stroll.jpg'"
            height="392px"
            width="452px"
          />
        </div>
        <div style="max-width: 700px">
          <p class="italic-heading">
            Stroll Central Park, watch Broadway shows, see the finest art in the
            world and enjoy the stunning skyline of Manhattan.
          </p>
          <p>
            For some fun and free things to do, your customers can walk across
            Brooklyn Bridge for outstanding views of the NYC skyline. Or, head
            to midtown Manhattan to check out the High Line. It’s an old
            abandoned railway that’s been beautified, packed with street art,
            green spaces, and places to sit and chill out.
          </p>
          <p>
            Why not head to Yankee Stadium, eat a hotdog and watch a baseball
            game. Or, if music and theatre are their thing, there are more shows
            on Broadway than your customers will ever have time to see.
          </p>
        </div>
      </div>
      <div class="d-inline-flex" style="padding-top: 54px">
        <div style="padding-right: 49px">
          <p class="italic-heading">Eat like a New Yorker</p>
          <p>
            With more than 1000 different cultures, every mealtime is an
            opportunity to explore cuisines from around the world. With five
            bustling boroughs in New York City (Queens, the Bronx, Brooklyn,
            Manhattan and Staten Island) your customers can eat like a local and
            discover the unique neighborhoods.
          </p>
          <p>
            Find out more about flying Air New Zealand to not only New York, but
            all our North America destinations by completing our
            <a @click="$router.push('/training')"
              >North America training module.</a
            >
          </p>
          <h3>
            To book your customers to New York with Air New Zealand, refer to
            your GDS.
          </h3>
        </div>
        <div>
          <v-img
            :src="$bucket + '/image/dashboard-pages/NY_EatLikeNewYorker.jpg'"
            height="392px"
            width="452px"
          />
        </div>
      </div>
      <v-col cols="12">
        <p style="font-size: 14px">
          *Schedules subject to change, see your GDS for the latest schedules
          and frequency of flights.
        </p>
      </v-col>
    </v-container>
  </v-container>
</template>

<style scoped>
h1 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 17px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

tr {
  line-height: 24px;
}

th,
td {
  font-size: 12px !important;
}

.italic-heading {
  font-family: "AirNZ Black Italic";
  font-size: 35px;
  line-height: 1;
}

.header {
  background-color: #c5b4e3 !important;
}

.side-border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.transparent-bg {
  background-color: transparent !important;
}

.table-sheet {
  background-color: rgba(255, 255, 255, 0.39) !important;
  border-color: transparent !important;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data: function () {
    return {
      headers: [
        { text: "Flight Number", value: "flightNumber" },
        { text: "Operated By", value: "operatedBy" },
        { text: "Aircraft Type", value: "aircraftType" },
        { text: "Departs", value: "departs" },
        { text: "Arrives", value: "arrives" },
        { text: "Frequency", value: "frequency" },
      ],
      effectiveFlights: [
        {
          flightNumber: "NZ2",
          operatedBy: "Air New Zealand",
          aircraftType: "Boeing 787-9 v2 <br/> Dreamliner",
          departs: "Auckland <br/> 19:45",
          arrives: "New York <br/> 17:40",
          frequency: "Mon, Thu, Sat",
        },
        {
          flightNumber: "NZ1",
          operatedBy: "Air New Zealand",
          aircraftType: "Boeing 787-9 v2 <br/> Dreamliner",
          departs: "New York <br/> 19:40",
          arrives: "Auckland <br/> 7:30 (+2)",
          frequency: "Mon, Thu, Sat",
        },
      ],
    };
  },
};
</script>