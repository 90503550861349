<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card width="100%" height="700" color="transparent-card" rounded="0" :elevation="0">
					<v-card-text style="height: auto">
						<v-container class="d-flex align-start fill-height no-gutters">
							<v-row class="d-flex align-start flex-wrap px-3">
								<v-col cols="12" class="d-flex align-start fill-height">
									<div v-html="page.valueMap.bodySummary" class="black--text pl-6 pr-12 duo-custom-link" style="line-height: 21px; font-family: AirNZ Sans Regular !important; color: #2d2926"></div>
								</v-col>
								<v-col cols="12">
									<v-card v-if="page.valueMap.bodyTip" width="100%" color="grey-box" rounded="0" flat>
										<v-card-text class="d-flex mb-0 pb-0">
											<div v-html="page.valueMap.bodyTip" class="black--text text-wrap duo-custom-link" style="line-height: 30px; word-wrap: break-word; font-family: AirNZ Sans Regular !important"></div>
										</v-card-text>
									</v-card>
									<v-card v-if="page.valueMap.bodyNote" width="100%" color="grey-box" rounded="0" flat class="mt-2">
										<v-card-text class="d-flex mb-0 pb-0">
											<div v-html="page.valueMap.bodyNote" class="black--text text-wrap duo-custom-link" style="line-height: 30px; word-wrap: break-word; font-family: AirNZ Sans Regular !important"></div>
										</v-card-text>
									</v-card>
									<div class="mt-3"></div>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-row class="d-flex justify-space-between">
					<v-col v-if="page.valueMap.footerButtonConfig=='Default'">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="duo-text-button" @click="previousPage()">Previous</v-btn>
					</v-col>
					<v-col class="text-right pr-0">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="ml-auto duo-text-button" :right="true" @click="nextPage()">
							<div v-if="page.valueMap.footerButtonConfig=='End Page'">Back to&nbsp;<b style="font-size: 16px !important;">duo</b>training</div>
							<div v-if="page.valueMap.footerButtonConfig!='End Page'">Next</div>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	data: function () {
		return {};
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
#D3D3D3 .transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.grey-box {
	background-color: rgba(211, 211, 211, 0.5) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>