<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px" style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-pages/PickAPerk_Banner.png'" />
        </v-col>
        <v-col cols="12" class="d-inline-flex py-0 px-4 justify-space-between align-end">
          <div>
            <h2>Sell Air New Zealand and be in to win!</h2>
          </div>
          <div>
            <v-img height="48px" width="150px" :src="$bucket + '/image/dashboard-pages/MTATravel_Logo.png'" />
          </div>
        </v-col>
      </v-row>
      <v-row style="padding: 0px 120px 10px 20px">
        <v-col cols="12" class="pa-0">
          <p>This August, we're giving MTA Travel Advisors the opportunity to treat yourself with Pick-A-Perk and win from a pool of prizes! Winners will be based
            on total ticketed value (TTV), so ensure you achieve at least AU$2,500 during the incentive by selling Air New Zealand and complete the <a @click="$router.push('/training')">Seats to Suit</a>
            training module.
          </p>
          <p>Every eligible Air New Zealand international ticket sold gets you a step closer to being in the draw, and there's a wide range of prizes to be won - so,
            what do
            you need to do?
          </p>
        </v-col>
      </v-row>
      <v-row style="padding: 0px 80px 10px 16px">
          <v-col cols="12" class="pa-0">

          <v-sheet class="ml-1 pl-11 py-4 duo-sheet" style="margin-left: -12px;">
            <p><b>Sell AU$2,500-$4,999</b></p>
            <p><b>10 lucky winners will be able to choose from:</b></p>
            <p>
              <ul>
                <li>AU$100 gift card to spend however you like</li>
              </ul>
            </p>
            <p class="ml-6">OR:</p>
            <p>
              <ul>
                <li>Aotea Skincare therapeutic pack (Valued at NZ$125) and includes Harakeke Seed Oil night cream, Manuka Honey day cream and Kawakawa Balm. Aotea Skincare products can be found in our onboard Business Premier amenity kits.</li>
              </ul>
            </p>
          </v-sheet>

          <v-sheet class="ml-1 mt-6 pl-11 py-4 duo-sheet" style="margin-left: -12px;">
            <p><b>Sell AU$5,000-$9,999</b></p>
            <p><b>5 lucky winners will be able to choose from:</b></p>
            <p>
              <ul>
                <li>AU$300 gift card to spend however you like</li>
              </ul>
            </p>
            <p class="ml-6">OR:</p>
            <p>
              <ul>
                <li>Bose QuietComfort 45 wireless noise cancelling headset (Valued at AU$345)</li>
              </ul>
            </p>
          </v-sheet>

          <v-sheet class="ml-1 mt-6 pl-11 py-4 duo-sheet" style="margin-left: -12px;">
            <p><b>Sell over AU$10,000</b></p>
            <p><b>2 lucky winners will be able to choose from:</b></p>
            <p>
              <ul>
                <li>AU$1000 gift card to spend however you like</li>
              </ul>
            </p>
            <p class="ml-6">OR:</p>
            <p>
              <ul>
                <li>July luggage family 3pc set (Valued at AU$1,125)</li>
              </ul>
            </p>
            <p class="ml-6">OR:</p>
            <p>
              <ul>
                <li>AU$1,200 Air New Zealand travel fund</li>
              </ul>
            </p>
          </v-sheet>
        </v-col>
      </v-row>

        <v-row style="padding: 0px 120px 10px 20px">
          <v-col cols="12" class="pa-0">

          <p>And, don't forget to head over to duotraining and complete the <a @click="$router.push('/training')">Seats to Suit</a> training module.</p>

          <h2>So, what would you pick?</h2>

        </v-col>
      </v-row>

      <v-row class="my-12 mx-1" style="padding: 0px 60px 0px 0px">
        <v-col class="pa-1" cols="4">
          <v-img :src="$bucket + '/image/dashboard-pages/PickAPerk_Skincare.png'" />
        </v-col>
        <v-col class="pa-1" cols="4">
          <v-img :src="$bucket + '/image/dashboard-pages/PickAPerk_Headphone.png'" />
        </v-col>
        <v-col class="pa-1" cols="4">
          <v-img :src="$bucket + '/image/dashboard-pages/PickAPerk_Luggage.png'" />
        </v-col>
      </v-row>

      <v-row style="padding: 0px 120px 10px 20px">
        <v-col cols="12" class="pa-0">


          <p>Air New Zealand has your customers covered from New Zealand, the Pacific Islands and to North America. Whether they want to discover the natural beauty of New Zealand with our 20 domestic destinations across the North and South Islands. Head off on a tropical island escape to one of many Pacific Islands, with beaches waiting to be explored. Or, go on a North American adventure through the USA and Canada, experiencing vibrant culture and entertainment along the way.</p>


          <p><b> Hurry, this incentive ends on 31 August 2023. </b></p>
          <p><sub><b>Terms and conditions apply:</b> Promotional period 1-31 August 2023. Incentive is exclusive to MTA Travel Advisors based in Australia only. Valid for new POS AU International Air New Zealand sales on
            086 ticket stock originating in Australia. Must be ticketed on MTA Travel IATA 0235190-4. Group bookings and sales are excluded. Winner must reach minimum sales of AU$2,500 per advisor during
            the incentive period, be registered with duo and complete at least one duotraining module to be eligible for a prize. Winner must be an employee of MTA Travel throughout the duration of the
            promotion period. Winners will be notified by Air New Zealand by 8 September 2023. The value of each individual prize varies, and total prize pool value is AUSGOOO. Prizes are not redeemable for
            cash, non-transferrable or exchangeable. The prize may have an impact on your tax and FBT, you are encouraged to seek your own tax advice. FBT is the responsibility of the winner.
            </sub></p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>