<template>
    <v-dialog persistent max-width="500px" v-model="show">
        <v-card>
            <v-card-title>
                <span class="headline"> {{ title }}</span>
            </v-card-title>
            <v-card-text class="content" v-html="content" />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onCloseDialog">Cancel</v-btn>
                <v-btn @click="onClickConfirm" color="primary">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.title {
    background-color: #2d2926;
}

.content {
    font-size: 17px !important;
    color: black !important;
}
</style>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        content: {
            type: String,
            default: ""
        }
    },
    methods: {
        onCloseDialog() {
            this.$emit("onCloseDialog");
        },
        onClickConfirm() {
            this.$emit("onClickConfirm");
        },
    },
}
</script>