var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('div',{staticStyle:{"background-size":"cover","background-attachment":"fixed","min-height":"100vh","display":"flex","flex-direction":"column","padding-bottom":"80px"},style:({ backgroundImage:'url(' + _vm.$bucket + '/image/background/Login.BG.jpg' + ')' })},[(_vm.phase != null)?_c('v-card',{staticStyle:{"margin-top":"20vh","align-self":"center","width":"500px","padding":"7px 7px 50px 7px"},attrs:{"raised":"","color":"rgba(255,255,255,0.75) !important"}},[_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"icon":"","color":"secondary","to":"/"}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticStyle:{"text-align":"center","margin-bottom":"15px","font-size":"24px","color":"rgba(0,0,0,0.75)"}},[_vm._v(" Confirmation email ")]),_c('div',{staticStyle:{"font-size":"14px","padding":"0 15%"}},[(_vm.phase == 'confirmed')?[_vm._v(" Congratulations! Your email has been successfully confirmed. To login please click "),_c('router-link',{attrs:{"to":'/'}},[_vm._v("here")]),_vm._v(". ")]:_vm._e(),(_vm.phase == 'resend-email')?[_vm._v(" Whoops! Your confirmation link is either invalid or has expired. To resend the confirmation email, please enter your email address below. "),_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"placeholder":"Email Address","rules":[
								(v) => !!v || 'Please enter your registered email address',
								(v) =>
								(v &&
									v.match(
									/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
									) &&
									true) ||
								'Please enter a valid email address',
							]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticStyle:{"text-align":"center"}},[_c('v-btn',{staticClass:"duo-primary",attrs:{"large":""},on:{"click":_vm.resend}},[_vm._v("Resend")])],1)],1)]:_vm._e(),(_vm.phase == 'resent')?[_vm._v("Confirmation email has been resent. To complete your "),_c('b',[_vm._v("duo")]),_vm._v(" account set up click the link provided within the email and login.")]:_vm._e(),(_vm.phase == 'active' || _vm.phase == 'invalid')?[_vm._v(" Whoops, looks like you have already confirmed your registration. Please log in to "),_c('b',[_vm._v("duo")]),_vm._v(" to get started. "),_c('v-col',{staticClass:"d-flex flex-column pa-0 pt-2",staticStyle:{"margin-top":"8px"},attrs:{"cols":"12","align-self":"center"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-btn',{staticClass:"btn-login elevation-0",attrs:{"id":"loginBtn","to":"/login"}},[_vm._v(" Login ")])],1)],1)]:_vm._e()],2)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }