<template>
	<v-container class="d-flex fluid align-center pa-0 justify-center" style="margin-top: 30px">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card width="100%" class="transparent-card" :elevation="0">
					<v-card-title class="my-0 py-0 px-12" style="min-height: 120px; height: auto">
						<v-container>
							<v-row class="d-flex justify-space-between align-center">
								<v-col class="justify-left align-center">
									<div class="pb-4"></div>
									<div v-html="page.valueMap.headerTitle"></div>
								</v-col>
								<v-col cols="auto" class="justify-right">
									<v-row class="d-flex justify-right no-gutters">
										<v-col class="pr-3">
											<div class="pb-3"></div>
											<div v-html="page.valueMap.headerIconTitle" class="text-right align-center" style="font-family: AirNZ Sans Regular !important"></div>
										</v-col>
										<v-col cols="auto" class="d-flex align-end">
											<svg v-if="$root.isBlank(page.valueMap.headerIcon)" xmlns="http://www.w3.org/2000/svg" width="57.824" height="57.824" viewBox="0 0 57.824 57.824" class="ml-auto">
												<g id="Group_3369" data-name="Group 3369" transform="translate(-1109 -331)">
													<circle id="Ellipse_12" data-name="Ellipse 12" cx="28.912" cy="28.912" r="28.912" transform="translate(1109 331)" :fill="parsedHeaderIconBackgroundColor" />
													<g id="Group_148" data-name="Group 148" transform="translate(1061.336 303.708)">
														<path
															id="Path_106"
															data-name="Path 106"
															d="M78.06,46.142V59.28H67.833V46.142H78.06m.625-1.533H67.209a.909.909,0,0,0-.909.909V59.9a.909.909,0,0,0,.909.909H78.684a.909.909,0,0,0,.909-.909V45.518a.909.909,0,0,0-.909-.909Z"
															transform="translate(0 -1.349)"
															:fill="parsedHeaderIconColor"
														/>
														<g id="Group_147" data-name="Group 147" transform="translate(73.377 43.049)">
															<path id="Path_107" data-name="Path 107" d="M173.448,47.551c.058.023.116.045.175.064a2.344,2.344,0,1,0-.175-.064Z" transform="translate(-164.773 -43.049)" :fill="parsedHeaderIconColor" />
															<path
																id="Path_108"
																data-name="Path 108"
																d="M130.252,81.846c-1-.946-2.913-.11-3.27.2l-3.621,3.418-4.725.448-.005.017.133.443c.441,1.555,1.491,1.412,1.491,1.412l4.047-.221,2.1-1.541.152,3.885,1,11.254a1.181,1.181,0,0,0,.226.61,1.97,1.97,0,0,0,1.454.737l.3.019,1.659-13.159C131.186,88.251,133.78,85.181,130.252,81.846Z"
																transform="translate(-118.631 -76.22)"
																:fill="parsedHeaderIconColor"
															/>
														</g>
														<path
															id="Path_109"
															data-name="Path 109"
															d="M86.771,165.9a.767.767,0,0,1-.755-.907l1.725-9.241a.767.767,0,1,1,1.507.281l-1.725,9.241A.767.767,0,0,1,86.771,165.9Z"
															transform="translate(-17.039 -96.918)"
															:fill="parsedHeaderIconColor"
														/>
														<path
															id="Path_110"
															data-name="Path 110"
															d="M123.282,165.9a.767.767,0,0,1-.753-.626l-1.725-9.241a.767.767,0,0,1,1.508-.281l1.725,9.241a.767.767,0,0,1-.755.907Z"
															transform="translate(-47.122 -96.919)"
															:fill="parsedHeaderIconColor"
														/>
													</g>
												</g>
											</svg>
											<v-img v-else :src="$base + '/asset/file/' + page.valueMap.headerIcon" width="60" class="ml-auto"></v-img>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-container>
					</v-card-title>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-card width="100%" color="transparent-card" :elevation="0">
					<v-card-text class="pb-0 px-12" style="min-height: 315px; height: auto">
						<v-container fluid class="py-7">
							<v-row>
								<v-col>
									<div v-html="page.valueMap.bodySummary" class="black--text text-wrap duo-custom-link" style="line-height: 20px; font-family: AirNZ Sans Regular !important"></div>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-row class="d-flex justify-space-between">
					<v-col class="text-right pr-0">
						<v-btn depressed height="50" width="175" color="#00B0B9" class="white--text ml-auto text-transform-none" :right="true" @click="nextPage()">Get Started</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				// white
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				// purple
				return "#221551";
			}
		}
	},
	data: function () {
		return {};
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>