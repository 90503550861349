<template>
	<div></div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";

export default {
	mounted: function() {
		document.fonts.ready.then(
			function() {
				this.automatic();
			}.bind(this)
		);
	},
	methods: {
		automatic() {
			axios
				.get("/system/person/automatic")
				.then(response => {
					if (response.data) {
						this.$router.push({ path: "/dashboard" });
					} else {
						this.$router.push({ path: "/login" });
					}
				})
				.catch(() => {});
		}
	}
};
</script>