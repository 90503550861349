<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1296px">
      <v-row justify="center">
        <v-col  cols="12" style="padding: 0; margin-bottom: 14px">
          <div :style="{height: '476px', maxWidth: '100%',  backgroundImage: 'url(' + $bucket + '/image/dashboard-pages/NZ_HeroBanner.jpg', backgroundSize: 'cover'}" >
            <div style="font-size: 25px; padding-top: 103px; padding-left: 61px; padding-bottom: 9px;">
              <span>Flying to <b>New Zealand's</b></span>
            </div>
            <div class="white--text" style="margin-top: -40px; height: 126px; font-size: 126px; padding-left: 53px; font-family: 'AirNZ Black Italic';">
              <span>Winter wonderland</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-col cols="12" style="padding: 20px 100px 34px 20px">
        <h1>Experience an unforgettable winter with postcard views, epic ski fields, snow parks and alpine resorts on a New Zealand snow holiday.</h1>
        <p>
          New Zealand is a winter wonderland with snow experiences to suit every level of skier or snowboarder. Choose from the snowy peaks of the North Island or the wide-open terrains of the South Island, there’s snow to suit everyone in New
          Zealand.
        </p>
        <h3>There are three main ski regions in New Zealand to explore</h3>
      </v-col>
      <v-row justify="center">
        <v-col cols="6" md="4" style="padding: 0 6px 12px 0">
          <v-card tile min-height="418px" max-width="420" elevation="0">
            <v-img :src="$bucket + '/image/dashboard-pages/NZ_MtRuapehu.jpg'" height="418px">
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-toolbar-title class="pl-1 pt-3" style="font-size: 28px; color: white">
                  <div class="d-flex flex-column">
                    <span><b>Mt Ruapehu</b></span>
                    <span class="duo-underline"></span>
                  </div>
                </v-toolbar-title>
              </v-app-bar>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="6" md="4" style="padding: 0 6px 12px 0">
          <v-card tile min-height="418px" max-width="420" elevation="0">
            <v-img :src="$bucket + '/image/dashboard-pages/NZ_ChristChurchCaterbury.jpg'" height="418px" >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-toolbar-title class="pl-1 pt-3" style="font-size: 28px; color: white">
                  <div class="d-flex flex-column">
                    <span><b>Christchurch & Canterbury</b></span>
                    <span class="duo-underline"></span>
                  </div>
                </v-toolbar-title>
              </v-app-bar>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="6" md="4" style="padding: 0 0 12px 0">
          <v-card tile min-height="418px" max-width="420" elevation="0">
            <v-img :src="$bucket + '/image/dashboard-pages/NZ_QueenstownWanaka.jpg'" height="418px">
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-toolbar-title class="pl-1 pt-3" style="font-size: 28px; color: white">
                  <div class="d-flex flex-column">
                    <span><b>Queenstown & Wanaka</b></span>
                    <span class="duo-underline"></span>
                  </div>
                </v-toolbar-title>
              </v-app-bar>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-col cols="12" style="padding: 20px 100px 20px 20px">
        <h3>Air New Zealand flies from Australia to New Zealand, connecting onto our domestic network of 20 destinations across New Zealand, taking customers right to the snow.</h3>
        <p>
          Our Seats to Suit offering will allow your customers to travel the way they like! From <b>seat</b> only fares, which includes a carry-on bag, through to the<b>works</b> where customers can experience premium check-in, movies, a meal and extra personal
          service and space, we’ve got something for everyone. And wherever you are flying, you can expect the same great, uniquely Kiwi experience onboard.
        </p>
        <p>
          With direct flights restarting this year from Sydney, Melbourne and Brisbane to Auckland, Christchurch and Queenstown - your customers can be hitting the slopes in no time. With restaurants, fun activities and wineries a stone’s throw away
          from each region, there’s something for everyone.
        </p>
        <p>
          Find out more about flying Air New Zealand across the Tasman by completing our
          <a @click="$router.push('/training')">Seats to Suit training module</a>
        </p>
        <h3>To book your customers to New Zealand with Air New Zealand, refer to your GDS.</h3>
      </v-col>
    </v-container>
  </v-container>
</template>

<style scoped>
h1 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 17px;
}

p,a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader
  },
  mounted: function () {
    window.scrollTo(0,0);
  },
};
</script>