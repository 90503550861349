<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-row no-gutters style="padding: 20px 10px 15px">
        <v-col cols="12" sm="8">
          <div class="d-flex px-2">
            <h2>Content Creator Access Report</h2>
          </div>
        </v-col>
      </v-row>

      <!-- Homepage Creator Report -->
      <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
        <v-card-text>
          <v-form ref="form2" no-gutters class="d-flex flex-wrap">
            <v-row>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateHomepageStartPicker" v-model="dateHomepageStartMenu" :close-on-content-click="false"
                  :return-value.sync="dateHomepageStart" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateHomepageStart" :v-model="dateHomepageStart"
                      :value="$root.parseDate(dateHomepageStart)" readonly v-on="on" v-bind="attrs"
                      :rules="[(v) => !!v || 'Enter start date']" @click:prepend="dateHomepageStartMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">Start date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateHomepageStart" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateHomepageStartPicker.save(dateHomepageStart)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateHomepageStartMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateHomepageEndPicker" v-model="dateHomepageEndMenu" :close-on-content-click="false"
                  :return-value.sync="dateHomepageEnd" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateHomepageStart" :v-model="dateHomepageEnd"
                      :value="$root.parseDate(dateHomepageEnd)" readonly v-on="on" v-bind="attrs"
                      :rules="[(v) => !!v || 'Enter end date']" @click:prepend="dateHomepageEndMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">End date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateHomepageEnd" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateHomepageEndPicker.save(dateHomepageEnd)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateHomepageEndMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
          <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadHomepageReport"><v-icon
              left>mdi-export</v-icon> Export Homepage Creator Access Report</v-btn>
        </v-card-actions>
      </v-card>

      <!-- Training Creators Report -->
      <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
        <v-card-text>
          <v-form ref="form3" no-gutters class="d-flex flex-wrap">
            <v-row>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateTrainingStartPicker" v-model="dateTrainingStartMenu" :close-on-content-click="false"
                  :return-value.sync="dateTrainingStart" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateTrainingStart" :v-model="dateTrainingStart" :value="$root.parseDate(dateTrainingStart)"
                      readonly v-on="on" v-bind="attrs" :rules="[(v) => !!v || 'Enter start date']"
                      @click:prepend="dateTrainingStartMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">Start date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateTrainingStart" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateTrainingStartPicker.save(dateTrainingStart)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateTrainingStartMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateTrainingEndPicker" v-model="dateTrainingEndMenu" :close-on-content-click="false"
                  :return-value.sync="dateTrainingEnd" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateTrainingStart" :v-model="dateTrainingEnd" :value="$root.parseDate(dateTrainingEnd)"
                      readonly v-on="on" v-bind="attrs" :rules="[(v) => !!v || 'Enter end date']"
                      @click:prepend="dateTrainingEndMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">End date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateTrainingEnd" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateTrainingEndPicker.save(dateTrainingEnd)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateTrainingEndMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="3">
                <v-autocomplete v-model="linksFilter" :items="visitLinks" item-text="value" item-value="value"
                  label="Filter" :class="{ 'line-blue': visitLinks != null }" class=" rounded-0"
                  append-icon="mdi-chevron-down" />
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
          <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadTrainingReport"><v-icon
              left>mdi-export</v-icon> Export Training Creator Access Report</v-btn>
        </v-card-actions>
      </v-card>
      
    </v-card>
  </v-main>
</template>

<style scoped>
.date-block {
  max-width: 230px !important;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dateRegisterStartMenu: false,
      dateRegisterStart: null,
      dateRegisterEndMenu: false,
      dateRegisterEnd: null,
      dateHomepageStartMenu: false,
      dateHomepageStart: null,
      dateHomepageEndMenu: false,
      dateHomepageEnd: null,
      dateTrainingStartMenu: false,
      dateTrainingStart: null,
      dateTrainingEndMenu: false,
      dateTrainingEnd: null,
      linksFilter: "",
      visitLinks: []
    };
  },
  mounted() {
  },
  methods: {
    downloadHomepageReport() {
      if (this.$refs.form2.validate()) {
        window.open(axios.defaults.baseURL + "/system/creator/accesslog/homepage/export?dateStart=" + this.dateHomepageStart + "&dateEnd=" + this.dateHomepageEnd);
      }
    },
    downloadTrainingReport() {
      if (this.$refs.form3.validate()) {
        window.open(axios.defaults.baseURL + "/system/creator/accesslog/training/export?dateStart=" + this.dateTrainingStart + "&dateEnd=" + this.dateTrainingEnd);
      }
    },
  }
};
</script>
