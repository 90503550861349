<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/UA - Week 2 - Landing Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            Air New Zealand, United Airlines and Brand USA are excited to launch week 2 of the four-week sales and training incentive to drive sales to the USA.
          </h2>
          <p>
            Week 1 of the incentive kicked off Tuesday 29th October, and all you need to do is continue to log your bookings via duosales and complete a Brand USA discovery programme badge to be in to win. Agents have four weeks to earn points for the grand prize of return flights for two to Houston with Air New Zealand, with add on domestic connections from United Airlines. Our friends over at Alamo are also throwing in a generous 7 day car rental for the Grand Prize winner, so they can see more of the USA!
          </p>
          <p class="pt-5">
            There will also be a different mini weekly incentive, scroll down to learn more!
          </p>
          <p>
            <b>Sounds good right?</b>
          </p>
          <br />
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;" class="pl-12">
            <p>
              <b>To enter in our Grand Prize, you must:</b>
            </p>
            <ul >
              <li style="margin-top: 10px; padding-left: 10px;">
                Book, and ticket flights to the USA on either Air New Zealand or United Airlines, on 086 ticket stock only, between 29 October and 24 November 2024.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Agents must register their ticketed Air New Zealand booking references through Duosales.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Each ticket issued will earn you one point.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Bookings logged on behalf of another agent are ineligible for entry
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Agents must enter the correct PNR creation date for the entry to be valid.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Booked travel may be availed for any time.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Participants must also sign up and complete at least one badge on USA Discovery Program (Brand USA's Training Platform). Platinum agents that have already completed all the badges will need to retrain in one of the badges and/or use the retail/ sell features on the platform to qualify.
              </li>
            </ul>
            <p style="font-size: 13px" class="pt-2">
              <a
                style="font-size: 13px; color: black; font-style: italic"
                :href="$window.location.origin + '/#/page/unitedAirlinesWeek1Tc'"
                target="_blank"
                >Terms and Conditions apply.
              </a>
            </p>
          </div>
          <br />

          <!-- 25 word or less submission -->
          <div style="background-color: #1C1044; color: white;">
            <div style="display: flex; flex-direction: column; align-items: center; text-align: center;" class="px-12 py-6">
              <p>
                Week Two Incentive
              </p>
              <p style="height: 80px;">
                Our USA day will be hosted in Auckland on Friday 22nd November, so make sure you're free before entering. Our USA day, in conjunction with United Airlines and Brand USA, is an exclusive day where we'll take you through part of a Business Class Passenger's journey as well as experiencing some onboard treats and more! You'll have an intimate opportunity to network with - and learn from - some of our outstanding destination partners such as Los Angeles Tourism and Visit Anaheim, plus receive some top tips!
              </p>
              <p>
                There will also be some huge prizes up for grabs to say thank you for your support in driving customers to The USA!
              </p>
              <p style="font-size: 22px;"><b>Tell us in 25 words or less why you think you deserve a spot at our exclusive USA day!</b></p>
              <div v-if="unitedAirlinesWeek2Form.submitted == false" class="my-0 py-0 px-0 mx-0 pb-6" style="width: 820px;">
                <v-form v-model="unitedAirlinesWeek2Form.validForm" ref="unitedAirlinesWeek2ValidForm" lazy-validation>
                  <v-textarea 
                    v-model="unitedAirlinesWeek2Form.response" 
                    label="Write here..." 
                    solo 
                    rows="3"
                    :rules="[(v) => !!v || 'Please enter your answer', (v) => (v.split(' ').length <= 25) || 'Max 25 words']"
                    @keydown="event => limitWords(event, 25)"
                  ></v-textarea>
                </v-form>
                <div style="text-align: right; margin-right: -10px;">
                  <v-btn style="font-size: 16px; height: 45px; width: 180px; text-transform: none !important;" @click="submitUnitedAirlinesWeek2Form()">Enter Now</v-btn>
                </div>
              </div>
              <v-col v-else cols="12">
                <v-row no-gutters class="correct-answer d-flex justify-center">
                  <v-alert dense type="success" color="#00b0b9">Submitted</v-alert>
                </v-row>
              </v-col>
            </div>
          </div>
        </div>
      </v-row>
      <div class="mt-12 pt-12"></div>

      <!-- Upstate New York -->
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/UA - Week 2 - Upstate NY.jpg'"
          />
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2 class="pt-3">
              <b>Travel beyond the Big Apple and explore more of New York State</b>
          </h2>
          <p class="pt-3 pb-8">
            <b>New Yorkers have been holidaying in the State for centuries, with popular weekend and year-round hotspots like the Catskills, the Hamptons, Lake George and more offering a home away from home from the bustling metropolis of New York City. Now it's our turn to uncover what New Yorkers have kept to themselves.</b>
          </p>

          <v-row>
            <v-col
              v-for="tile in 3"
              :key="tile.title"
              cols="4"
              md="4"
              sm="12"
            >
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Week 2 - Image '+ (tile)+'.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <p class="pt-3">
            New York State has 11 distinct regions filled with historic sites, natural wonders and more. From the lights of Times Square to the mists of Niagara Falls, there is so much to see and so much to love. Discover vibrant cities, scenic shorelines and majestic mountains. Outdoor sports and indoor culture, beautiful beaches and unbeatable shopping. The best sites to see and the tastiest food to eat. Here, you can get lost in history, camp under a canopy of stars and explore vast natural wonders. With thousands of acres of wilderness, tons of incredible history, trendy city neighbourhoods, and Mother Nature's most awe-inspiring playgrounds, there's something for everyone.
          </p>
        </div>
      </v-row>
      <!-- Expedia TAAP -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;" class="pl-12">
            <v-row>
              <v-col cols="3">
                <v-card tile elevation="0">
                  <v-img
                    :src="$bucket + '/image/dashboard-pages/UA - Expedia TAAP.png'"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="9">
                <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                  <p style="height: 30px;"> 
                    <b>No one has the USA covered like Expedia TAAP. That's why the great majority of Kiwi Agents look to Expedia TAAP for the USA.</b>
                  </p>
                  <p style="height: 10px;">
                    Expedia TAAP has access to an enormous amount of 'directly contracted' supply.
                  </p>
                  <p>
                    Learn more <a href="https://www.expediataap.co.nz/" target="_blank" style="color: black;"><u>here!</u></a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-row>
      <br /><br /><br />
      <!-- Discover Open Road -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <v-row>
            <v-col cols="8">
              <h2>
                Discover the Open Road with Brand USA's Road Trips USA Collection!
              </h2>
              <p class="pt-2">
                <b>Get ready to inspire your clients to explore the diverse landscapes of the USA with Brand USA's exciting Road Trips USA collection. Featuring a wealth of trip inspiration and essential travel information the Brand USA self-drive itineraries will empower your clients to confidently hit the highways and byways.</b>
              </p>
            </v-col>
            <v-col cols="4">
              <v-img
                width="200px"
                :src="$bucket + '/image/dashboard-pages/UA - VisitTheUSA.png'"
                class="ml-6"
              ></v-img>
            </v-col>
          </v-row>
          <br />
          <!-- The collection includes 38 -->
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 1.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                <p style="height: 60px;"> 
                  The collection includes 38 self-drive itineraries available as downloadable PDFs, perfect for sharing with your clients. Each road trip begins and ends in a gateway city, tailored specifically for international travellers.
                </p>
                <p>  
                  To make planning even easier, driving times and distances are provided in kilometres-ideal for New Zealand customers. Plus, each itinerary is fully customisable, allowing clients to select their start and end points and choose routes based on their preferred trip length.<br />From cultural-themed road trips to multi-state journeys, historic trails and scenic wanders, these 38 inspired itineraries will set your clients on a path to discover the USA.
                </p>
              </div>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Nature lovers -->
          <v-row>
            <v-col cols="7">
              <div class="black--text d-flex flex-column justify-center" style="height: 100%;">
                <p> 
                  <b>Nature lovers</b> will be on a natural high on the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Rocky%20Mountains-Great%20Plains.pdf" target="_blank" style="color: black;"><u>Rocky Mountains & the Great Plains</u></a>, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/ENGLISH_Mountains-Glaciers-Parks_Global.pdf" target="_blank" style="color: black;"><u>Alaska - Mountains, Glaciers & Parks</u></a> 
                      and 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_CoastsAndMountains.pdf" target="_blank" style="color: black;"><u>Coasts & Mountains of the South itineraries.</u></a>
                  <br /><br />
                  For otherworldly landscapes, encourage them to hit the road from Las Vegas, Nevada on the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Canyons-Mesas-Hoodoos.pdf" target="_blank" style="color: black;"><u>Canyons, Mesas & Hoodoos</u></a>, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/RoadTripsUSA_VolcanicPeaksSparklingShores_ENG.pdf" target="_blank" style="color: black;"><u>Volcanic Peaks to Sparkling Shores</u></a>
                    in Oregon and Washington and 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Geological%20Wonders.pdf" target="_blank" style="color: black;"><u>Geological Wonders</u></a>
                    in Utah, Wyoming and Idaho.
                  <br /><br />
                  Steer forest bathers to the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_National-parks-American%20India%20culture.pdf" target="_blank" style="color: black;"><u>National Parks & American Indian Cultures</u></a> 
                    roundtrip from Phoenix, Arizona or the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_GiantTreesAncientForests.pdf" target="_blank" style="color: black;"><u>Giant Trees & Ancient Forests</u></a> 
                    loop from San Francisco, California.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 2.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Music Loving Clients -->
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 3.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                <h2>
                  Music-loving clients?
                </h2>
                <p> 
                  Inspire a deep dive into America's songbook with the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Bourbon-Bluegrass%20and%20Bloodstock.pdf" target="_blank" style="color: black;"><u>Bourbon, Bluegrass & Horse Country</u></a> 
                    roundtrip from Nashville, Tennessee. There's also the eight-state 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Musical%20Roots%20of%20the%20South.pdf" target="_blank" style="color: black;"><u>Musical Roots of the South</u></a> 
                    odyssey from Dallas, Texas to Charleston, South Carolina.
                  <br /> <br />
                  Road-trippers can also follow the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Rhythms%20of%20the%20River.pdf" target="_blank" style="color: black;"><u>Rhythms of the River</u></a>, 
                    tracing the Mississippi from Chicago, Illinois down to New Orleans, Louisiana through eight states. Or embark on a 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_OnTheShoresOfTheGreatLakes.pdf" target="_blank" style="color: black;"><u>Great Lakes loop</u></a> 
                    through Wisconsin, Minnesota and Michigan from Chicago.
                </p>
              </div>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Stars in their eyes -->
          <v-row>
            <v-col cols="7">
              <div class="black--text d-flex flex-column justify-center" style="height: 100%;">
                <h2>
                  Stars in their eyes?
                </h2>
                <p> 
                  They'll be awestruck by silver screen destinations on the fly-and-drive 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_At%20the%20Movies.pdf" target="_blank" style="color: black;"><u>At The Movies</u></a> 
                    cross-country trip from NYC to LA. And ogle the bright lights and big-city sights of 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_IconicCitiesBeyond.pdf" target="_blank" style="color: black;"><u>Iconic Cities & Beyond</u></a> 
                    from Boston, Massachusetts to Washington, D.C.
                  <br /><br />
                  For a slice of small-town life, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Quaint%20New%20England%20Towns.pdf" target="_blank" style="color: black;"><u>Quaint New England Towns</u></a> 
                    through Massachusetts, New Hampshire, Maine, Vermont, Connecticut and Rhode Island serves up the scenery and charm we love from TV and movies.
                  <br /><br />
                  Classic road trippers and foodies can tick off the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Route66-TexasHill_Country.pdf" target="_blank" style="color: black;"><u>Route 66 & Texas Hill Country</u></a> or 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/RoadTripsUSA_HistoricRoute66_ENG%20%283%29_0.pdf" target="_blank" style="color: black;"><u>Historic Route 66 - Curios, Pit Stops & Diners</u></a> 
                    itineraries from their bucket list. Meanwhile, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_TheCivilRights-HistoryTrail.pdf" target="_blank" style="color: black;"><u>The Civil Rights History Trail</u></a> 
                    puts place-making landmarks of the South into context.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 4.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <br /> <br />
          <!-- More Road trips -->
          <p>
            <b>More road trips inspiration can be found <a href="https://traveltrade.visittheusa.com.au/USAItineraries" target="_blank" style="color: black;"><u>here</u></a> </b>
          </p>
          <p>
            Brand USA's official Visit the USA travel trade site is equipped with everything you need to sell travel to the USA with confidence.
          </p>
          <p>
            <b>
              You'll find Road Trip USA itineraries, tour operators and destination contacts along with general US travel info including visa and entry policies and getting around: <a href="https://traveltrade.visittheusa.com" target="_blank" style="color: black;"><u>traveltrade.visittheusa.com</u></a>
            </b>
          </p>
        </div>
      </v-row>
      <br /><br /><br />
      <!-- Become a USA expert -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="background-color: #ffffff; margin-bottom:1.5vw; padding:1.5vw;" class="px-12">
            <h2 class="pt-9">
              Become a USA expert AND win with Brand USA and Air New Zealand USA Discovery Program!
            </h2>
            <p class="pt-3">
              From 29 October to 20 November, every badge completed on Brand USA's Discovery Program will earn an entry into a draw to win incredible prizes or to attend the Air New Zealand VIP training day.
            </p>
            <p>
              There are over 60-plus badges to earn across all regions and states. Once you have completed the Brand USA badge training, you can download and add badge icons to any client communications, demonstrating their USA expertise.
            </p>
            <v-row>
              <v-col
                v-for="tile in 4"
                :key="tile.title"
                cols="3"
                md="3"
                sm="3"
              >
                <v-card tile elevation="0">
                  <v-img
                    :src="$bucket + '/image/dashboard-pages/UA - Badge '+ (tile)+'.png'"
                    width="200px"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <p>
              The Brand USA training platform lets you enhance your USA knowledge in four key ways: earn badges, access valuable resources, share inspiring content with clients, and utilise specialised tools to boost your sales.
            </p>
            <p>
              <b>
                Don't miss out - access the USA Discovery Program <a href="https://usadiscoveryprogram.co.nz/main" target="_blank" style="color: black;"><u>here</u></a>
              </b>
            </p>
            <br />
          </div>
        </div>
      </v-row>
      <br /> <br />

      <!-- Trade Ad -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Week 2 - Trade Ad.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-9" style="height: 100%;">
                <h2>
                  Trade ad available for promotion.
                </h2>
                <v-btn
                  style="width: 193px; height: 50px; font-size: 16px"
                  color="#00b0b9"
                  class="mt-4"
                  :elevation="0"
                  bottom
                  dark
                  @click="handleDownloadTradeAd()"
                  >Download here</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </v-row>

    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";
import axios from "axios";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getFormStatus();
  },
  data() {
    return {
      unitedAirlinesWeek2Form: {
				show: false,
				data: {},
				response: "",
				submitted: false,
				validForm: true
			}
    };
  },
  methods: {
    limitWords(event, wordLimit) {
			const inputField = event.target;
			const currentValue = inputField.value;
			const words = currentValue.split(' ');

			// Allow backspace, delete, arrow keys, etc.
			if (event.key === 'Backspace' || event.key === 'Delete' || 
				event.key === 'ArrowLeft' || event.key === 'ArrowRight' || 
				event.key === 'ArrowUp' || event.key === 'ArrowDown' || 
				event.key === 'Tab') {
				return;
			}

			// Prevent input if word count is at or over the limit
			if (words.length >= (wordLimit+1)) {
				event.preventDefault();
			}
		},
    getFormStatus() {
			axios
				.post("/system/activitylog/person/value/list", { value: 'United Airlines Week 2 - Incentive Form' })
				.then(response => {
					if (response.status === 200) {
						if (response.data.length > 0) {
							this.unitedAirlinesWeek2Form.submitted = true;
						}
					}
				})
				.catch(() => { });
		},
    submitUnitedAirlinesWeek2Form() {
      if (this.unitedAirlinesWeek2Form.response == "" || this.unitedAirlinesWeek2Form.response.trim() === '') {
				this.$store.commit("showNotification", 
					{ 	
						content: "Response is required", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			} else if (this.$refs.unitedAirlinesWeek2ValidForm.validate()) {
				this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: 'United Airlines Week 2 - Incentive Form', response: this.unitedAirlinesWeek2Form.response });
        this.unitedAirlinesWeek2Form.submitted = true;
        this.unitedAirlinesWeek2Form.response = ""
				this.$store.commit("showNotification", 
					{ 	
						title: "Awesome", 
						content: "Thanks for registering for the incentive. Sell Air New Zealand to be in the draw to WIN.", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			}
    },
    handleDownloadTradeAd() {
      const fileUrl = this.$bucket + '/file/UA - NY TRADE AD.pdf';
      window.open(fileUrl, '_blank');
    }
  },
};
</script>
