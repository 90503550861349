var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('subHeader'),_c('v-container',{staticStyle:{"max-width":"1271px"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"padding":"0","margin-bottom":"14px"},attrs:{"cols":"12"}},[_c('div',{style:({
          height: '375px',
          maxWidth: '100%',
          backgroundImage:
            'url(' +
            _vm.$bucket +
            '/image/dashboard-pages/Helloworld_Incentive.png',
          backgroundSize: 'cover',
        })})])],1),_c('v-col',{staticStyle:{"padding":"34px 100px 10px 20px"},attrs:{"cols":"12"}},[_c('h1',[_vm._v("WIN lounge access before take off!")]),_c('p',[_vm._v(" Air New Zealand lounges are a great place to start and end a holiday or work trip – especially when it’s on us. ")]),_c('p',[_vm._v(" Our lounges have several different spaces for you to relax, refresh or get some work done before flying. You can enjoy barista-made coffee, New Zealand wines or beers, plus chef made fresh cuisine. ")])]),_c('v-col',{staticStyle:{"padding":"0px 100px 20px 20px"},attrs:{"cols":"12"}},[_c('h3',[_vm._v("Play to WIN")]),_c('p',[_vm._v(" To kick of your Helloworld OMC festivities early, we’re giving you the chance to win access to our lounge in either Sydney, Brisbane, Melbourne or Perth on your way to New Zealand, simply by completing the quiz below. It’s that easy! ")]),_c('p',[_vm._v(" Hurry, this giveaway ends on 12 April 2023. ")])]),_c('v-row',{staticClass:"mt-4 d-flex justify-center text-center"},[_c('iframe',{staticClass:"pa-0 ma-o",attrs:{"name":"wayinFrame","src":"https://xd.wayin.com/display/container/dc/3880ee3f-87cb-4c32-af38-bb018d84d107","frameborder":"0","width":"1220","height":"1100px","id":"ngxFramee6a918d0-72d5-43d3-933c-dd5dd4ef7e4f","allowTransparency":"true"}})]),_c('v-col',{staticClass:"mt-",staticStyle:{"padding":"0px 100px 20px 20px"},attrs:{"cols":"12"}},[_c('p',{staticClass:"mt-12"},[_vm._v(" Terms and conditions apply, see "),_c('a',{attrs:{"target":"_blank","href":"https://p-airnz.com/cms/assets/AU-Agents/Terms-and-Conditions-Helloworld-OMC-Duo-Trade-competition-Australia.pdf"}},[_vm._v("here")]),_vm._v(". ")]),_c('p',[_c('b',[_vm._v("Note:")]),_vm._v(" For those flying from other ports across Australia where there isn't an Air New Zealand lounge, you'll receive lounge access in New Zealand ahead of your return international flight. ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }