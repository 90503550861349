<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center mt-5">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card color="transparent-card" rounded="0" :elevation="0">
					<v-card-text class="pa-0">
						<div class="wrapper">
							<div class="h_iframe">
								<img v-if="$root.isBlank(page.valueMap.bodyImage)" hidden/>
								<img v-else class="ratio" width='100%' :src="$base + '/asset/file/' + page.valueMap.bodyImage" title="Image" frameborder="0"/>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12">
				<v-row class="d-flex justify-space-between">
					<v-col v-if="page.valueMap.footerButtonConfig == 'Default'">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="duo-text-button"
							@click="previousPage()">Previous</v-btn>
					</v-col>
					<v-col class="text-right pr-0">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="ml-auto duo-text-button"
							:right="true" @click="nextPage()">
							<div v-if="page.valueMap.footerButtonConfig == 'End Page'">Back to&nbsp;<b
									style="font-size: 16px !important;">duo</b>training</div>
							<div v-if="page.valueMap.footerButtonConfig != 'End Page'">Next</div>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa;
			} else {
				return "#2D2926";
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa;
			} else {
				return "#FFFFFFFF";
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa;
			} else {
				return "#221551";
			}
		}
	},
	data: function () {
		return {};
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
.wrapper {
	height: 100%;
	margin: 0 auto;
	background: #CCC
}

.h_iframe {
	position: relative;
}

.h_iframe .ratio {
	display: block;
	width: 100%;
	height: auto;
}

.h_iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#D3D3D3 .transparent-card {
	background-color: rgba(255, 255, 255, 0.5) !important;
}

.grey-box {
	background-color: rgba(211, 211, 211, 0.5) !important;
}

.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>