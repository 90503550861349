<template>
  <div v-if="show">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn @click="$router.push('/administration/creator/tile/video')" color="duo-secondary"
            class="mr-2">Back</v-btn>
            <v-btn @click="save" color="duo-primary">Save</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0" fluid>
      <div class="navbar d-flex flex-wrap pt-5">
        <img style="width: 216px" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
        <v-spacer />
        <img style="width: 182px" :src="$bucket + '/image/logo-airnz.svg'" />
      </div>
    </v-container>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row>
        <duo-tile :img-only="entity.imageOnly" :map="entity.map" :title="entity.title" :content="entity.content"
          :img="entity.image" :size="Number(entity.size)" @change="refresh" :btn-label="entity.buttonLabel"
          :is-preview="1" />
      </v-row>
    </v-container>

    <v-container style="margin-top:50px;">
      <duoFooter />
    </v-container>
  </div>
</template>

<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
import axios from 'axios';
import subHeader from "../subHeader.vue";
import duoFooter from "../duoFooter.vue";
import duoTile from "./duoTile.vue";

export default {
  components: {
    subHeader,
    duoFooter,
    duoTile
  },
  data() {
    return {
      contentCss: '<style scoped>ul {margin-left: 48px;}</style>',
      loaded: false,
      tab: 0,
      tabItemList: ["header", "body", "button"],

    }
  },
  props: {
    show: {
      default: true,
    },
    entity: {
      default: {
        type: 'Dashboard',
        size: 1,
        map: {
          allFilter: false,
          domainFilter: false,
          agencyGroupFilter: false,
        },
        transitMap: {
          dateStart: null,
          dateEnd: null,
        },
        buttonLabel: "Find out more",
      },
    },
    props: {
      isPreview: {
        type: Number,
        default: 0,
      },
      size: {
        type: Number,
        default: 1,
      },
      imgOnly: {
        type: Boolean,
        default: false,
      },
      img: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "",
      },
      content: {
        type: String,
        default: "",
      },
      btnLabel: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: 'Url',
      },
      link: {
        type: String,
        default: 'https://google.com',
      },
      map: {
        default: {},
      }
    },
    questionMap: {},
    submmittedMap: {}
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {
    refresh() {
      this.$forceUpdate();
    },
    save() {
      let url = '/system/creator/tile/add';

      if (this.entity.id) {
        url = '/system/creator/tile/edit';
      }

      // clean preference
      if (this.entity.map.agencyGroupFilter == false &&
        this.entity.map.domainFilter == false) {
        this.entity.map.allFilter = true;
      } else if (this.entity.map.allFilter == true) {
        this.entity.map.agencyGroupFilter = false;
        this.entity.map.domainFilter = false;
      } else {
        this.entity.map.agencyGroupList = this.$root.trimSpaces(this.entity.map.agencyGroupList);
        this.entity.map.domainList = this.$root.trimSpaces(this.entity.map.domainList);
      }

      if (this.entity.size > 1) {
        delete this.entity.map.headingTheme;
      }
      // end clean preference

      let formData = new FormData();
      formData.append("entity", JSON.stringify(this.entity));
      if (this.attachment) {
        formData.append("attachment", this.attachment);
      }

      axios.post(url, formData).then(
        (response) => {
          if (response.status == 200) {
            this.$router.push('/administration/creator/tile/video')
          }
        }
      )
    },
  }
};
</script>