<template>
	<v-row class="d-flex align-start pl-5 pt-4'" :style="'width: 100%; font-size: 28px;' + ' color: ' + color">
		<v-col class="d-flex flex-column px-2">
			<span style="font-size: 28px; line-height: 1.5; word-wrap: break-word; overflow-wrap: break-word;" v-html="label ? label.replace('<p>', '').replace('</p>', '') : ''"></span>
			<!-- <span class="duo-underline" :style="{marginTop: isEdit == true ? '0' : '-18px', fontSize: '28px'}"></span> -->
			<span class="duo-underline" :style="{ color: color }"></span>
		</v-col>
	</v-row>
</template>
<script>
export default {
	props: {
		label: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "#2d2926",
		}
	},
}
</script>