<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-row no-gutters style="padding: 20px 10px 15px">
        <v-col cols="12" sm="8">
          <div class="d-flex px-2">
            <h2>Report Christmas Chase</h2>
          </div>
        </v-col>
      </v-row>

      <!-- Christmas Chase Report -->
      <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
        <v-card-text>
          <v-form ref="form2" no-gutters class="d-flex flex-wrap">
            <v-row>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateChristmasChaseStartPicker" v-model="dateChristmasChaseStartMenu" :close-on-content-click="false"
                  :return-value.sync="dateChristmasChaseStart" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateChristmasChaseStart" :v-model="dateChristmasChaseStart"
                      :value="$root.parseDate(dateChristmasChaseStart)" readonly v-on="on" v-bind="attrs"
                      :rules="[(v) => !!v || 'Enter start date']" @click:prepend="dateChristmasChaseStartMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">Start date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateChristmasChaseStart" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateChristmasChaseStartPicker.save(dateChristmasChaseStart)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateChristmasChaseStartMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateChristmasChaseEndPicker" v-model="dateChristmasChaseEndMenu" :close-on-content-click="false"
                  :return-value.sync="dateChristmasChaseEnd" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateChristmasChaseStart" :v-model="dateChristmasChaseEnd"
                      :value="$root.parseDate(dateChristmasChaseEnd)" readonly v-on="on" v-bind="attrs"
                      :rules="[(v) => !!v || 'Enter end date']" @click:prepend="dateChristmasChaseEndMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">End date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateChristmasChaseEnd" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateChristmasChaseEndPicker.save(dateChristmasChaseEnd)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateChristmasChaseEndMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
          <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadChristmasChaseReport"><v-icon
              left>mdi-export</v-icon> Export Christmas Chase Report</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-main>
</template>

<style scoped>
.date-block {
  max-width: 230px !important;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dateChristmasChaseStartMenu: false,
      dateChristmasChaseStart: null,
      dateChristmasChaseEndMenu: false,
      dateChristmasChaseEnd: null,
      linksFilter: "",
      visitLinks: []
    };
  },
  mounted() {
    // this.listAllValue();
  },
  methods: {
    downloadChristmasChaseReport() {
      if (this.$refs.form2.validate()) {
        window.open(axios.defaults.baseURL + "/system/christmaschase/report/export?dateStart=" + this.dateChristmasChaseStart + "&dateEnd=" + this.dateChristmasChaseEnd);
      }
    },
  }
};
</script>
