<template>
	<div class="application">
		<div v-if="!$window.location.origin.includes('duo.pontoonx.io')">
			<div v-if="$window.location.origin.includes('test')" class="banner" style="background-color: #03b3b2; color: white">TEST</div>
			<div v-if="$window.location.origin.includes('localhost')" class="banner" style="background-color: #82ba00; color: white">DEVELOPMENT</div>
		</div>

		<router-view></router-view>
		<!-- <img v-show="$root.loader.value" src="loader.svg" class="loader" /> -->
		<link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet" />

		<div style="visibility: hidden; height: 0">
			<span style="font-family: duo"></span>
			<span style="font-family: AirNZ Black Italic"></span>
			<span style="font-family: AirNZ Sans Regular"></span>
			<span style="font-family: AirNZ Sans Semibold"></span>
			<span style="font-family: vlookicon"></span>
			<span style="font-family: fontello"></span>
			<span style="font-family: fontello-icon-briefcase"></span>
			<span style="font-family: fontello-admin-training-module"></span>
		</div>
	</div>
</template>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.v-list-group__header.v-list-item
	padding: 0

.v-list-group__header__append-icon
	position: absolute
	right: 15px

.v-list-group__header__append-icon > .v-icon
	color: white

.v-list-group__header.v-list-item.v-list-item--active
	background-color: #440099 !important
	text-decoration: none !important

.v-list-group__header.v-list-item.v-list-item--active > .v-list-item > .v-list-item__icon
	background-color: #221551 !important
	min-width: 56px
	height: 56px
	padding-right: 15px !important
	margin: 0 13px 0 auto !important

.v-list-group__items
	background-color: #440099 !important
	text-decoration: none !important

.v-list-group__items > .v-list-item > .v-list-item__icon
	background-color: #221551 !important
	min-width: 56px
	height: 56px
	padding-right: 15px !important
	margin: 0 13px 0 auto !important

.v-list-group__items:hover > .v-list-item:hover > .v-list-item__icon,
.v-list-group__items > .v-list-item--active.v-list-item > .v-list-item__icon
	background-color: #440099 !important
</style>

<style>
@font-face {
	font-family: "duo";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/duo.woff) format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "AirNZ Black Italic";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/AirNewzald-BlackItalic.woff2) format("woff2");
}
@font-face {
	font-family: "AirNZ Sans Regular";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/AirNewZealandSansSub-Regular.woff2) format("woff2");
}
@font-face {
	font-family: "AirNZ Sans Semibold";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/AirNewZealandSansSub-Semibold.woff2) format("woff2");
}
@font-face {
	font-family: "vlookicon";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/vlookicon.ttf) format("truetype");
}
@font-face {
	font-family: "fontello";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/fontello.ttf) format("truetype");
}
@font-face {
	font-family: "fontello-icon-briefcase";
	src: url(https://duonz.s3-ap-southeast-2.amazonaws.com/font/fontello-icon-briefcase.ttf) format("truetype");
}
@font-face {
	font-family: "duo-profile";
	src: url(https://duonz.s3.ap-southeast-2.amazonaws.com/font/duo-profile.ttf) format("truetype");
}
@font-face {
  font-family: 'new-menu-nz';
  src:  url('../public/fonts/new-menu-nz.eot');
  src:  url('../public/fonts/new-menu-nz.eot') format('embedded-opentype'),
    url('../public/fonts/new-menu-nz.ttf') format('truetype'),
    url('../public/fonts/new-menu-nz.woff') format('woff'),
    url('../public/fonts/new-menu-nz.svg') format('svg');
}
@font-face {
	font-family: "fontello-admin-training-module";
	src: url(https://airnzduo.s3.ap-southeast-2.amazonaws.com/font/fontello-admin-training-module.ttf) format("truetype");
}
.loader {
	position: fixed;
	z-index: 888;
	top: 30%;
	left: 50%;
	transform: translateX(-50%);
}
.no-background-hover::before {
	background-color: transparent !important;
}
.pnr input {
	text-transform: uppercase;
	text-align: center;
}
[class*="icon-profile-"]:before {
	display: inline-block;
	font-family: "duo-profile" !important;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-profile-personal:before {
	content: "\e800";
}
.icon-profile-company:before {
	content: "\e803";
}
.icon-profile-account:before {
	content: "\e801";
}
.icon-profile-agency:before {
	content: "\e802";
}

[class*="icon-anz-"]:before {
	display: inline-block;
	font-family: "vlookicon" !important;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-anz-logo:before {
	content: "\e900";
}
.icon-anz-logo-long:before {
	content: "\e901";
}
.icon-anz-airplane:before {
	content: "\E90B";
}
.icon-anz-calendar:before {
	content: "\E91B";
}
.icon-anz-person:before {
	content: "\E918";
}
[class*="duo-icon"]:before {
	display: inline-block;
	font-family: "fontello" !important;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.duo-icon-messages:before {
	content: "\e800";
}
.duo-icon-training:before {
	content: "\e801";
	margin-left: 3px;
}
.duo-icon-help:before {
	content: "\e802";
}
.duo-icon-warning:before {
	content: "\e803";
}
[class*="icon-duo-"]:before {
	display: inline-block;
	font-family: "duo";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-duo-alert:before {
	content: "\0041";
}
.icon-duo-approved:before {
	content: "\0042";
}
.icon-duo-available:before {
	content: "\0043";
}
.icon-duo-campaigns:before {
	content: "\0044";
}
.icon-duo-dashboard:before {
	content: "\0045";
}
.icon-duo-edit:before {
	content: "\0046";
}
.icon-duo-problem:before {
	content: "\0047";
}
.icon-duo-profile:before {
	content: "\0048";
}
.icon-duo-rewards:before {
	content: "\0049";
}
.icon-duo-sales:before {
	content: "\004a";
}
.icon-duo-tickets:before {
	content: "\004b";
}
.icon-duo-tools:before {
	content: "\004c";
}
.icon-duo-tour:before {
	content: "\004d";
}
.icon-duo-training:before {
	content: "\004e";
}
.icon-duo-plus:before {
	content: "\004f";
}
.icon-duo-close panel:before {
	content: "\0050";
}
.icon-duo-close:before {
	content: "\0051";
}
.icon-duo-exit:before {
	content: "\0052";
}
.icon-duo-open panel arrow2:before {
	content: "\0053";
}
.icon-duo-pending:before {
	content: "\0054";
}
.icon-duo-search:before {
	content: "\0055";
}
.icon-duo-spreadsheet:before {
	content: "\0056";
}
.icon-duo-trash:before {
	content: "\0057";
}
[class*="icon-briefcase"]:before {
	display: inline-block;
	font-family: "fontello-icon-briefcase";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-briefcase:before { 
	content: '\0058';
	margin-left: -2px;
	margin-right: -3px;
}

[class*="icon-tm"]:before {
	display: inline-block;
	font-family: "fontello-admin-training-module";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-tm-active:before { content: '\59'; } /* 'Y' */
.icon-tm-quick-edit:before { content: '\60'; } /* '`' */
.icon-tm-draft:before { content: '\61'; } /* 'a' */
.icon-tm-delete:before { content: '\62'; } /* 'b' */
.icon-tm-target:before { content: '\63'; } /* 'c' */
.icon-tm-incorrect:before { content: '\64'; } /* 'd' */


::selection {
	/* background: #65bd0091 !important; */
}
div.banner {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9;
	padding: 5px 10px;
	border-radius: 0 0 5px 5px;
	font-family: "PT Sans", sans-serif;
}
@media all and (max-width: 600px) {
}
/* password strength indicator */
.password-strength-initial {
	background-color: #eeeeee;
	font-size: 15px;
	color: #2d2926;
	margin-top: 10px;
	height: 60px;
}
.password-strength-veryweak {
	background-color: #bbbbbb;
	border: 2px solid #bbbbbb;
	color: white;
}
.password-strength-weak {
	background-color: #9f0737;
	border: 2px solid #9f0737;
	color: white;
}
.password-strength-strong {
	background-color: #65a500;
	border: 2px solid #65a500;
	color: white;
}

/* duo style */
h1.duo {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	line-height: 1.2;
	font-style: normal;
	font-weight: 700;
	font-size: 35px;
}
h1.duo:after {
	content: " ";
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 50px;
}
span.top-navigation-logged-in {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 12px;
	color: #2d2926;
	letter-spacing: 0px;
	font-weight: 400;
	text-transform: none;
}
h1.top-navigation-logged-in:after {
	content: " ";
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 50px;
}
h2.duo,
div.v-card__title.duo {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	color: #2d2926;
}
h2.duo:after {
	content: " ";
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 50px;
}
h3.duo {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}
h3.duo:after {
	content: " ";
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 50px;
}
h4.duo {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}
h4.duo:after {
	content: " ";
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	width: 50px;
}
span.duo {
	font-size: 17px;
	color: #2d2926;
}
div.v-text-field__details div.error--text div.v-messages__wrapper div.v-messages__message {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	color: #ec040f !important;
	font-size: 12px;
	line-height: normal !important;
	letter-spacing: 0px;
	font-weight: 400 !important;
}
a:hover.v-btn {
	text-decoration: none;
}
button.btn-primary {
	font-size: 16px !important;
	line-height: normal;
	letter-spacing: 0;
	font-weight: 400;
	height: 48px;
	background-color: #00b0b9 !important;
	text-transform: none !important;
	min-width: 85px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	border-radius: 4px !important;
}
button.btn-primary:hover {
	background-color: #00626e !important;
	text-transform: none !important;
	min-width: 85px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	border-radius: 4px !important;
}

/* primary */
button.duo-primary > span {
	color: white;
}
button.duo-primary {
	font-size: 16px !important;
	line-height: normal;
	letter-spacing: 0;
	font-weight: 400;
	width: 150px;
	height: 48px;
	background-color: #00b0b9 !important;
	text-transform: none !important;
	min-width: 85px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	border-radius: 4px !important;
}
button.duo-primary:hover {
	background-color: #00626e !important;
	width: 150px;

	text-transform: none !important;
	min-width: 85px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	border-radius: 4px !important;
}
a.duo-primary > span {
	color: #ffffff;
	/* font-width */
	width: 400px;
}
a.duo-primary {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 16px;
	line-height: normal;
	letter-spacing: 0;
	text-transform: none !important;

	background-color: #00b0b9 !important;
}
a.duo-primary:hover {
	background-color: #00626e !important;
}
/* secondary */
button.duo-secondary > span {
	color: #008392;
}
button.duo-secondary:hover > span {
	color: #00626e;
}
button.duo-secondary {
	font-size: 16px !important;
	line-height: normal;
	letter-spacing: 0;
	font-weight: 400;
	width: 150px;
	height: 48px;
	border-radius: unset;
	box-shadow: unset;
	border: thin solid #008392 !important;
	text-transform: none !important;
	min-width: 85px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	border-radius: 4px !important;
}
button.duo-secondary:hover {
	width: 150px;
	border: thin solid #00626e !important;
	text-transform: none !important;
	min-width: 85px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	border-radius: 4px !important;
}
button.duo-secondary::before {
	background-color: transparent !important;
}
a.duo-secondary > span {
	color: #008392;
	/* font-width */
	width: 400px;
}
a.duo-secondary:hover > span {
	color: #00626e;
}
a.duo-secondary {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 16px;
	line-height: normal;
	letter-spacing: 0;
	text-transform: none !important;
	border: thin solid #008392 !important;
}
a.duo-secondary:hover {
	border: thin solid #00626e !important;
}
/* tertiary */
button.duo-tertiary > span {
	color: white;
}
button.duo-tertiary {
	width: 170px;
	height: 48px;
	border-radius: unset;
	box-shadow: unset;
	background-color: #008392 !important;
}
button.duo-tertiary:hover {
	background-color: #00626e !important;
}
/* quaternary */
button.duo-quaternary > span {
	color: white;
}
button.duo-quaternary {
	width: 120px;
	height: 36px;
	border-radius: unset;
	box-shadow: unset;
	background-color: #008392 !important;
}
.duo-purple {
	color: #440099;
}
button.duo-quaternary:hover {
	background-color: #00626e !important;
}
a {
	color: #008392;
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 14px;
	text-decoration: underline;
}
a:hover {
	color: #00626e !important;
	text-decoration: underline;
}
p.duo-intro {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 17px;
	line-height: 25.5px;
	color: #2d2926;
}
p.duo,
a.duo,
span.duo,
div.v-card__text.duo {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 17px;
	color: #2d2926;
	line-height: 21px;
	font-weight: 400;
	letter-spacing: 0px;
}
ul.duo li {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 17px;
	color: #2d2926;
	line-height: 25.5px;
	font-weight: 400;
}
ol.duo li {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 17px;
	color: #2d2926;
	line-height: 25.5px;
	font-weight: 400;
}
.duo-footer {
	font-family: "AirNZ Sans Regular", arial, sans-serif;
	font-size: 14px;
	color: white;
}
.duo.v-input input {
	font-size: 17px !important;
}

/* override */
div.v-messages__message {
	line-height: 18px;
}
div.v-select__selections {
	line-height: 20px;
}
span.v-badge__badge {
	background-color: white !important;
	color: #440099;
}
.line-blue.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
	border-color: rgb(11, 187, 238);
	border-style: solid;
	border-top-width: 1px;
}
.v-text-field > .v-input__control > .v-input__slot:after {
	border-width: thin 0 0 0;
}
.line-space {
	margin-top: 10px;
	border-style: solid;
	border-top-width: 1px;
	border-color: rgb(128, 128, 128) !important;
}
.duo-text-button {
	font-size: 17px !important;
	text-transform: none;
}
.duo-underline {
	border-bottom: 4px solid currentColor;
	width: 50px;
	margin-top: 5px;
}
.duo-tile-title {
	font-size: 22px;
	color: #2d2926;
}
.duo-tile-title-bold {
	font-weight: bold;
	font-size: 28px;
	color: #2d2926;
	line-height: 1.2;
}
.duo-tile-title-bold-small {
	font-weight: bold;
	font-size: 22px;
	color: #2d2926;
	line-height: 1.2;
}
.duo-description {
	font-size: 17px;
	color: #2d2926;
	line-height: 21px;
	letter-spacing: 0;
}
.duo-description-link {
	font-size: 17px;
	line-height: 21px;
	letter-spacing: 0;
}
.mdi-eye {
	color: #707070 !important;
}
.mdi-eye-off {
	color: #707070 !important;
}
.mdi-eye.primary--text::before {
	color: #707070 !important;
}
.mdi-eye-off.primary--text::before {
	color: #707070 !important;
}
.v-btn {
	text-transform: none;
	letter-spacing: 0;
}
.duo-custom > div > p, .duo-custom > span > p, .duo-custom > p {
	margin-bottom: unset !important;
}
.duo-custom-link a{
	font-size: unset !important;
}
.duo-custom-template {
    max-width: 1271px;
}
.duo-custom-template-full {
    width: 100%;
}

h1.anz-italic {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
  font-weight: lighter;
}

h1.anz-regular {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

.duo-sheet {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

[class^="new-menu-"], [class*=" new-menu-"] {
  display: inline-block;
	font-family: 'new-menu-nz' !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new-menu-icon-discovery:before {
  content: "\e900";
	font-size: 2rem;
	margin-top: -8px;
	margin-left: -7px;
	margin-right: -5px;
}
.new-menu-icon-airnz:before {
	content: "\e901";
	font-size: 1.5rem;
	margin-top: -2px;
	margin-left: -5px;
	margin-right: -3px;
}

</style>

<script>
export default {
	data: () => ({})
};
</script>
