<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <div :style="{
            height: '375px',
            maxWidth: '100%',
            backgroundImage:
              'url(' +
              $bucket +
              '/image/dashboard-pages/Helloworld_Incentive.png',
            backgroundSize: 'cover',
          }"></div>
        </v-col>
      </v-row>
      <v-col cols="12" style="padding: 34px 100px 10px 20px">
        <h1>WIN lounge access before take off!</h1>
        <p>
          Air New Zealand lounges are a great place to start and end a holiday or work trip – especially when it’s on us.
        </p>
        <p>
          Our lounges have several different spaces for you to relax, refresh or get some work done before flying. You can enjoy barista-made coffee, New Zealand wines or beers, plus chef made fresh cuisine.
        </p>
      </v-col>
      <v-col cols="12" style="padding: 0px 100px 20px 20px">
        <h3>Play to WIN</h3>
        <p>
          To kick of your Helloworld OMC festivities early, we’re giving you the chance to win access to our lounge in
          either Sydney, Brisbane, Melbourne or Perth on your way to New Zealand, simply by completing the quiz below.
          It’s that easy!
        </p>
        <p>
          Hurry, this giveaway ends on 12 April 2023.
        </p>
      </v-col>
      <v-row class="mt-4 d-flex justify-center text-center">
        <iframe name="wayinFrame" class="pa-0 ma-o"
            src="https://xd.wayin.com/display/container/dc/3880ee3f-87cb-4c32-af38-bb018d84d107" frameborder="0"
            width="1220" height="1100px" id="ngxFramee6a918d0-72d5-43d3-933c-dd5dd4ef7e4f" allowTransparency="true"></iframe>

          <!-- <div style="width: 1271px; height: 480px; overflow-y: hidden; overflow-x: auto">
          <iframe name="wayinFrame" class="pa-0 ma-o"
            src="https://xd.wayin.com/display/container/dc/3880ee3f-87cb-4c32-af38-bb018d84d107" frameborder="0"
            width="1220" height="1100px" id="ngxFramee6a918d0-72d5-43d3-933c-dd5dd4ef7e4f" allowTransparency="true"
            style="position: relative; top: -610px"></iframe>
          </div> -->
      </v-row>
      <v-col cols="12" class="mt-" style="padding: 0px 100px 20px 20px">
        <p class="mt-12">
          Terms and conditions apply, see <a target="_blank"
            href="https://p-airnz.com/cms/assets/AU-Agents/Terms-and-Conditions-Helloworld-OMC-Duo-Trade-competition-Australia.pdf">here</a>.
        </p>
        <p>
          <b>Note:</b> For those flying from other ports across Australia where there isn't an Air New Zealand lounge, you'll receive lounge access in New Zealand ahead of your return international flight.
        </p>
      </v-col>
    </v-container>
  </v-container>
</template>

<style scoped>
.xOverlay {
    background: black;
    position: relative !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    text-align: center;
    background-color: rgba(234,234,234,0.93);
    padding: 28px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

h1 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 17px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>