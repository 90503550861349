<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/Sweetstake.Landing.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2 class="pt-8 pb-8 white--text">
            Our lolly jar is on a grand adventure across the Air New Zealand network, and we’re inviting you to join the fun! Each day, the jar will pop up in a new location, and we want you to guess how many lollies are inside. With a new guess every day, your chances of winning just keep growing!
          </h2>
          <div style="background-color:black; border: 1px solid white; margin-bottom:1.5vw; padding:1.5vw;">
            <ul class="py-4 white--text">
              <b>Here’s what’s up for grabs:</b>
              <li style="list-style-type: none; margin-left: 1vw; ">
                  <div style="width: 100%; float:left; margin-top: 10px;" >
                    <ul style="list-style-type:disc">
                      <li style="margin-top: 10px;"><b>Win one of five 100 Airpoints Dollars™ prizes every weekday! Five lucky winners will be randomly drawn each day!</b></li>
                      <li style="margin-top: 10px;"><b>And for the grand prize – the person who guesses the closest (or gets it spot on) will win a whopping 3,000 Airpoints Dollars™!</b></li>
                    </ul>
                  </div>
                  <br style="clear:both;"/>
              </li>
            </ul>
          </div>
          <p class="pt-4 white--text">
            <b>So, keep an eye our for the jar’s next stop, take your best guess, and you could be our next big winner! Enter below:</b>
          </p>
          <p style="font-size: 13px">
            <a
              class="white--text"
              style="font-size: 13px; color: black; font-style: italic"
              :href="$window.location.origin + '/#/sweetStakeTC'"
              target="_blank" 
              >Terms and conditions apply.</a
            >
          </p>
        </div>
        <div class="pt-3 pb-12">
          <v-btn @click="$router.push('/sweetStakeWayin')" depressed height="50" width="175" color="black" class="white--text text-transform-none" style="border: 1px solid white !important;">
            Enter Now
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
