<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-row no-gutters style="padding: 20px 10px 15px">
        <v-col cols="12" sm="8">
          <div class="d-flex px-2">
            <h2>Toolkit Report</h2>
          </div>
        </v-col>
      </v-row>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel v-for="item in result" :key="item.value">
            <v-expansion-panel-header>
              <div>
                <h3>{{ item.name }}</h3><br /><span>Total Count : <b>{{ item.totalCount }}</b></span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table :headers="panelHeader" :items="item.personActivityList">
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<style scoped>
.date-block {
  max-width: 230px !important;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      panelHeader: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Account Name", value: "agencyName" },
        { text: "State", value: "state" },
        { text: "Count", value: "count" },
        { text: "Last Clicked", value: "transitMap.created", width: "120px" },
      ],
      result: [

      ]
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios
        .get("/system/activitylog/report/toolkit")
        .then((response) => {
          this.result = response.data;
        })
        .catch(() => { });
    },
  }
};
</script>
