<template>
    <v-dialog width="1024px" v-model="show">
        <v-card>
            <v-card-title class="title white--text">
                <h5>{{ title }}</h5>
            </v-card-title>
            <v-card-text class="content pa-0">
                <v-row no-gutters>
                    <v-col cols="12" md="6"
                        :style="{ minHeight: '476px', backgroundImage: 'url(' + $bucket + image + ')', backgroundSize: 'cover', position: 'relative' }">
                    </v-col>
                    <v-col cols="12" md="6" class="px-8 pt-6">
                        <div v-html="content" />
                        <div>
                            <v-btn class="duo-text-button" width="193px" height="50px" color="duoPrimary" :elevation="0"
                                absolute bottom dark @click="onCloseDialog">
                                Got it
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.title {
    background-color: #2d2926;
}

.content {
    font-size: 17px !important;
    color: black !important;
}
</style>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        image: {
            type: String,
            default: ""
        },
        content: {
            type: String,
            default: ""
        }
    },
    methods: {
        onCloseDialog() {
            this.$emit("onCloseDialog");
        },
    },
}
</script>