<template>
	<v-container fluid>
		<subHeader/>
		<v-container fluid>
			<p style="font-size: 17px; margin-bottom: 30px; color: white; font-family: 'AirNZ Sans Regular'">
				<b> Agency Sales Support are a team of specialised consultants available to assist you by phone or email for any questions that you<br/>have about Air New Zealand products, fares and services.</b>
			</p>
			<v-row style="font-size: 17px; margin-bottom: 30px; color: white; font-family: 'AirNZ Sans Regular'; padding: 0 45px">
				<v-col style="max-width: 80px">
					Hours:<br/>
					Email:<br/>
					Phone:<br/>
				</v-col>
				<v-col cols="auto">
					Monday to Friday 9:00am–5:30pm EST (Friday from 9:30am)<br/>
					<a style="font-size: 17px; margin-bottom: 30px; font-family: 'AirNZ Sans Regular'" href="mailto:agencysupport@airnz.co.nz">agencysupport@airnz.co.nz</a><br/>
					1800 737 747<br/>
				</v-col>
			</v-row>
			<p style="font-size: 17px; margin-bottom: 30px; color: white; font-family: 'AirNZ Sans Regular'">
				<b>Frequently Asked Questions</b><br/>
				For the frequently asked questions presented to the Agency Sales Support team, please refer to<br>
				<a style="font-size: 17px; margin-bottom: 30px; font-family: 'AirNZ Sans Regular'" href="airnzagent.com.au/agency-sales-support" target="_blank">airnzagent.com.au/agency-sales-support</a>
			</p>
			<v-expansion-panels flat>
				<v-expansion-panel class="rounded-0 elevation-0" v-for="(item, i) in entityList" :key="i" style="margin-bottom: 25px; background-color: #ffffffd9">
					<v-expansion-panel-header>
						<p style="font-size: 18px; padding: 15px 0px 0px 20px; font-weight: bold; font-family: 'AirNZ Sans Semibold'">
							{{ item.title }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content style="font-size: 17px; padding: 0px 0px 0px 20px; margin-right: 12%">
						<div v-html="item.content"></div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>
	</v-container>
</template>

<style scoped>
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 0%;
		margin-right: 0%;
	}
}

@media (max-width: 576px) {
	.info-card {
		padding: 10px 0px !important;
	}

	.v-card__text {
		padding: 5px 10px !important;
	}
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 5%;
		margin-right: 5%;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 40px;
		margin-left: 0%;
		margin-right: 0%;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 18%;
		margin-right: 18%;
	}

	.info-row {
		margin: 0 150px !important;
	}
}

@media (min-width: 1680px) {
	.info-row {
		margin: 0 250px !important;
	}
}

@media (min-width: 1881px) {
	.info-row {
		margin: 0 400px !important;
	}
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
	components: {
		subHeader
	},
	data() {
		return {
			entityList: [],
			emailAgencySupport: "agencysupport@airnz.com.au",
			urlCovid19Policy: "https://www.airnzagent.com.au/covid-19-coronavirus-flexibility-policy",
			urlVirtualCard: "https://www.airnzagent.com.au/virtual-card",
			urlAuvcQuery: "http://forms.airnz.co.nz/cn/a2gtm/auvcquery",
			urlScheduleChangeRebooking: "https://www.airnzagent.com.au/schedule-change-rebooking",
			urlNameCorrectionPolicy: "https://www.airnzagent.com.au/name-correction-policy",
			urlGdsUserGuides: "https://www.airnzagent.com.au/gds-user-guides",
			urlPrepaySeatSelectFaqs: "https://www.airnzagent.com.au/prepay-seat-select-faqs",
			urlPrepaidExtraBags: "https://www.airnzagent.com.au/prepaid-extra-bags",
			urlAirNotesSubscibe: "https://airnzagent.us8.list-manage.com/subscribe?u=fc847423e8eb18ef5f0ed02dc&id=0ce6f512be",
			urlWorksDeluxe: "https://www.airnzagent.com.au/works-deluxe",
			urlSpecialAssistanceFlyingWithChildrenInfants: "https://www.airnzagent.com.au/special-assistance-flying-with-children-infants",
			urlInternationalTravel: "https://www.airnzagent.com.au/international-travel",
			urlAirNZSubscribe: "https://airnewzealand.us8.list-manage.com/subscribe?u=fc847423e8eb18ef5f0ed02dc&id=412e97b7c1",
			urlWebsite: "https://www.airnzagent.com.au"
		};
	},
	mounted: function () {
		window.scrollTo(0, 0);
		this.setData();
	},
	methods: {
		setData() {
			this.entityList = [
				{
					title: "Tickets reissued due to a date change and EMDs are associated to the original dates, can this be transferred over?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> <span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">For prepaid baggage: </span> Please contact <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> with original EMD's, along with the current PNR details and they can reisssue the EMD's to the new ticket </p> <p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> <span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">For prepaid seating: </span> Please contact <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> with original EMD's, along with the current PNR details and the seat numbers now required and they will rebook the seating and reissue the EMD's </p>"
				},
				{
					title: "I have reissued the ticket and have forgotten to collect the reissue fee.",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Please contact <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> for an authority to reissue the ticket again to collect the reissue fee. </p>"
				},
				{
					title: "How do I upgrade my customers flying Sydney to Auckloand return from seat+bag to now include a meal?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> The ticket is required to be reissued to minimum S and above booking class, please contact <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> to confirm the correct booking class. The <span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">works</span> is the product that is inclusive of a meal. The Seats to suit fare guide can be found <a target='_blank' href='" +
							this.urlWebsite +
							"' style='font-size: 17px'>here</a> </p>"
				},
				{
					title: "How do I add an infant onto an existing ticket?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Please add infant name onto the existing PNR and contact <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> for an authority to issue the infant ticket on the historical fare. </p>"
				},
				{
					title: "How long do I have to use my credit?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Credits are valid for 12 months from date credit issuance. Please Note: for any bookings that were placed in credit up to and including 30 June 2021, the credit validity date is up to 30 June 2022. Travel can be anytime within system range at the time of rebooking. You can find the latest Covid-19 flexibility policy <a target='_blank' href='" +
							this.urlCovid19Policy +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "How munch is left on my Virtual Card?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Air New Zealand will issue a <span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">'balance reporting statement'</span>, these are being sent directly to your Head Office, therefore please contact them ofr assistance. Alternatively, you can check the balance of a VirtualCard by either contacting <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> with the full Virtual Credit number or by completing the form <a target='_blank' href='" +
							this.urlAuvcQuery +
							"' style='font-size: 17px'>here.</a> More information on Virtual Cards can be found <a target='_blank' href='" +
							this.urlVirtualCard +
							"' style='font-size: 17px'>here</a>  </p>"
				},
				{
					title: "When will my customers credit be uploaded onto a Virtual Card?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Virtual Cards are loaded and delivered in batches which are processed once a month (usually around the 3rd week of the month). Each batch includes tickets where the ticketed dates of travel have now passed. For example, if you customers were booked to travel in August 2021 with travel completed by 21 August 2021 then their credit would be loaded onto an Air New Zealand Virtual card in the August 2021 batch run.</p><p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> <span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">Note:</span> Ticket which are defined as complex are not yet processed onto Virtual Card. More information on Virtual Card can be found <a target='_blank' href='" +
							this.urlVirtualCard +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "My customer is not happy with the new flights after a schedule change. What are the options?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Air New Zealand's schedule change policy can be found <a target='_blank' href='" +
							this.urlScheduleChangeRebooking +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "I have incorrectly spelt the name of my customer and the booking is now ticketed. How do I amend?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Air New Zealand's name correction policy including the process to amend can be found <a target='_blank' href='" +
							this.urlNameCorrectionPolicy +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "What is the entry for checking if an Economy SkycouchTM is available?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> The process for checking availability of an Economy SkycouchTM varies between each GDS. The process for booking and issuing an EMD for each GDS can be found <a target='_blank' href='" +
							this.urlGdsUserGuides +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "My customer paid for seating and an extra bag. Are these refundable?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Find out more and view the conditions for changing or refunding prepaid seating <a target='_blank' href='" +
							this.urlPrepaySeatSelectFaqs +
							"' style='font-size: 17px'>here</a> and for prepaid extra bags <a target='_blank' href='" +
							this.urlPrepaidExtraBags +
							"' style='font-size: 17px'>here.</a></p>"
				},
				{
					title: "How can I link two bookings together? (TCP)",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> The entry varies between each GDS. Please cantact your GDS provider for the correct entry.</p> <p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"><span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">Note:</span> TCP stands for <span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">Total Complete Party,</span> therefore within each PNR you will be required to advise the total number of travellers.</p>"
				},
				{
					title: "What is Airnotes? How do I sign up to receive Airnotes?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Airnotes is our Australia Trade Communications, distributed via email and inclues the latest Air New Zealand industry news, updates, incentives and competitions. You can sign up to receive Airnotes <a target='_blank' href='" +
							this.urlAirNotesSubscibe +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "How can I issue UMNR EMD? What is the entry?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Yes, you are able to issue an EMD for the UMNR fee. The process for booking, confirming and playing for an UMNR varies between each GDS, the correct process for each GDS is <a target='_blank' href='" +
							this.urlGdsUserGuides +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "How can I check worksdeluxe availability? Can I asign seating in the GDS?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Please contact <a href='mailto:" +
							this.emailAgencySupport +
							"' style='font-size: 17px'> Agency Support </a> to check works<span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">deluxe</span> availability. </p><p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> At time of ticketing, a fare quote over-ride is required to purchase worksdeluxe. The GDS override entries can be found <a target='_blank' href='" +
							this.urlWorksDeluxe +
							"' style='font-size: 17px'>here.</a> Once ticketed, you are required to allocate the neighbour-free seating for your customer immediately to avoid any issues.</p>"
				},
				{
					title: "My customers are travelling with a child. Are they allowed to check a car seat and/or a stroller free of charge?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Yes, your customer can check in a stroller and a car seat for their traveling child at no additional charge. These items do not form part of the baggage allowance. In addition, your customers can take a pram or stroller for their travelling child if the fares are not inclusive of chacked baggage.</p><p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> More info an travelling with children/infants can be found <a target='_blank' href='" +
							this.urlSpecialAssistanceFlyingWithChildrenInfants +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "How do i find out if my customers can transit through a particular country?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> For informatrion on transiting particular countries, entry requirements and visas, please always contact the local government for the country you require, this will ensure the most up to date information on travel restrictions and requirements.</p>"
				},
				{
					title: "Are Custromers required to be at the airport early with the global Covid-19 situation?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Yes, it is advides to allow for additional time to check in due to requirements required at selected air ports.<br>More information on international travel from Australia can be found <a target='_blank' href='" +
							this.urlInternationalTravel +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "Are name changes allowed?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Name changes are not permitted and the ticket cannot be transferred to another person. However, Air New Zealand does permit name corrections when a genuine error has been made. The name correction policy including the process to amend can be found <a target='_blank' href='" +
							this.urlNameCorrectionPolicy +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "When do you charge a name correction fee?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> When the name correction involves a completely different name, for example amending from a maiden name to a marreid name and a name change fee will apply. The name correction policy including the process to amend can be found <a target='_blank' href='" +
							this.urlNameCorrectionPolicy +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "What is the US DoT policy? How can I find out if my customer is eligible for a refund on this policy?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> US Department of Transportation (DoT) requlations states that all passengers on cancelled flights to/from/within the USA are permitted to a full refund of their airfare with no penalty. Find out more on the US Department of Transport refund regulations within the Covid Flexibility policy <a target='_blank' href='" +
							this.urlCovid19Policy +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "How can I sign up to receive Virtual Card updates?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> To receive Virtual Card updates straight to your inbox, sign up <a target='_blank' href='" +
							this.urlAirNZSubscribe +
							"' style='font-size: 17px'>here.</a> </p><p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> More information on Virtual Cards can be found <a target='_blank' href='" +
							this.urlVirtualCard +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "My customer has purchased a seat fare. How can they add a bag?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> The ticket is required to be reissued, please reissue to a seat<span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">+bag</span> fare. If additional baggage is required on top of the 1pc within seat<span style=\"font-size: 17px; font-family: 'AirNZ Sans SemiBold'\">+bag</span>, prepaid baggage can be purchased once you have reissued the ticket. More information on prepaid baggage can be found <a target='_blank' href='" +
							this.urlPrepaidExtraBags +
							"' style='font-size: 17px'>here.</a> </p>"
				},
				{
					title: "My customer is flying from Auckland to Cairns via Brisbane. Can they prepay an additional bag for the entire journey?",
					content:
							"<p style=\"font-size: 17px; font-family: 'AirNZ Sans Regular'\"> Additional baggage are only available on Air New Zealand operated flights and ticketed on 086 ticket stock. Additional baggage is not available to be purchased on codeshare flights and flights operated by partner airlines. More information on prepaid baggage can be found <a target='_blank' href='" +
							this.urlPrepaidExtraBags +
							"' style='font-size: 17px'>here.</a> </p>"
				}
			];
		}
	}
};
</script>