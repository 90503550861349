<template>
	<v-container class="d-flex fluid align-center pa-0 justify-center" style="margin-top: 30px;">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-card width="100%" color="transparent-card" rounded="0" :elevation="0">
					<v-card-title class="pa-0 ma-0" :style="{ backgroundColor: parsedHeaderBackgroundColor, color: 'white', minHeight: '58px', height: 'auto' }">
						<div v-html="page.valueMap.headerTitle" class="pt-3 pl-10 mr-12 pr-12 text-wrap"></div>
					</v-card-title>
					
					<v-card-text class="ma-0 pa-0">
						<div v-if="$root.isBlank(page.valueMap.headerIcon)" style="position: relative;">
							<svg xmlns="http://www.w3.org/2000/svg" width="57.824" height="57.824" viewBox="0 0 57.824 57.824" style="position: absolute; right: 30px; margin-top: -32px">
								<g id="Group_3369" data-name="Group 3369" transform="translate(-1109 -331)">
									<circle id="Ellipse_12" data-name="Ellipse 12" cx="28.912" cy="28.912" r="28.912" transform="translate(1109 331)" :fill="parsedHeaderIconBackgroundColor"/>
								</g>
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" width="57.824" height="57.824" viewBox="0 0 57.824 57.824" style="position: absolute; right: 13px; margin-top: -16px">
								<path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z"  :fill="parsedHeaderIconColor" />
							</svg>
						</div>
						<v-img v-else :src="($base + '/asset/file/' + page.valueMap.headerIcon)" width="60" style="position: absolute; right: 30px; margin-top: -31px" />

						<v-form v-model="validForm" ref="form" lazy-validation>
							<v-container class="d-flex align-center" style="height: auto;">
								<v-row class="px-12 pt-4 pb-4" no-gutters>
									<v-col v-if="page.valueMap.bodyVideo && page.valueMap.bodyVideo.length > 0" class="d-flex justify-center" cols="12">
										<iframe v-if="page.valueMap.bodyVideo && (page.valueMap.bodyVideo.startsWith('http://') || page.valueMap.bodyVideo.startsWith('https://'))" width="640" height="360" :src="page.valueMap.bodyVideo" title="Video" frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowfullscreen />
									</v-col>
									<v-col v-if="page.valueMap.bodyQuestionFreeAnswer.questionText" cols="12" class="d-flex justify-left align-center text-wrap">
										<div v-html="page.valueMap.bodyQuestionFreeAnswer.questionText"></div>
									</v-col>
									<v-col cols="6" class="d-flex justify-start pt-3">
										<v-textarea rows="3" v-model="responseText" label="Answer here"  filled background-color="white" :disabled="isSubmitted" class="rounded-0" :class="textFieldColor" :hide-details="textFieldColor != ''? true:false" :rules="[(v) => !!v || 'Please enter your answer']"></v-textarea>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" class="d-flex justify-end">
				<v-row class="d-flex flex-wrap">
					<v-col v-if="!isSubmitted" cols="12" class="d-flex justify-end">
						<v-btn depressed right height="50" width="175" color="#00B0B9" class="white--text ml-auto text-transform-none" @click="submit" :disabled="!validForm || isSubmitted">Submit</v-btn>
					</v-col>

					<v-col v-if="isSubmitted" cols="12" >
						<v-row v-if="result" no-gutters class="correct-answer d-flex justify-end">
							<v-alert dense type="success">Submitted</v-alert>
						</v-row>
					</v-col>
				</v-row>
			</v-col>

			<v-col v-if="isSubmitted" cols="12">
				<v-row class="d-flex justify-space-between">
					<v-col>
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="duo-text-button" @click="previousPage()">Previous</v-btn>
					</v-col>
					<v-col class="text-right pr-0">
						<v-btn height="50" width="193" :elevation="0" outlined color="duoSecondary" class="white--text ml-auto duo-text-button" :right="true" @click="nextPage()">Next</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		parsedHeaderBackgroundColor() {
			if (this.page.valueMap.headerBackgroundColor) {
				return this.page.valueMap.headerBackgroundColor.hexa
			} else {
				return '#2D2926'
			}
		},
		parsedHeaderIconColor() {
			if (this.page.valueMap.headerIconColor) {
				return this.page.valueMap.headerIconColor.hexa
			} else {
				return '#FFFFFFFF'
			}
		},
		parsedHeaderIconBackgroundColor() {
			if (this.page.valueMap.headerIconBackgroundColor) {
				return this.page.valueMap.headerIconBackgroundColor.hexa
			} else {
				return '#221551'
			}
		},
		textFieldColor() {
			return this.isSubmitted ? (this.result ? 'color: green !important;' : 'color: red !important;') : '';
		}
	},
	data: function () {
		return {
			responseText: null,
			validForm: true,
			isSubmitted: false,
			result: null
		};
	},
	methods: {
		fetchAnswer(response) {
			let pageScore = response.data.map.pageScore.find(item => parseInt(item.pageId) === this.page.id);
			this.responseText = pageScore.answer;
			if(this.responseText != null && this.responseText !== ""){
				this.isSubmitted = true;
				this.result = true;
			}
		},
		submit() {
			if (this.$refs.form.validate()) {
				this.isSubmitted = true;
				this.result = true;
				

				if (!this.isPreview) {
					this.page.valueMap.responseText = this.responseText;
					// this.save();
					this.$emit("submit");
				}
			}
		},
		save() {
			// TODO
			// API Check + Save User Answer
			axios
				.post("/system/page/edit", { page: this.page })
				.then(() => {
				})
				.catch(() => {});
		},
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		},
	}
};
</script>

<style scoped>
.form-group {
	height: 80vh;
}
.radio-question .v-label {
	font-size: 20px !important;
}
.radio-question .error--text {
	font-size: 20px !important;
}
.v-application .title {
	font-size: 26px !important;
	font-weight: 600;
	letter-spacing: 1px !important;
}
.correct-answer {
	color: green;
	font-size: 20px;
	font-weight: bold;
}
.wrong-answer {
	color: red;
	font-size: 20px;
	font-weight: bold;
}
.question-answer {
	border-left: 2px solid rgba(224, 224, 224, 0.5);
}
</style>