<template>
	<v-app>
		<v-content>
			<v-overlay z-index="5"></v-overlay>
			<div style="background-size:cover;background-attachment:fixed;min-height:100vh;display:flex;flex-direction:column;padding-bottom:80px;" :style="{ backgroundImage:'url(' + $bucket + '/image/background-authentication.jpg' + ')' } ">
				<v-layout style="z-index:6;" column align-center justify-center>
					<v-form ref="form" v-model="valid">
						<v-card v-if="!showFinishCard" max-width="1500">
							<v-card-title>
								<v-spacer></v-spacer>
								<v-btn to="/login" icon color="secondary">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-card-title>

							<v-card-text class="px-12">
								<v-layout column align-center justify-center>
									<h2 class="duo">Company Profile Change Request</h2>
									<span>{{person.id}} - {{person.firstName}} {{person.lastName}}</span>
								</v-layout>
							</v-card-text>
							<v-card-text class="px-12">
								<v-row>
									<v-col>
										<v-card-text class="px-12">
											<v-layout column align-start justify-start>
												<h2 class="duo">Current Company</h2>
												<br />
												<span class="duo">Parent Account: {{currentAgency.agencyGroup.name}}</span>
												<span class="duo">Agency Pseudo Code: {{currentAgency.agency.code}}</span>
												<span class="duo">Account Name: {{currentAgency.agency.name}}</span>
												<span class="duo">Job Title: {{currentAgency.jobTitle}}</span>
												<span class="duo" v-if="person.mobileAgent">Mobile travel consultant</span>
												<span class="duo" v-else>Home based consultant</span>
												<br />
												<h4 class="duo">Business Address</h4>
												<span class="duo">Apartment, Suite, Unit, Level etc: {{currentAgency.agency.unit}}</span>
												<span class="duo">Street, Road, etc: {{currentAgency.agency.street}}</span>
												<span class="duo">Suburb: {{currentAgency.agency.suburb}}</span>
												<span class="duo">City/Town: {{currentAgency.agency.city}}</span>
												<span class="duo">Postcode: {{currentAgency.agency.postCode}}</span>
												<span class="duo">State: {{currentAgency.agency.state}}</span>
												<br />
												<h4 class="duo" v-if="currentAgency.personalAddress">Personal Address</h4>
												<span class="duo" v-if="currentAgency.personalAddress">Apartment, Suite, Unit, Level etc: {{person.addressUnit}}</span>
												<span class="duo" v-if="currentAgency.personalAddress">Street, Road, etc: {{person.addressStreet}}</span>
												<span class="duo" v-if="currentAgency.personalAddress">Suburb: {{person.addressSuburb}}</span>
												<span class="duo" v-if="currentAgency.personalAddress">City/Town: {{person.addressCity}}</span>
												<span class="duo" v-if="currentAgency.personalAddress">Postcode: {{person.addressPostCode}}</span>
												<span class="duo" v-if="currentAgency.personalAddress">State: {{person.addressState}}</span>
											</v-layout>
										</v-card-text>
									</v-col>
									<v-col>
										<v-card-text class="px-12">
											<v-layout column align-start justify-start>
												<h2 class="duo">Requested Change</h2>
												<br />
												<span class="duo">Parent Account: {{requestedAgencyGroup.name}}</span>
												<span class="duo">Agency Pseudo Code: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.code : agencyRequested.map.agency.code}}</span>
												<span class="duo">Account Name: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.name : agencyRequested.map.agency.name}}</span>
												<span class="duo">Job Title: {{agencyRequested.map.jobTitle}}</span>
												<span class="duo" v-if="agencyRequested.map.mobileAgent">Mobile travel consultant</span>
												<span class="duo" v-else>Home based consultant</span>
												<br />
												<h4 class="duo">Business Address</h4>
												<span class="duo">Apartment, Suite, Unit, Level etc: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.unit : agencyRequested.map.agency.unit}}</span>
												<span class="duo">Street, Road, etc: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.street : agencyRequested.map.agency.street}}</span>
												<span class="duo">Suburb: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.suburb : agencyRequested.map.agency.suburb}}</span>
												<span class="duo">City/Town: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.city : agencyRequested.map.agency.city}}</span>
												<span class="duo">Postcode: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.postCode : agencyRequested.map.agency.postCode}}</span>
												<span class="duo">State: {{isHaveNewAgency == true ? agencyRequested.transitMap.newAgency.state : agencyRequested.map.agency.state}}</span>
												<br />
												<h4 class="duo" v-if="agencyRequested.map.isPersonalAddress">Personal Address</h4>
												<span class="duo" v-if="agencyRequested.map.isPersonalAddress">Apartment, Suite, Unit, Level etc: {{agencyRequested.map.personalAddress.unit}}</span>
												<span class="duo" v-if="agencyRequested.map.isPersonalAddress">Street, Road, etc: {{agencyRequested.map.personalAddress.street}}</span>
												<span class="duo" v-if="agencyRequested.map.isPersonalAddress">Suburb: {{agencyRequested.map.personalAddress.suburb}}</span>
												<span class="duo" v-if="agencyRequested.map.isPersonalAddress">City/Town: {{agencyRequested.map.personalAddress.city}}</span>
												<span class="duo" v-if="agencyRequested.map.isPersonalAddress">Postcode: {{agencyRequested.map.personalAddress.postCode}}</span>
												<span class="duo" v-if="agencyRequested.map.isPersonalAddress">State: {{agencyRequested.map.personalAddress.state}}</span>
											</v-layout>
										</v-card-text>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions class="d-flex flex-wrap justify-center pb-6">
								<v-btn @click="approveRequest(), isApprove=true" class="duo-primary ma-2">Approve</v-btn>
								<v-btn @click="rejectReenable(), isApprove=false" class="duo-primary ma-2 pa-2">Reject & Enable</v-btn>
								<v-btn @click="rejectKeepDisable(), isApprove=false" class="duo-primary ma-2 pa-2">Reject & Disable</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>

					<!--If request has been approved or rejected-->
					<v-card v-if="showFinishCard" max-width="500">
						<v-card-title class="pb-0">
							<v-spacer></v-spacer>
							<v-btn to="/login" icon color="secondary">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-title>

						<v-card-text class="pb-3 pr-3 pl-3 pt-0">
							<v-layout column align-center justify-center>
								<p v-if="isApprove" class="title mt-6 px-6" style="text-align: center;">Request has been approved</p>
								<p v-else class="title mt-6 px-6 duo" style="text-align: center;">Request has been rejected</p>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-layout>
				<duoFooter />
			</div>
		</v-content>
	</v-app>
</template>

<style scoped>
</style>

<script>
import axios from "axios";
import duoFooter from "./duoFooter.vue";

export default {
	props: ["agencyChangeRequestId"],
	components: {
		duoFooter
	},
	data() {
		return {
			email: null,
			valid: true,
			showFinishCard: false,
			agencyRequested: { map: { agency: {} }, transitMap: {newAgency: {}} },
			currentAgency: { agency: {}, agencyGroup: {} },
			person: {},
			isApprove: false,
			requestedAgencyGroup: { name: "" },
			isHaveNewAgency: false
		};
	},
	mounted: function() {
		this.getAgencyChangeRequest();
	},
	methods: {
		approveRequest() {
			axios
				.post("/system/agency/request/approve", { id: this.agencyChangeRequestId })
				.then(() => {
					this.showFinishCard = true;
				})
				.catch(() => {});
		},
		rejectKeepDisable() {
			axios
				.post("/system/agency/request/reject/disable", { id: this.agencyChangeRequestId })
				.then(() => {
					this.showFinishCard = true;
				})
				.catch(() => {});
		},
		rejectReenable() {
			axios
				.post("/system/agency/request/reject/reenable", { id: this.agencyChangeRequestId })
				.then(() => {
					this.showFinishCard = true;
				})
				.catch(() => {});
		},
		getAgencyChangeRequest() {
			axios
				.post("/system/agency/change/request", { id: this.agencyChangeRequestId })
				.then(response => {
					this.agencyRequested = response.data;
					if(this.agencyRequested.transitMap.newAgency.id){
						this.isHaveNewAgency = true;
					}
					if (this.agencyRequested.status != "Pending") {
						this.showFinishCard = true;
						if (this.agencyRequested.status == "Approved") {
							this.isApprove = true;
						} else {
							this.isApprove = false;
						}
					}
					this.getCurrentAgency();
					this.getAgencyGroup();
				})
				.catch(() => {});
		},
		getCurrentAgency() {
			axios
				.post("/system/person", { id: this.agencyRequested.personId })
				.then(response => {
					this.currentAgency = response.data.map;
					this.person = response.data;
				})
				.catch(() => {});
		},
		getAgencyGroup() {
			axios
				.post("/system/agency/group", { id: this.isHaveNewAgency ? this.agencyRequested.transitMap.newAgency.agencyGroupId : this.agencyRequested.map.agencyGroup.id })
				.then(response => {
					this.requestedAgencyGroup = response.data;
				})
				.catch(() => {});
		}
	}
};
</script>