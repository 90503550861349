<template>
	<v-dialog v-model="$store.state.notification.show" width="505px">
		<v-card class="white" height="100%" :style="{ borderRadius: '8px'}">
			<v-card-title class="pa-0" height="66px" :style="{ backgroundColor: getBackgroundColor() }">
				<div :style="{ margin: '18px 17px 18px 24.5px' }">
					<v-img width="30px" height="30px" :src="$bucket + selectImagePathByTitle()" contain style="color: white;"></v-img>
				</div>
				<div>
					<span class="notification-title-text-style">{{ getNotificationTitle() }}</span>
				</div>
			</v-card-title>
			<v-card-text class="pa-0" height="190.71px">
				<div :class="getContentStyleClass()">
					<div v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
						<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
						<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
						<div :key="item" v-for="(item, index) in $store.state.notification.content.noticeList">
							<div v-if="index == 0 && item.includes('Session')" class="notification-content-text-style">{{ item }}</div>
							<div v-else class="regular-text-style" style="color: #2D2926 !important; padding-top: 20px;">{{ item }}</div>
						</div>
					</div>
					<div v-else>
						<div v-html="getNotificationContent()" class="notification-content-text-style"></div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="px-0 pt-1 justify-center" height="93.29px" :style="{ paddingBottom: '29.85px'}">
				<v-btn @click="hideNotification" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom dark>
					<span class="regular-text-style">{{ getNotificationButtonText() }}</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
/* eslint-disable no-console */
export default {
	data: function () {
		return {};
	},
	mounted: function () {
	},
	methods: {
		selectImagePathByTitle() {
			if (this.$store.state.notification && this.$store.state.notification.title === "Awesome") {
				return '/image/success.svg';
			} else {
				return '/image/warning.svg';
			}
		},
		getBackgroundColor() {
			if (this.$store.state.notification && this.$store.state.notification.color) {
				return this.$store.state.notification.color;
			} else {
				return "#440099 !important";
			}
		},
		getNotificationTitle() {
			if (this.$store.state.notification && this.$store.state.notification.title) {
				if (this.$store.state.notification.title === "Unauthorized") {
					return "Warning";
				} else if (this.$store.state.notification.title) {
					return this.$store.state.notification.title;
				}
			}
			return "Error";
		},
		getNotificationContent() {
			if (this.$store.state.notification && this.$store.state.notification.content) {
				return this.$store.state.notification.content;
			} else {
				return "Internal service error";
			}
		},
		getContentStyleClass() {
			if (this.getNotificationTitle() === "Awesome") {
				return 'notification-content-awesome-style';
			} else if (this.getNotificationTitle() === "Error" || this.getNotificationTitle() === "Unknown error") {
				return 'notification-content-error-style';
			} else {
				return 'notification-content-warning-style';
			}
		},
		getNotificationButtonText() {
			if (this.$store.state.notification && this.$store.state.notification.buttonText) {
				return this.$store.state.notification.buttonText;
			} else {
				return "Got it";
			}
		},
		hideNotification() {
			if (this.$store.state.notification.title === "Unauthorized") {
				this.$store.commit("hideNotification");
				this.$router.push("/");
			} else {
				this.$store.commit("hideNotification");
			}
		}
	}
};
</script>

<style scoped>
.notification-title-text-style {
	letter-spacing: 0px;
	color: #FFFFFF;
	text-align: left;
	font-style: normal;
	font-variant: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 32px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
}
.notification-content-text-style {
	letter-spacing: 0px;
	color: #2D2926;
	text-align: center;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
	white-space: pre-wrap;
}
.regular-text-style {
	letter-spacing: 0px;
	color: #FFFFFF;
	text-align: center;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 21px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
	white-space: pre-wrap;
}
.notification-content-error-style {
	margin: 47.29px 71.45px 13.44px 72.45px;
	text-align: center;
	/* height: 77.42px; */
}
.notification-content-awesome-style {
	margin: 37.29px 71.45px 30.44px 72.45px;
	text-align: center;
	/* height: 77.42px; */
}
.notification-content-warning-style {
	margin: 39.74px 71.45px 30.98px 72.45px;
	text-align: center;
	/* height: 77.42px; */
}
</style>