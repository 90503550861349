<template>
	<v-main>
		<div style="background-size:cover;background-attachment:fixed;min-height:100vh;display:flex;flex-direction:column;padding-bottom:80px;" :style="{ backgroundImage:'url(' + $bucket + '/image/background/Login.BG.jpg' + ')' } ">
			<v-card raised style="margin-top:20vh;align-self:center;width:500px;padding:7px 7px 50px 7px;" color="rgba(255,255,255,0.75) !important" v-if="phase != null">
				<div style="text-align:right;">
					<v-btn icon color="secondary" to="/">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<div style="text-align:center;margin-bottom:15px;font-size:24px;color:rgba(0,0,0,0.75);">
					Confirmation email
				</div>

				<div style="font-size:14px;padding:0 15%;">
					<template v-if="phase == 'confirmed'">
						Congratulations! Your email has been successfully confirmed. To login please click
						<router-link :to="'/'">here</router-link>.
					</template>
					<template v-if="phase == 'resend-email'">
						Whoops! Your confirmation link is either invalid or has expired.
						To resend the confirmation email, please enter your email address below.
						<v-form ref="form">
							<v-text-field
								v-model="email"
								placeholder="Email Address"
								:rules="[
									(v) => !!v || 'Please enter your registered email address',
									(v) =>
									(v &&
										v.match(
										/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
										) &&
										true) ||
									'Please enter a valid email address',
								]"
							></v-text-field>
							<div style="text-align:center;">
								<v-btn large class="duo-primary" @click="resend">Resend</v-btn>
							</div>
						</v-form>
					</template>
					<template v-if="phase == 'resent'">Confirmation email has been resent. To complete your <b>duo</b> account set up click the link provided within the email and login.</template>
					<template v-if="phase == 'active' || phase == 'invalid'">
						Whoops, looks like you have already confirmed your registration.
						Please log in to <b>duo</b> to get started.
						<v-col cols="12" class="d-flex flex-column pa-0 pt-2" style="margin-top: 8px" align-self="center">
							<v-row no-gutters justify="center">
								<v-btn id="loginBtn" class="btn-login elevation-0" to="/login">
									Login
								</v-btn>
							</v-row>
						</v-col>
					</template>
				</div>
			</v-card>
		</div>
	</v-main>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";

export default {
	data() {
		return {
			email: null,
			phase: null
		};
	},
	mounted: function() {
		this.confirmRegistration();
		this.doRememberMe();
	},
	methods: {
		confirmRegistration() {
			axios
				.post("/system/authentication/registration/confirm", {
					token: this.$route.query.token,
					type: "Registration"
				})
				.then(response => {
					if (response.data) {
						this.phase = "confirmed";
					} else {
						this.phase = "invalid";
					}
				})
				.catch(() => {});
		},
		doRememberMe() {
			axios
				.get("/system/authentication/remember/me")
				.then(response => {
					if (response.data && response.data.length > 0) {
						this.email = response.data;
					}
				})
				.catch(() => {});
		},
		resend() {
			if (this.$refs.form.validate()) {
				axios
					.post("/system/authentication/registration/resend", {
						email: this.email
					})
					.then((response) => {
						this.phase = response.data;
					})
					.catch(() => {});
			}
		}
	}
};
</script>

<style scoped>
.btn-login {
	width: 192px !important;
	height: 50px !important;
	font-size: 17px !important;
	color: #ffffff !important;
	background-color: #00b0b9 !important;
	border-radius: 4px;
	opacity: 1;
	text-transform: none !important;
	letter-spacing: 0;
}
</style>