<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/UA - USA.Landing Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            Air New Zealand, United Airlines and Brand USA are excited to launch a four-week sales and training incentive to drive sales to the USA.
          </h2>
          <p>
            The incentive kicks off Tuesday 29th October, where all you need to do is log bookings via duosales and complete a Brand USA discovery programme badge to be in to win. Agents will have four weeks to earn points for the grand prize of return flights for two to Houston with Air New Zealand, with add on domestic connections from United Airlines. Our friends over at Alamo are also throwing in a generous 7 day car rental for the Grand Prize winner, so they can see more of the USA!
          </p>
          <p class="pt-5">
            There will also be a different mini weekly incentive, and this week we’re excited to kick off with our Discover North America training module where you could be in to win one of five $100 Prezzy Cards!
          </p>
          <p>
            <b>Sounds good right?</b>
          </p>
          <br />
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;" class="pl-12">
            <p>
              <b>To enter in our Grand Prize, you must:</b>
            </p>
            <ul >
              <li style="margin-top: 10px; padding-left: 10px;">
                Book, and ticket flights to the USA on either Air New Zealand or United Airlines, on 086 ticket stock only, between 29 October and 24 November 2024.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Agents must register their ticketed Air New Zealand booking references through Duosales.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Each ticket issued will earn you one point.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Bookings logged on behalf of another agent are ineligible for entry
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Agents must enter the correct PNR creation date for the entry to be valid.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Booked travel may be availed for any time.
              </li>
              <li style="margin-top: 10px; padding-left: 10px;">
                Participants must also sign up and complete at least one badge on USA Discovery Program (Brand USA's Training Platform). Platinum agents that have already completed all the badges will need to retrain in one of the badges and/or use the retail/ self features on the platform to qualify.
              </li>
            </ul>
            <p style="font-size: 13px" class="pt-2">
              <a
                style="font-size: 13px; color: black; font-style: italic"
                :href="$window.location.origin + '/#/page/unitedAirlinesWeek1Tc'"
                target="_blank"
                >Terms and Conditions apply.
              </a>
            </p>
          </div>
          <br />

          <!-- Discover North America Training Module -->
          <div style="width: 1266px !important; padding-bottom: 1rem">
            <v-row class="d-flex">
              <duo-training-tile 
                          style="margin-left: 4px; max-width: 872px;"
                          @onClickEdit="showEditDialog(item)" :map="item.map"
                          @onClickHide="showDialogConfirmation('unpublish', item.id)"
                          v-for="item in tileList" :img="item.image" :title="item.title" :content="item.content"
                          :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :link="item.link"
                          :status="getModuleStatus(item.link)" @onClickButton="onStartTraining(item.link, item.title)" />
              
              <div class="pa-2 mt-2" style="width: 414px; height: 376px; background-color: #221651;">
                <div class="white--text d-flex flex-column justify-center px-4" style="height: 100%;">
                  <p>Week One Incentive</p>
                  <h2>
                    <b>Complete or retake our Discover North America Module for a chance to win 1 of 5 $100 Prezzy cards!</b>
                  </h2>
                  <p>If you have already completed this module, we have made some changes so we encourage you to have another go and be in to win.</p>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </v-row>
      <br /> <br /> 

      <!-- Los Angeles Tourism -->
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-pages/UA - Spotlight LA.jpg'"
          />
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2 style="height: 15px;">
              <b>Los Angeles Tourism</b>
          </h2>
          <p style="font-size: 20px;">
            Re-LAX at LA's Beach Cities
          </p>
          
          
          <v-row>
            <v-col
              v-for="tile in 3"
              :key="tile.title"
              cols="4"
              md="4"
              sm="12"
            >
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - LA Tourism Image '+ (tile)+'.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <p class="pt-5">
            <b>Escape to the iconic California coast with a convenient stopover in LA's vibrant beach cities! Explore a word of sun-kissed beaches, world-class surfing, eclectic street art, and charming shops. From the bustling boardwalk of Venice Beach to the laid-back charm of Manhattan Beach, each beach community offers a unique experience.</b>
          </p>
          <p>
            Venice Beach, with its iconic Muscle Beach and quirky street performers, is a must-visit for those seeking a vibrant atmosphere. Neighbouring Santa Monica boasts the world-famous Santa Monica Pier with its Pacific Park amusement centre and solar-powered Ferris wheel. For a dose of surf culture and beach volleyball, head to Manhattan Beach.
          </p>
          <p>
            Getting to and around LA's beach cities is a breeze. Many beach communities are connected by the scenic Marvin Braude Bike Trail, perfect for exploring on two wheels.          
          </p>
          <p>
            Additionally, Los Angeles International Airport (LAX) is undergoing a multi-billion-dollar modernisation project, including the highly anticipated Automated People Mover (APM) system, which is scheduled to open in January 2026. This system will provide travellers with a convenient and efficient way to navigate the airport. With heaps of beach hotels just 20 minutes away from LAX, it's easy to combine your airport layover with a relaxing beach getaway.
          </p>
          <p>
            <b>For a detailed guide on exploring LA's beach cities, read <a href="https://www.discoverlosangeles.com/discover-la-your-way-los-angeles%E2%80%99-beach-cities" target="_blank" style="color: black;"><u>here.</u></a></b>
          </p>
        </div>
      </v-row>
      <!-- Expedia TAAP -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;" class="pl-12">
            <v-row>
              <v-col cols="3">
                <v-card tile elevation="0">
                  <v-img
                    :src="$bucket + '/image/dashboard-pages/UA - Expedia TAAP.png'"
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="9">
                <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                  <p style="height: 30px;"> 
                    <b>No one has the USA covered like Expedia TAAP. That's why the great majority of Kiwi Agents look to Expedia TAAP for the USA.</b>
                  </p>
                  <p style="height: 10px;">
                    From Oahu through to Boston and everywhere in between Expedia TAAP has it all at your fingertips!
                  </p>
                  <p>
                    Learn more <a href="https://www.expediataap.co.nz/" target="_blank" style="color: black;"><u>here!</u></a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-row>
      <br /><br /><br />
      <!-- Discover Open Road -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <v-row>
            <v-col cols="8">
              <h2>
                Discover the Open Road with Brand USA's Road Trips USA Collection!
              </h2>
              <p class="pt-2">
                <b>Get ready to inspire your clients to explore the diverse landscapes of the USA with Brand USA's exciting Road Trips USA collection. Featuring a wealth of trip inspiration and essential travel information the Brand USA self-drive itineraries will empower your clients to confidently hit the highways and byways.</b>
              </p>
            </v-col>
            <v-col cols="4">
              <v-img
                width="200px"
                :src="$bucket + '/image/dashboard-pages/UA - VisitTheUSA.png'"
                class="ml-6"
              ></v-img>
            </v-col>
          </v-row>
          <br />
          <!-- The collection includes 38 -->
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 1.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                <p style="height: 60px;"> 
                  The collection includes 38 self-drive itineraries available as downloadable PDFs, perfect for sharing with your clients. Each road trip begins and ends in a gateway city, tailored specifically for international travellers.
                </p>
                <p>  
                  To make planning even easier, driving times and distances are provided in kilometres-ideal for New Zealand customers. Plus, each itinerary is fully customisable, allowing clients to select their start and end points and choose routes based on their preferred trip length.<br />From cultural-themed road trips to multi-state journeys, historic trails and scenic wanders, these 38 inspired itineraries will set your clients on a path to discover the USA.
                </p>
              </div>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Nature lovers -->
          <v-row>
            <v-col cols="7">
              <div class="black--text d-flex flex-column justify-center" style="height: 100%;">
                <p> 
                  <b>Nature lovers</b> will be on a natural high on the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Rocky%20Mountains-Great%20Plains.pdf" target="_blank" style="color: black;"><u>Rocky Mountains & the Great Plains</u></a>, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/ENGLISH_Mountains-Glaciers-Parks_Global.pdf" target="_blank" style="color: black;"><u>Alaska - Mountains, Glaciers & Parks</u></a> 
                      and 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_CoastsAndMountains.pdf" target="_blank" style="color: black;"><u>Coasts & Mountains of the South itineraries.</u></a>
                  <br /><br />
                  For otherworldly landscapes, encourage them to hit the road from Las Vegas, Nevada on the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Canyons-Mesas-Hoodoos.pdf" target="_blank" style="color: black;"><u>Canyons, Mesas & Hoodoos</u></a>, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/RoadTripsUSA_VolcanicPeaksSparklingShores_ENG.pdf" target="_blank" style="color: black;"><u>Volcanic Peaks to Sparkling Shores</u></a>
                    in Oregon and Washington and 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Geological%20Wonders.pdf" target="_blank" style="color: black;"><u>Geological Wonders</u></a>
                    in Utah, Wyoming and Idaho.
                  <br /><br />
                  Steer forest bathers to the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_National-parks-American%20India%20culture.pdf" target="_blank" style="color: black;"><u>National Parks & American Indian Cultures</u></a> 
                    roundtrip from Phoenix, Arizona or the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_GiantTreesAncientForests.pdf" target="_blank" style="color: black;"><u>Giant Trees & Ancient Forests</u></a> 
                    loop from San Francisco, California.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 2.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Music Loving Clients -->
          <v-row>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 3.jpg'"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="8">
              <div class="black--text d-flex flex-column justify-center px-4" style="height: 100%;">
                <h2>
                  Music-loving clients?
                </h2>
                <p> 
                  Inspire a deep dive into America's songbook with the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Bourbon-Bluegrass%20and%20Bloodstock.pdf" target="_blank" style="color: black;"><u>Bourbon, Bluegrass & Horse Country</u></a> 
                    roundtrip from Nashville, Tennessee. There's also the eight-state 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Musical%20Roots%20of%20the%20South.pdf" target="_blank" style="color: black;"><u>Musical Roots of the South</u></a> 
                    odyssey from Dallas, Texas to Charleston, South Carolina.
                  <br /> <br />
                  Road-trippers can also follow the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Rhythms%20of%20the%20River.pdf" target="_blank" style="color: black;"><u>Rhythms of the River</u></a>, 
                    tracing the Mississippi from Chicago, Illinois down to New Orleans, Louisiana through eight states. Or embark on a 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_OnTheShoresOfTheGreatLakes.pdf" target="_blank" style="color: black;"><u>Great Lakes loop</u></a> 
                    through Wisconsin, Minnesota and Michigan from Chicago.
                </p>
              </div>
            </v-col>
          </v-row>
          <br /><br />
          <!-- Stars in their eyes -->
          <v-row>
            <v-col cols="7">
              <div class="black--text d-flex flex-column justify-center" style="height: 100%;">
                <h2>
                  Stars in their eyes?
                </h2>
                <p> 
                  They'll be awestruck by silver screen destinations on the fly-and-drive 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_At%20the%20Movies.pdf" target="_blank" style="color: black;"><u>At The Movies</u></a> 
                    cross-country trip from NYC to LA. And ogle the bright lights and big-city sights of 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_IconicCitiesBeyond.pdf" target="_blank" style="color: black;"><u>Iconic Cities & Beyond</u></a> 
                    from Boston, Massachusetts to Washington, D.C.
                  <br /><br />
                  For a slice of small-town life, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Quaint%20New%20England%20Towns.pdf" target="_blank" style="color: black;"><u>Quaint New England Towns</u></a> 
                    through Massachusetts, New Hampshire, Maine, Vermont, Connecticut and Rhode Island serves up the scenery and charm we love from TV and movies.
                  <br /><br />
                  Classic road trippers and foodies can tick off the 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_Route66-TexasHill_Country.pdf" target="_blank" style="color: black;"><u>Route 66 & Texas Hill Country</u></a> or 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-08/RoadTripsUSA_HistoricRoute66_ENG%20%283%29_0.pdf" target="_blank" style="color: black;"><u>Historic Route 66 - Curios, Pit Stops & Diners</u></a> 
                    itineraries from their bucket list. Meanwhile, 
                    <a href="https://traveltrade.visittheusa.com.au/sites/default/files/itineraries/2023-04/RoadTripsUSA_TheCivilRights-HistoryTrail.pdf" target="_blank" style="color: black;"><u>The Civil Rights History Trail</u></a> 
                    puts place-making landmarks of the South into context.
                </p>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="4">
              <v-card tile elevation="0">
                <v-img
                  :src="$bucket + '/image/dashboard-pages/UA - Open Road 4.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <br /> <br />
          <!-- More Road trips -->
          <p>
            <b>More road trips inspiration can be found <a href="https://traveltrade.visittheusa.com.au/USAItineraries" target="_blank" style="color: black;"><u>here</u></a> </b>
          </p>
          <p>
            Brand USA's official Visit the USA travel trade site is equipped with everything you need to sell travel to the USA with confidence.
          </p>
          <p>
            <b>
              You'll find Road Trip USA itineraries, tour operators and destination contacts along with general US travel info including visa and entry policies and getting around: <a href="https://traveltrade.visittheusa.com" target="_blank" style="color: black;"><u>traveltrade.visittheusa.com</u></a>
            </b>
          </p>
        </div>
      </v-row>
      <br /><br /><br />
      <!-- Become a USA expert -->
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="background-color: #ffffff; margin-bottom:1.5vw; padding:1.5vw;" class="px-12">
            <h2 class="pt-9">
              Become a USA expert AND win with Brand USA and Air New Zealand USA Discovery Program!
            </h2>
            <p class="pt-3">
              From 29 October to 20 November, every badge completed on Brand USA's Discovery Program will earn an entry into a draw to win incredible prizes or to attend the Air New Zealand VIP training day.
            </p>
            <p>
              There are over 60-plus badges to earn across all regions and states. Once you have completed the Brand USA badge training, you can download and add badge icons to any client communications, demonstrating their USA expertise.
            </p>
            <v-row>
              <v-col
                v-for="tile in 4"
                :key="tile.title"
                cols="3"
                md="3"
                sm="3"
              >
                <v-card tile elevation="0">
                  <v-img
                    :src="$bucket + '/image/dashboard-pages/UA - Badge '+ (tile)+'.png'"
                    width="200px"
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <p>
              The Brand USA training platform lets you enhance your USA knowledge in four key ways: earn badges, access valuable resources, share inspiring content with clients, and utilise specialised tools to boost your sales.
            </p>
            <p>
              <b>
                Don't miss out - access the USA Discovery Program <a href="https://usadiscoveryprogram.co.nz/main" target="_blank" style="color: black;"><u>here</u></a>
              </b>
            </p>
            <br />
          </div>
        </div>
      </v-row>

    </v-container>

    <v-dialog v-model="dialogRetake" max-width="400">
      <v-card class="mx-auto" max-width="400">
        <v-toolbar flat color="#221551" dark>
          <v-toolbar-title>{{moduleStatus == 'Resume' ? 'Resume' : 'Finish' }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <h2 class="text-h6 my-4">{{ moduleStatus == 'Resume' ? 'You haven\'t finished this training yet' : 'You have finished this training' }}</h2>
          <h2 class="text-h6 my-4" v-if="moduleStatus != 'Resume'">Your Score: {{ finalScore }}</h2>
        </v-card-text>

        <v-card-actions class="justify-center pb-6">
          <v-btn @click="handleRetakeModule()" class="duo-primary">Retake</v-btn>
          <v-btn @click="handleReviewModule()" class="duo-secondary">{{moduleStatus == 'Resume' ? 'Resume' : 'Review'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Training Module is disabled -->
    <v-dialog v-model="dialogModuleDisable" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">The training module is disabled</h2>
          </v-card-text>

          <v-card-actions class="justify-center pb-6">
            <v-btn @click="closeDialogModule" class="duo-primary">Cancel</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";
import duoTrainingTile from './creator/duoTrainingTile.vue';
import axios from "axios";

export default {
  components: {
    subHeader,
    duoTrainingTile
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getList();
    if (this.$store.state.principal.administrator == false) {
      this.listCompletedModule();
    }
  },
  data() {
    return {
      retakeModule: false,
      dialogRetake: false,
      moduleId: 0,
      moduleStatus: "",
      finalScore: 0,
      showScore: false,
      dialogModuleDisable: false,
      tileList: [],
      completedModuleList: [],
      choosenTitle: "",
    };
  },
  methods: {
    getList() {
      axios
          .get("/system/tile/training")
          .then((response) => {
              this.tileList = response.data.filter(function (el) {
                  return el.sequence;
              })
              this.tileList = response.data.filter(function (el) {
                  if (window.location.origin.includes("duo.airnzagent.co.nz")) {
                    return el.link === "25181"; //Discover North America training module linkid on production
                  } else {
                    return el.link === "148008";
                  }
              })
          })
          .catch(() => { });
    },
    listCompletedModule() {
      axios
        .get("/system/score/completed")
        .then((response) => {
          this.completedModuleList = response.data;
        }).catch(() => { });
    },
    onStartTraining(id, title) {
      this.choosenTitle = title;
      this.moduleId = id;
      // axios.get('/system/trainingModule/list').then((response) => {
      axios.get('/system/trainingModule/list/all').then((response) => {
        const res = response.data.find((v) => Number(v.id) === Number(id))
        if (res.active) {
          this.checkPersonScore(this.choosenTitle);
        } else {
          this.dialogModuleDisable = true;
        }
      });
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.moduleStatus = this.getModuleStatus(response.data.moduleId);
            this.dialogRetake = true;
            this.moduleId && this.getModulePageQuiz(this.moduleId);
          } else {
            this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
          }
        })
        .catch(() => { });
    },
    getModuleStatus(moduleId) {
      let result = this.completedModuleList.find(score => Number(score.moduleId) === Number(moduleId));
      if (result != null) {
        return result.transitMap.scoreStatus;
      } else {
        return null;
      }
    },
    getModulePageQuiz(id) {
      if (id !== undefined) axios.get(`/system/page/module/list?moduleId=${id}`).then((response) => {
        this.showScore = response.data.some((v) => v.name.includes('choice') || v.name.includes('answer'))
      });
    },
    closeDialogModule() {
      this.dialogModuleDisable = false;
    }
  },
};
</script>
