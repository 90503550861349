<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px" style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-pages/TravelManagers_Incentive.jpg'" />
        </v-col>
        <v-col cols="12" class="d-inline-flex py-0 px-4 justify-space-between align-end">
          <div>
            <h2>Be in to WIN to elevate your Travel Managers Conference experience!</h2>
          </div>
          <div>
            <v-img height="64px" width="145px" :src="$bucket + '/image/dashboard-pages/TravelManagers_Logo.png'" />
          </div>
        </v-col>
      </v-row>
      <v-row style="padding: 0px 100px 10px 20px">

        <v-col cols="12" class="pa-0">

          <p>
            What better way to start your conference experience than with your flights to Christchurch covered or an Air
            New Zealand Gold Airpoints&trade; membership? What about wheeling a luxury July suitcase around or maybe
            relaxing at
            an Air New Zealand lounge before take-off?
          </p>
          <p><b>Well, you're in luck because we are giving you the chance to get in the draw to WIN from our prize pool.
            </b></p>

          <v-sheet class="pa-6 duo-sheet" style="margin-left: -12px;">
            <div class="ml-4">
              <p>
                <b>The more you engage, the more chances to WIN - it's that easy.</b>
                <br />You get one entry into the draw for doing each of the following: <br /><br />
              <ol>
                <li>Logging a valid sale in your duo <a @click="$router.push('sales')">mysales</a> table. </li>
                <li>Completing a training module in <a @click="$router.push('training')">duotraining</a>. </li>
                <li>Downloading the Air New Zealand <a @click="$router.push('TradeToolkit')">Trade Toolkit</a>. </li>
                <li>Attending a cluster or virtual training session during the incentive period.
                </li>
              </ol>
              </p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>So, what's up for grabs. The main prize pool consists of: </p>
          <ul><b>
              <li>5x return Economy flights from Australia to Christchurch </li>
              <li>5x Air New Zealand Gold Airpoints&trade; membership </li>
              <li>5x July luggage vouchers </li>
            </b>
          </ul>
          <p>PLUS, weekly spot prizes drawn at random of: </p>
          <ul>
            <b>
              <li>20x Air New Zealand Lounge vouchers </li>
              <li>12x $100 Prezzee eGift cards</li>
            </b>
          </ul>

          <p><b> The incentive ends on 30 June 2023 - so don't delay! </b></p>
          <p><a href="https://p-airnz.com/cms/assets/TravelManagers-Conference-Incentive-Terms-and-Conditions.pdf">Terms
              and conditions</a> apply.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>