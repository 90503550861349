<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="headers" :items="data" :search="keyword"
			:footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="5">
						<div class="d-flex px-2 pt-4">
							<h2>Sales Info</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="7" class="justify-end">
						<v-form ref="form2">
							<v-row class="d-flex ">
								<v-col class="date-block ma-2">
									<v-menu ref="dateSalesStartPicker" v-model="dateSalesStartMenu" :close-on-content-click="false"
									:return-value.sync="dateSalesStart" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field id="dateSalesStart" :v-model="dateSalesStart"
										:value="$root.parseDate(dateSalesStart)" readonly v-on="on" v-bind="attrs"
										:rules="[(v) => !!v || 'Enter start date']" @click:prepend="dateSalesStartMenu = true">
										<template v-slot:append>
											<img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
										</template>
										<template v-slot:label>
											<span>Start date</span>
										</template>
										</v-text-field>
									</template>
									<v-date-picker v-model="dateSalesStart" no-title scrollable style="width: 100%">
										<v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
										<v-btn width="120" class="mx-0" outlined color="primary"
											@click="$refs.dateSalesStartPicker.save(dateSalesStart)">Confirm</v-btn>
										<v-btn width="120" class="mx-0" outlined @click="dateSalesStartMenu = false">Cancel</v-btn>
										</v-row>
									</v-date-picker>
									</v-menu>
								</v-col>
								<v-col class="date-block ma-2">
									<v-menu ref="dateSalesEndPicker" v-model="dateSalesEndMenu" :close-on-content-click="false"
									:return-value.sync="dateSalesEnd" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field id="dateSalesStart" :v-model="dateSalesEnd"
										:value="$root.parseDate(dateSalesEnd)" readonly v-on="on" v-bind="attrs"
										:rules="[(v) => !!v || 'Enter end date']" @click:prepend="dateSalesEndMenu = true">
										<template v-slot:append>
											<img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
										</template>
										<template v-slot:label>
											<span>End date</span>
										</template>
										</v-text-field>
									</template>
									<v-date-picker v-model="dateSalesEnd" no-title scrollable style="width: 100%">
										<v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
										<v-btn width="120" class="mx-0" outlined color="primary"
											@click="$refs.dateSalesEndPicker.save(dateSalesEnd)">Confirm</v-btn>
										<v-btn width="120" class="mx-0" outlined @click="dateSalesEndMenu = false">Cancel</v-btn>
										</v-row>
									</v-date-picker>
									</v-menu>
								</v-col>
								<v-col cols="4" class="mt-3" style="margin-right:15px;">
									<v-btn right block medium class="mt-2 pl-2" color="primary" @click="downloadSalesReport">
										<v-icon left>mdi-export</v-icon>  Export Sales Info</v-btn>
								</v-col>
							</v-row>
						</v-form>
						<v-row no-gutters class="d-flex">
							<v-col>
								<div class="d-flex px-2">
									<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details
										style="margin-right:10px;" />
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.name="{ item }">{{ item.firstName + " " + item.lastName }}</template>
			<template v-slot:item.creationDate="{ item }">{{ item.creationDate.date.day + "-" +
				month_names_short[item.creationDate.date.month - 1] + "-" + new Date("" + item.creationDate.date.year +
					"").getFullYear().toString().substr(2, 2) }}</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex flex-row">
					<v-icon v-if="item.askLog.length > 2" @click="showDialog(item)">mdi-pencil</v-icon>
				</div>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>

		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ entity.id != null ? 'Edit' : 'Add' }} Sales Info</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" style="padding: 0 40px" v-model="valid" autocomplete="off">
						<v-text-field v-model="entity.bookingCode" label="PNR" disabled />
						<v-menu ref="datePickerCreation" v-model="datePickerCreation" :close-on-content-click="false"
							:return-value.sync="entity.transitMap.creationDate" transition="scale-transition" offset-y
							max-width="320px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field disabled id="creationDate" :v-model="entity.transitMap.creationDate"
									:value="$root.parseDate(entity.transitMap.creationDate)" readonly v-on="on"
									v-bind="attrs" :rules="[(v) => !!v || 'Enter Creation Date']"
									@click:prepend="datePickerCreation = true">
									<!-- <template v-slot:append>
										<img v-on="on" v-bind="attrs" width="24" height="24"
											src="/icon/icon-calendar.svg" />
									</template> -->
									<template v-slot:label>
										<span style="font-size: 17px; color: black !important">Creation Date</span>
									</template>
								</v-text-field>
							</template>
							<v-date-picker v-model="entity.transitMap.creationDate" no-title scrollable style="width: 100%">
								<v-row no-gutters justify="space-around" class="pa-4">
									<v-btn width="120" class="mx-0" outlined color="primary"
										@click="$refs.datePickerCreation.save(entity.transitMap.creationDate)">Confirm</v-btn>
									<v-btn width="120" class="mx-0" outlined
										@click="datePickerCreation = false">Cancel</v-btn>
								</v-row>
							</v-date-picker>
						</v-menu>
						<v-autocomplete :disabled="entity.status == 'Matched_PNR' && entity.status == oldStatus"
							v-model="entity.status" :items="['Pending', 'Matched_PNR', 'Error']"
							:rules="[(v) => !!v || 'This field is required']" label="Status"
							append-icon="mdi-chevron-down" />
						<v-text-field disabled v-model="entity.itineraryOriginAirportCode" label="Departure"/>
						<v-text-field disabled v-model="entity.itineraryDestinationAirportCode" label="Arrival"/>
						<v-text-field disabled v-model="entity.itineraryDestinationCountry" label="Destination Country"/>

						<v-text-field disabled v-model="entity.totalPassengerCount" label="Total Passengers"/>

						<v-text-field disabled v-model="entity.economySectors" label="Econ."/>

						<v-text-field disabled v-model="entity.premiumEconomySectors" label="Prem."/>

						<v-text-field disabled v-model="entity.businessPremierSectors" label="Bus."/>
					</v-form>

				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="closeDialog">Cancel</v-btn>
					<v-btn @click="confirmSave" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<confirmation-dialog :title="'Confirm validate'" :show="confirmationDialog"
			:content="'Once PNR Validated, it can\'t be invalidated again <br/> Are you sure?'"
			@onCloseDialog="confirmationDialog = false" @onClickConfirm="save" />
	</v-main>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
import confirmationDialog from "./dialog/confirmationDialog.vue";

export default {
	components: {
		confirmationDialog,
	},
	data() {
		return {
			headers: [
				{ text: "PNR", value: "bookingCode" },
				{ text: "Creation Date", value: "creationDate", width: 120 },
				{ text: "Status", value: "status" },
				{ text: "Departure", value: "itineraryOriginAirportCode" },
				{ text: "Arrival", value: "itineraryDestinationAirportCode", sortable: false },
				{ text: "Destination Country", value: "itineraryDestinationCountry" },
				{ text: "Total Passengers", value: "totalPassengerCount", align: "center" },
				{ text: "Econ.", value: "economySectors", align: "center" },
				{ text: "Prem.", value: "premiumEconomySectors", align: "center" },
				{ text: "Bus.", value: "businessPremierSectors", align: "center" },
				{ text: "Created By", value: "name" },
				{ text: "Email", value: "email" },
				{ text: "Account", value: "agencyName" },
				{ text: "Parent Account", value: "agencyGroupName" },
				{ text: "State", value: "agencyState" },
				{ text: "", value: "action" },
			],
			month_names_short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			keyword: "",
			dialog: false,
			datePickerCreation: false,
			entity: { transitMap: {} },
			data: [],
			confirmationDialog: false,
			oldStatus: '',
			dateSalesStartMenu: false,
			dateSalesStart: null,
			dateSalesEndMenu: false,
			dateSalesEnd: null,
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		showDialog(item) {
			this.oldStatus = item.status;
			this.entity = item;
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;
			this.entity = { transitMap: {} };
			this.oldStatus = '';
		},
		confirmSave() {
			if(this.$refs.form.validate()) {
				if (this.entity.status == 'Matched_PNR' && this.entity.status != this.oldStatus) {
					this.confirmationDialog = true;
				} else {
					this.save();
				}
			}
		},
		save() {
			axios.post("/system/sales/edit", { entity: this.entity }).then(() => {
				this.confirmationDialog = false;
				this.closeDialog();
				this.getList();
			})
		},
		getList() {
			axios.get("/system/sales/booking/list/info").then(response => {
				this.data = response.data;
			})
		},
		downloadSalesReport() {
			if (this.$refs.form2.validate()) {
				window.open(axios.defaults.baseURL + "/system/sales/info/report/export?dateStart=" + this.dateSalesStart + "&dateEnd=" + this.dateSalesEnd);
			}
		},
	}
}
</script>