<template>
	<v-container class="d-flex fluid align-center pa-0 justify-center" style="margin-top: 30px">
		<v-row class="d-flex flex-wrap">
			<v-col cols="12">
				<v-card flat width="100%" rounded="0">
					<v-tabs v-model="tab" color="white" height="50" background-color="#424242" slider-color="#90CAF9" slider-size="4" dark>
						<v-tab v-for="(tabItem, tabItemIndex) in tabItemList" :key="tabItemIndex" class="px-12" style="background-color: #424242">
							<div class="text-h6 text-capitalize">
								{{ tabItem }}
							</div>
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="tab" v-if="chipList[tab].length > 0">
						<v-tab-item v-for="(tabItem, tabItemIndex) in tabItemList" :key="tabItemIndex" class="my-3 mx-12">
							<v-row class="d-flex justify-left">
								<v-chip-group v-model="chip" mandatory column class="pt-2">
									<v-chip v-for="(chipItem, chipItemIndex) in chipList[tab]" :key="chipItemIndex" class="mx-3 mt-2 px-5 py-4" color="primary" outlined pill style="cursor: pointer">{{ chipItem }}</v-chip>
								</v-chip-group>
							</v-row>
							<v-row class="pt-4">
								<v-col cols="12">
									<v-card rounded="0" flat>
										<v-card-title class="py-2" style="background: #14c5f4; color: white; height: 45px">
											<div class="text-h6 text-capitalize mb-2" style="first-letter: text-transform:capitalize;">{{ fieldName }}</div>
										</v-card-title>
										<v-card-text class="ma-0 pa-0">
											<div v-for="fieldName in fields" :key="fieldName">
												<template v-if="isActiveField(fieldName)">
													<!-- Text Editor -->
													<template v-if="isVideoContent(fieldName)" class="container mt-0">
														<v-container style="border: 1px solid #cccccc; border-radius: 0">
															<v-row>
																<v-col cols="12">
																	<v-text-field
																		:rules="[(v) => !v || $root.isURL(v) || 'Invalid link, link should start with http:// or https://']"
																		v-model="page.valueMap.bodyVideo"
																		label="Video link"
																		hint="It’s recommended to use youtube or vimeo link"
																		prepend-icon="mdi-video"
																	/>
																</v-col>
															</v-row>
														</v-container>
													</template>
													<v-text-editor v-else-if="isTextEditor(fieldName)" v-model="page.valueMap[selectedModel(fieldName)]" :api-key="$tinyMceKey" :init="selectedInitEditor" />
													<template v-else-if="isColorEditor(fieldName)">
														<!-- Color Editor -->
														<v-color-picker
															v-if="iconType == 'Default'"
															v-model="page.valueMap[selectedModel(fieldName)]"
															mode="hexa"
															width="100%"
															hide-canvas
															hide-mode-switch
															style="border: 1px solid #cccccc; border-radius: 0"
														></v-color-picker>
														<v-container v-else style="border: 1px solid #cccccc; border-radius: 0">
															<div>Choose Default Icon to edit color.</div>
														</v-container>
													</template>
													<!-- True/False Editor -->
													<v-checkbox
														v-else-if="isTrueFalseEditor(fieldName)"
														v-model="page.valueMap[selectedModel(fieldName)]"
														:label="labelCheckbox"
														color="success"
														hide-details
														class="container mt-0"
														style="border: 1px solid #cccccc; border-radius: 0"
													></v-checkbox>
													<!-- Question Editor -->
													<v-question
														v-else-if="isQuestionEditor(fieldName)"
														v-model="page.valueMap[selectedModel(fieldName)]"
														:type="page.type"
														class="container mt-0"
														style="border: 1px solid #cccccc; border-radius: 0"
													></v-question>
													<!-- Icon Uploader -->
													<template v-else-if="isIconUploader(fieldName)" class="container mt-0">
														<v-container style="border: 1px solid #cccccc; border-radius: 0">
															<v-row>
																<v-col cols="12" sm="3">
																	<v-select v-model="iconType" :items="['Upload', 'Default']" label="Icon Type" @change="iconType == 'Default' ? deleteIconUpload() : ''"></v-select>
																</v-col>
																<v-col cols="12" sm="9" v-if="iconType === 'Upload'">
																	<input ref="attachment1" type="file" accept="image/png, image/jpeg, image/jpg, image/svg+xml" @change="onIconUpload" style="display: none" />
																	<v-text-field v-model="iconName" label="Icon" :hint="iconUploaderHint" prepend-icon="mdi-image" @click="openIconUpload()" readonly :rules="[(v) => !!v || 'Icon is required']">
																		<template v-slot:append>
																			<template v-if="!$root.isBlank(iconName)">
																				<v-icon color="red" size="20" class="pt-1" @click="deleteIconUpload()">icon-duo-trash</v-icon>
																			</template>
																		</template>
																	</v-text-field>
																</v-col>
															</v-row>
														</v-container>
													</template>
													<!-- Image Uploader -->
													
													<template v-else-if="isImageUploader(fieldName)" class="container mt-0">
														<v-container style="border: 1px solid #cccccc; border-radius: 0">
															<v-row>
																<v-col cols="12" sm="3">
																	<v-select v-model="imageType" :items="['Upload', 'Default']" label="Image Type" @change="imageType == 'Default' ? deleteImageUpload() : ''"></v-select>
																</v-col>
																<v-col cols="12" sm="9" v-if="imageType === 'Upload'">
																	<input ref="attachment2" type="file" accept="image/png, image/jpeg, image/jpg, .svg" @change="onImageUpload" style="display: none" />
																	<v-text-field
																		v-model="imageName"
																		label="Image"
																		hint="It’s recommended to use youtube or vimeo links"
																		prepend-icon="mdi-image"
																		@click="openImageUpload()"
																		readonly
																		:rules="[(v) => !!v || 'Image is required']"
																	>
																		<template v-slot:append>
																			<template v-if="!$root.isBlank(imageName)">
																				<v-icon color="red" size="20" class="pt-1" @click="deleteImageUpload()">icon-duo-trash</v-icon>
																			</template>
																		</template>
																	</v-text-field>
																</v-col>
															</v-row>
														</v-container>
													</template>
													<!-- Button Config --> 
													<template v-else-if="isButtonConfig(fieldName)" class="container mt-0">
														<v-container style="border: 1px solid #CCCCCC; border-radius: 0;">
															<v-row>
																<v-col cols="12" sm="3">
																	<v-select v-model="page.valueMap[selectedModel(fieldName)]" :items="buttonType"></v-select>
																</v-col>
															</v-row>
														</v-container>
													</template> 
												</template>
											</div>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<br />
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import questionEditor from "./trainingModuleQuestion.vue";

export default {
	props: {
		pageId: {
			required: true
		},
		page: {
			type: Object,
			required: true,
			valueMap: {
				type: Object,
				required: true
			}
		},
		fields: {
			type: Array,
			required: true,
			default: function () {
				return ["header-title", "body-summary"];
			}
		},
		attachments: {
			type: Object,
			required: false,
			attachment1: {
				type: Object,
				required: false
			},
			attachment2: {
				type: Object,
				required: false
			}
		}
	},
	components: {
		"v-text-editor": Editor,
		"v-question": questionEditor
	},
	mounted() {
		this.findPage();
		this.defaultValue();
	},
	computed: {
		selectedInitEditor() {
			// field body-summary with tinymce -> height = 400px, else 160px
			if (String(this.chipList[this.tab][this.chip]).includes("summary")) {
				return this.$root.copyWith({ height: 400 }, this.initEditor);
			} else {
				return this.$root.copyWith({ height: 160 }, this.initEditor);
			}
		},
		fieldName() {
			// active field name
			if (this.chipList.length != 0) {
				return String(this.chipList[this.tab][this.chip])
					.replace(/header|body|footer/gi, "")
					.replace(/-/gi, " ");
			} else {
				return "";
			}
		},
		labelCheckbox() {
			// active checkbox name
			if (String(this.chipList[this.tab][this.chip]).includes("has-back-button")) {
				return String(this.page.valueMap.footerHasBackButton);
			} else {
				return "";
			}
		}
	},
	watch: {
		tab(newValue, oldValue) {
			if (oldValue !== newValue) {
				// reset chip if the tab has changed
				this.chip = 0;
			}
		},
		fields(newValue, oldValue) {
			// wait field props loaded, then convert fields props to chips
			if (String(oldValue) != String(newValue)) {
				this.convertFieldsPropsToChipList();
			}
		},
		"page.valueMap.headerIcon": function (newValue, oldValue) {
			if (String(oldValue) != String(newValue)) {
				this.getAttachmentTypeAndName();
			}
		},
		"page.valueMap.bodyImage": function (newValue, oldValue) {
			if (String(oldValue) != String(newValue)) {
				this.getAttachmentTypeAndName();
			}
		},
		"page.valueMap.bodyVideo": function (newValue) {
			this.convertBodyVideo(newValue);
		}
	},
	data: function () {
		return {
			tab: 0,
			tabItemList: ["header", "body", "footer"],
			chip: 0,
			chipList: [[], [], []],
			initEditor: {
				height: 200,
				selector: "",
				menubar: true,
				plugins: ["code", "paste", "table", "lists", "link", "textcolor"],
				paste_as_text: true,
				fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px",
				toolbar:
						"code undo redo | formatselect | fontsizeselect | bold italic underline forecolor backcolor | table | \
				alignleft aligncenter alignright alignjustify | \
				bullist numlist outdent indent | link | removeformat | pastetext | help"
			},
			imageType: "Default",
			buttonType: ['Default', 'No Back Button', 'End Page'],
			iconType: "Default",
			imageName: "",
			iconName: "",
			iconUploaderHint: ""
		};
	},
	methods: {
		findPage() {
			axios
				.get("/api/page?id=" + this.pageId)
				.then((response) => {
					let page = response.data;
					if (page.type === "start-2") {
						this.iconUploaderHint = "It’s recommended to use icon with resolution approximately 58x58 pixels and recommended size is under 2MB.";
					} else {
						this.iconUploaderHint = "It’s recommended to use icon with resolution approximately 73x73 pixels and recommended size is under 2MB.";
					}
				})
				.catch(() => {});
		},
		camelize(str) {
			// kebab-case to camel-case
			return str.replace(/-./g, (x) => x.toUpperCase()[1]);
		},
		convertFieldsPropsToChipList() {
			// load chip list (used for v-chip) from fields props
			var header = [];
			var body = [];
			var footer = [];
			for (let i = 0; i < this.fields.length; i++) {
				var fieldName = String(this.fields[i]);
				if (fieldName.includes("header")) {
					header.push(this.fields[i]);
				} else if (fieldName.includes("body")) {
					body.push(this.fields[i]);
				} else if (fieldName.includes("footer")) {
					footer.push(this.fields[i]);
				}
			}
			this.chipList = [header, body, footer];
		},
		getAttachmentTypeAndName() {
			this.iconName = this.page.valueMap[this.selectedModel("header-icon")];
			this.imageName = this.page.valueMap[this.selectedModel("body-image")];
			this.iconType = this.$root.isBlank(this.iconName) ? "Default" : "Upload";
			this.imageType = this.$root.isBlank(this.imageName) ? "Default" : "Upload";
		},
		defaultValue() {
			if(!this.page.valueMap.footerButtonConfig){
              this.page.valueMap.footerButtonConfig = 'Default'
			}
		},
		onIconUpload(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let iconName = files[0].name;
				if (iconName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.attachments.attachment1 = files[0]; // this is an image file that can be sent to server...
				});
				this.iconName = files[0].name;
				this.page.valueMap[this.selectedModel("header-icon")] = files[0].name;
			} else {
				this.attachments.attachment1 = null;
			}
		},
		onImageUpload(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let imageName = files[0].name;
				if (imageName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.attachments.attachment2 = files[0]; // this is an image file that can be sent to server...
				});
				this.imageName = files[0].name;
				this.page.valueMap[this.selectedModel("body-image")] = files[0].name;
			} else {
				this.attachments.attachment2 = null;
			}
		},
		openIconUpload() {
			this.$refs.attachment1[0].click();
		},
		openImageUpload() {
			this.$refs.attachment2[0].click();
		},
		deleteIconUpload() {
			this.attachments.attachment1 = null;
			this.iconName = "";
			this.page.valueMap[this.selectedModel("header-icon")] = "";
			this.imageType = "Default";
		},
		deleteImageUpload() {
			this.attachments.attachment2 = null;
			this.imageName = "";
			this.page.valueMap[this.selectedModel("body-image")] = "";
			this.imageType = "Default";
		},
		isActiveField(fieldName) {
			// check active field right now
			return String(this.chipList[this.tab][this.chip]) === fieldName;
		},
		selectedModel(fieldName) {
			// dynamic modelling for fieldName (kebab case) to db json (camel case)
			return this.camelize(fieldName);
		},
		isTextEditor(fieldName) {
			// check active field will use text editor (v-text-editor tinymce)
			return !this.isButtonConfig(fieldName) && !this.isColorEditor(fieldName) && !this.isTrueFalseEditor(fieldName) && !this.isQuestionEditor(fieldName) && !this.isIconUploader(fieldName) && !this.isImageUploader(fieldName);
		},
		isColorEditor(fieldName) {
			// check active field will use color editor (v-color-editor)
			return String(fieldName).includes("color");
		},
		isTrueFalseEditor(fieldName) {
			// check active field will use True/False editor (v-checkbox)
			return String(fieldName).includes("has-back-button");
		},
		isButtonConfig(fieldName) {
			// check active field will use button config
			return String(fieldName).includes('button-config');
		},
		isQuestionEditor(fieldName) {
			// check active field will use Question editor (v-question)
			return String(fieldName).includes("question");
		},
		isIconUploader(fieldName) {
			// check active field will use icon uploader
			return String(fieldName).includes("header-icon") && !String(fieldName).includes("header-icon-title");
		},
		isImageUploader(fieldName) {
			// check active field will use image uploader
			return String(fieldName).includes("body-image");
		},
		isVideoContent(fieldName) {
			// check active field will use image uploader
			return String(fieldName).includes("body-video");
		},
		convertBodyVideo(url) {
			this.page.valueMap.bodyVideo = this.$root.convertVideoUrl(url)
		} 
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.85) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>