<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row style="padding: 0px 100px 10px 20px">
        <h2>Air New Zealand: Seats to Suit Incentive - Competition Terms and Conditions </h2>
        <v-col cols="12" class="pa-0">
          <p>
              1. Information on how to enter the promotion forms part of these terms and conditions. Entries not made in accordance with these terms and conditions will be disqualified. Acceptance of the prize by the prize winner is deemed acceptance of these terms and conditions. 
          </p>

          <p>2. The promotion commences at 00:01 AEST 28 August 2023 and closes at 23:59 AEST 30 September 2023 (the "Promotional Period"). All times recorded throughout these terms and conditions are in Australian Eastern Standard Time. Entries received outside the Promotional Period will be ineligible for entry. 
          </p>

          <p>3. The promoter is Air New Zealand Limited, Level 12, 7 Macquarie Place, Sydney, NSW, 2000, (ABN 70 000 312 685) (the "Promoter"). a. Entry is only eligible for travel agents registered to duo (duo.airnzagent.com.au). Agents must be 18 years or over, who comply with the entry criteria set out in these terms and conditions ("Entrants"). Entrants must be 18 years or over at the time of departure to be eligible for the major prize draw. </p>
          <p>4. Employees of the Promoter and their immediate families, participating agents and their agencies associated with this Promotion are ineligible to enter. The Promoter reserves the right to verify the validity of each entry. The Promoter reserves the right to disqualify any person for tampering with the entry process. 
</p>
          <p>5. Conditions of Entry: a) To be eligible for entry into the prize draw, entrants must register for duo, watch the Seats to Suit video and complete the Seats to Suit training module to be eligible. 
</p>
          <p>6. Prizes: Prizes are as follows: Total of five (5) prizes consisting of two (2) return Economy theworks tickets from Australia to New Zealand. Each winner is only eligible for one (1) prize, a total of five (5) winners. Valid from the following ports ex Australia: BNE/SYD/MEL/PER/ADL/HBA/CNS/OOL to AKL/WLG/CHC/ZQN on an Air New Zealand direct operated service only. Domestic flights within New Zealand are not included. Where additional charges apply based on the winners preferred travel requirements, these will be payable by the winner. The ticket value is up to AUD$2000 and is not redeemable for cash, non-transferrable or exchangeable. 
</p>
          <p>7. Prizes will be drawn on 03 October 2023. Attempts will be made to notify winners by email from the email address provided on the promotion entry. Subject to any written directions given under Reg. 37 of the Lottery and Gaming Regulations (SA) or other applicable law, if the Promoter, having used reasonable efforts, is unable to contact the winner by 17:00 AEST the following Business Day of the draw, then the prize is forfeited, and further draw(s) will be conducted until the prize is awarded (subject to any further regulatory directions). </p>
          <p>8. The Promoter's decision is final, and no correspondence will be entered into. </p>
          <p>9. The total prize pool is valued at AUD$10,000. This is the total value across five (5) prizes. 
</p>
          <p>10. The prizes cannot be converted into cash. The prizes are not transferable to other persons or organisations. If a prize or receipt of it incurs a tax liability, the relevant prize winner is liable for payment of such tax. 
</p>
          <p>11. Subject to the State legislation, the Promoter reserves the right to substitute the prize in whole (or any of its components), with a substitute prize of equal or greater value and the prize winner will be notified accordingly. 
</p>
          <p>12. All prize travel will be subject to Air New Zealand's conditions of carriage, to view visit airnewzealand.com.au. Airpoints Dollars' cannot be accrued on prize air travel. Prize cannot be used in conjunction with any other special offer. </p>
          <p>13. Neither the Promoter nor any other person or party associated with the Promotion shall be liable for any delayed, lost, or misdirected mail, email, or other communication. Further, to the fullest extent permitted by law, the Promoter excludes liability for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, technical problems or traffic congestion on the internet or any website, theft or destruction or unauthorised access to or alteration of entries. The Promoter assumes no responsibility for any injury or damage to participants or any other person's computer related to or resulting from participation in or downloading any materials in this promotion. The use of any automated entry software or any other mechanical or electronic means that allows an entrant to automatically enter repeatedly is prohibited and will render all entries submitted by that entrant invalid. 
</p>
          <p>14. Neither the Promoter nor any other person or party associated with this promotion shall be liable for any loss or damage whatsoever suffered (including but not limited to direct, indirect, consequential, or economic loss) or personal injury suffered or sustained in connection with either participation in this promotion or with any prizes offered, except for any liability that cannot be excluded. 
</p>
       </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>