<template>
  <v-col v-if="show" cols="12" :md="size == 1 ? 4 : size == 2 ? 8 : 12" class="py-2">
    <v-row no-gutters
      :style="{ minHeight: '369px', backgroundColor: map.contentTheme != undefined && map.contentTheme == 'light' ? 'white' : '#221551', color: 'white' }">
      <v-col cols="12" :md="size > 1 ? 6 : 12">
        <v-card class="pt-2"
          :color="map.contentTheme != undefined && map.contentTheme == 'light' ? 'white' : 'duoQuaternary'" tile
          min-height="376" elevation="0">
          <div class="d-flex align-center pl-6" style="width: 100%; height: 58px; font-size: 28px">
            <div
              :class="'d-flex flex-column ' + (map.contentTheme != undefined && map.contentTheme == 'light' ? '' : 'white--text')">
              <span><b>duo</b>training</span>
              <span class="duo-underline"></span>
            </div>
            <v-spacer />
            <completion-label :status="status" />
          </div>
          <v-card-text class="ma-1">
            <v-row>
              <v-col cols="12" class="pl-3">
                <v-row align="center">
                  <v-col cols="auto" class="pl-3">
                    <v-avatar size="76"
                      :color="map.contentTheme != undefined && map.contentTheme == 'light' ? 'duoQuaternary' : 'white'">
                      <v-icon class="duo-icon-training"
                        :color="map.contentTheme != undefined && map.contentTheme == 'light' ? 'white' : 'duoQuaternary'"
                        size="32"></v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8" class="d-flex">
                    <div style="max-height: 106px; overflow-y: hidden;">
                      <span style="font-size: 28px; line-height: 1.2; max-height: 107px; overflow-y: hidden;"
                        :style="{ color: (map.contentTheme != undefined && map.contentTheme == 'light' ? '#2d2926 !important' : 'white !important') }"
                        v-html="title.replace('<p>', '').replace('</p>', '')" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pl-4 pr-12">
                    <span
                      style="display: block;"
                      :class="'duo-description ' + (map.contentTheme != undefined && map.contentTheme == 'light' ? '' : 'white--text')"
                      v-html="content" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="duo-description pl-3 pr-6">
                <span
                  :style="{ color: map.contentTheme != undefined && map.contentTheme == 'light' ? 'duoPrimary' : 'white' }"></span>
              </v-col>
            </v-row>
          </v-card-text>
          
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" class="pl-3">
                <v-btn class="'duo-text-button elevation-0 '" width="193px" height="50px"
                  :style="{ fontSize: '17px', color: map.contentTheme != undefined && map.contentTheme == 'light' ? 'white' : '#2d2926' }"
                  :color="map.contentTheme != undefined && map.contentTheme == 'light' ? 'duoPrimary' : 'white'"
                  @click="onClickButton(link)" absolute bottom light>{{ btnLabel }}</v-btn>
                <div v-if="isPreview > 1"
                  :style="{ position: 'absolute', left: size != 3 ? '80%' : '0', right: size == 3 ? '80%' : '0', bottom: '30px' }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-2"
                        :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                        @click="onClickEdit()" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>Edit tile</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-2"
                        :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                        @click="onClickHide()" v-bind="attrs" v-on="on">mdi-close-circle-outline</v-icon>
                    </template>
                    <span>Unpublish tile</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :order="size == 2 ? 2 : 1" v-if="size > 1" cols="12" md="6" :style="{
        minHeight: '376px',
        backgroundImage:
          'url(' + img + ')',
        backgroundColor: '#2d2926',
        backgroundSize: 'cover',
        position: 'relative',
        backgroundPosition: '50% 50%',
      }">
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import completionLabel from "../creator/duoTrainingCompletionLabel.vue";

export default {
  components: {
    completionLabel
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 1,
    },
    img: {
      type: String,
      default: "VirtualCards.jpg",
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    btnLabel: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: null,
    },
    map: {
      default: {}
    },
    isPreview: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onClickButton() {
      this.$emit('onClickButton')
    },
    onClickEdit() {
      this.$emit('onClickEdit');
    },
    onClickHide() {
      this.$emit('onClickHide');
    },
  },
};
</script>
