<template>
    <v-dialog persistent max-width="1024px" v-model="show">
        <v-card>
            <v-card-title>
                <span class="headline"> Images Libary </span>
                <v-spacer></v-spacer>
                <v-icon @click="onCloseDialog">mdi-close</v-icon>
            </v-card-title>
            <v-card-text style="max-height: 700px; overflow-y: auto;">
                <v-row>
                    <v-col style="cursor: pointer;" @click="onClickImage(item)" v-for="(item, idx) in list" :key="idx" cols="2">
                        <v-hover v-slot="{ hover }" disabled>
                            <v-img :elevation="hover ? 12 : 2" :src="$bucket + '/' + item" />
                        </v-hover>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onCloseDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        onCloseDialog() {
            this.$emit("onCloseDialog");
        },
        onClickImage(item) {
            this.$emit("onClickImage", item);
        },
    },
}
</script>