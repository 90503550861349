2<template>
	<v-container fluid class="d-flex align-center pa-0 justify-center mt-5">
		<v-row class="d-flex flex-wrap duo-custom-template">
			<v-col cols="12">
				<v-row class="d-flex justify-space-around align-center">
					<v-card-text class="pa-12">
						<div v-html="page.valueMap.bodySummary" class="text-wrap duo-custom-link" style="line-height: 21px; font-family: AirNZ Sans Regular !important; color: #2D2926;"></div>
					</v-card-text> 				
					<v-btn class="ma-2 white--text" style="width: 192px; height: 192px;" fab color="#2D2926" elevation="1" @click="nextPage()">
						<p style="font-family:AirNewzald; font-style: italic; font-size:33px; line-height: 0.8;">
							<strong>Start<br>the<br>Quiz</strong>
						</p>
					</v-btn>     
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable no-console */
export default {
	props: {
		// validate props
		page: {
			type: Object,
			required: true
		},
		isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	data: function () {
		return {};
	},
	methods: {
		previousPage() {
			if (!this.isPreview) {
				this.$emit("previousPage");
			}
		},
		nextPage() {
			if (!this.isPreview) {
				this.$emit("nextPage");
			}
		}
	}
};
</script>

<style scoped>
.transparent-card {
	background-color: rgba(255, 255, 255, 0.85) !important;
}
.text-transform-none.v-btn {
	text-transform: none !important;
}
</style>