<template>
	<v-main>
		<div class="d-flex justify-center align-center" style="background-color:#f8f8f8;background-size:cover;height:100vh;">
			<v-card width="360">
				<v-card-title class="d-flex justify-center align-center" style="border-bottom:1px solid #dedede;">
					<img src="/icon/logo.svg" width="270" />
				</v-card-title>
				<v-card-text style="padding-top:20px;">
					<v-text-field v-model="email" label="Email"></v-text-field>
					<v-text-field v-model="password" label="Password" type="password" @keyup.enter="login"></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="login" color="primary">Login</v-btn>
				</v-card-actions>
			</v-card>
		</div>
	</v-main>
</template>

<script>
export default {
	data: function() {
		return {
			email: null,
			password: null
		};
	},
	methods: {
		login() {
			this.$store
				.dispatch("loginAdministration", {
					email: this.email,
					password: this.password
				})
				.then(() => {
					if(this.$store.state.principal.role == 'bdm'){
						this.$router.push("/administration/agent/info");
					} else {
						this.$router.push("/administration/dashboard");
					}
				}).catch(() => {});
		}
	}
};
</script>