<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-row no-gutters style="padding: 20px 10px 15px">
        <v-col cols="12" sm="8">
          <div class="d-flex px-2">
            <h2>Visit BDM Report</h2>
          </div>
        </v-col>
      </v-row>

      <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
        <v-card-text>
          <v-form ref="form4" no-gutters class="d-flex flex-wrap">
            <v-row>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateVisitBdmIntroductionStartPicker" v-model="dateVisitBdmIntroductionStartMenu" :close-on-content-click="false"
                  :return-value.sync="dateVisitBdmIntroductionStart" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateVisitBdmIntroductionStart" :v-model="dateVisitBdmIntroductionStart" :value="$root.parseDate(dateVisitBdmIntroductionStart)"
                      readonly v-on="on" v-bind="attrs" :rules="[(v) => !!v || 'Enter start date']"
                      @click:prepend="dateVisitBdmIntroductionStartMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">Start Visit BDM Introduction date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateVisitBdmIntroductionStart" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateVisitBdmIntroductionStartPicker.save(dateVisitBdmIntroductionStart)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateVisitBdmIntroductionStartMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateVisitBdmIntroductionEndPicker" v-model="dateVisitBdmIntroductionEndMenu" :close-on-content-click="false"
                  :return-value.sync="dateVisitBdmIntroductionEnd" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateVisitBdmIntroductionStart" :v-model="dateVisitBdmIntroductionEnd" :value="$root.parseDate(dateVisitBdmIntroductionEnd)"
                      readonly v-on="on" v-bind="attrs" :rules="[(v) => !!v || 'Enter end date']"
                      @click:prepend="dateVisitBdmIntroductionEndMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">End Visit BDM Introduction date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateVisitBdmIntroductionEnd" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateVisitBdmIntroductionEndPicker.save(dateVisitBdmIntroductionEnd)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateVisitBdmIntroductionEndMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-autocomplete v-model="visitBdmIntroductionFilter" :items="visitBdmIntroductionOptions" item-text="value" item-value="value"
                  label="Filter" :class="{ 'line-blue': visitBdmIntroductionOptions != null }" class=" rounded-0"
                  append-icon="mdi-chevron-down" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
          <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadVisitBdmIntroductionReport"><v-icon
              left>mdi-export</v-icon> Export Visit BDM Introduction</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-main>
</template>

<style scoped>
.date-block {
  max-width: 230px !important;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dateVisitBdmIntroductionStartMenu: false,
      dateVisitBdmIntroductionStart: null,
      dateVisitBdmIntroductionEndMenu: false,
      dateVisitBdmIntroductionEnd: null,
      visitBdmIntroductionFilter: "",
      visitBdmIntroductionOptions: []
    };
  },
  mounted() {
    this.listAllVisitBdmIntroduction();
  },
  methods: {
    downloadVisitBdmIntroductionReport() {
      if (this.$refs.form4.validate()) {
        window.open(axios.defaults.baseURL + "/system/activitylog/watchvideo/export?dateStart=" + this.dateVisitBdmIntroductionStart + "&dateEnd=" + this.dateVisitBdmIntroductionEnd + "&value=" + (this.visitBdmIntroductionFilter.length > 0 ? this.visitBdmIntroductionFilter.replaceAll(' ', '_') : ''));
      }
    },
    listAllVisitBdmIntroduction() {
      axios
        .get("/system/activitylog/watchvideo/list")
        .then((response) => {
          var result = response.data;
          this.visitBdmIntroductionOptions = result.filter(option => {
            return option.value.includes('BDM Introduction');
          });
        })
        .catch(() => { });
    }
  }
};
</script>
