<template>
	<div style="display: flex; align-items: center">
		<input
			ref="input"
			:type="inputType"
			maxlength="1"
			pattern="[0-9a-zA-Z]"
			v-model="model"
			:class="inputClasses"
			@input="handleOnChange"
			@keydown="handleOnKeyDown"
			@paste="handleOnPaste"
			@focus="handleOnFocus"
			@blur="handleOnBlur"
			style="text-transform: uppercase; border-radius: 5px; margin-right: 15px"
		/>
		<span v-if="!isLastChild && separator">
			<span v-html="separator"></span>
		</span>
	</div>
</template>

<script>
export default {
	name: "singlePnrInput",
	props: {
		value: {
			type: String
		},
		separator: {
			type: String
		},
		focus: {
			type: Boolean
		},
		inputClasses: {
			type: String
		},
		shouldAutoFocus: {
			type: Boolean
		},
		inputType: {
			type: String,
			default() {
				return "tel";
			}
		},
		isLastChild: {
			type: Boolean
		}
	},
	data() {
		return {
			model: this.value || ""
		};
	},
	mounted() {
		if (this.$refs.input && this.focus && this.shouldAutoFocus) {
			this.$refs.input.focus();
		}
	},
	watch: {
		value(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.model = newValue;
			}
		},
		// whenever question changes, this function will run
		focus(newFocusValue, oldFocusValue) {
			// Check if focusedInput changed
			// Prevent calling function if input already in focus
			if (oldFocusValue !== newFocusValue && this.$refs.input && this.focus) {
				this.$refs.input.focus();
				this.$refs.input.select();
			}
		}
	},
	methods: {
		handleOnChange() {
			if (this.model.length > 1) {
				this.model = this.model.slice(0, 1);
			}
			var index = this.inputClasses.split(" ");

			if (this.model.match(/[a-zA-Z0-9]$/g)) {
				return this.$emit("on-change", this.model.toUpperCase(), index.pop());
			} else {
				this.model = "";
			}
		},
		handleOnKeyDown(event) {
			// Only allow characters 0-9, DEL, Backspace and Pasting
			const keyEvent = event || window.event;
			const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode;
			if (this.isCodeNumeric(charCode) || charCode === 8 || charCode === 86 || charCode === 46) {
				var index = this.inputClasses.split(" ");
				this.$emit("on-keydown", event, index.pop());
			} else {
				keyEvent.preventDefault();
			}
		},
		isCodeNumeric(charCode) {
			// custom 1 allow alphabet
			// numeric keys and numpad keys, charCode info -> https://keycode.info
			return (charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 96 && charCode <= 105);
		},
		handleOnPaste(event) {
			var index = this.inputClasses.split(" ");
			return this.$emit("on-paste", event, index.pop());
		},
		handleOnFocus() {
			this.$refs.input.select();
			return this.$emit("on-focus");
		},
		handleOnBlur() {
			return this.$emit("on-blur");
		}
	}
};
</script>

<style >
.pnr-input {
	width: 45px;
	height: 45px;
	/* padding: 5px; */
	margin: 0 5px;
	font-size: 17px;
	border-radius: 4px;
	border: 1px solid #2D2926;
	text-align: center;
}
</style>