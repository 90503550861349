<template>
	<v-main style="margin: 10px">
		<v-data-table :headers="headers" :items="personList" :items-per-page="15" :search="keyword">
			<template v-slot:top>
				<v-row no-gutters style="padding: 20px 10px 15px">
					<v-col cols="12" sm="8">
						<div class="d-flex px-2">
							<h2>Person list - {{agency.code + " - " + agency.name}}</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="4">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details style="margin-right: 10px"></v-text-field>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.name="{ item }">{{ item.firstName + " " + item.lastName }}</template>
		</v-data-table>
	</v-main>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";

export default {
	data: function() {
		return {
			agencyId: 0,
			agency: {},
			personList: [],
			keyword: "",
			headers: [
				{
					text: "Name",
					align: "start",
					sortable: false,
					filterable: true,
					value: "name"
				},
				{ text: "Email", value: "email" },
				{ text: "Contact number", value: "mobileNumber" }
			]
		};
	},
	created() {
		/* eslint-disable no-console */
		this.agencyId = this.$route.params.agencyId;
	},
	mounted: function() {
		this.getAgency();
		this.listPerson();
	},
	methods: {
		getAgency() {
			if(this.agencyId > 0){
				axios
				.get("/system/agency?id=" + this.agencyId)
				.then(response => {
					this.agency = response.data;
				})
				.catch(() => {});
			}
		},
		listPerson() {
			axios
				.get("/system/person/list?agencyId=" + this.agencyId)
				.then(response => {
					this.personList = response.data;
				})
				.catch(() => {});
		}
	}
};
</script>