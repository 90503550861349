<template>
	<v-container fluid>
		<subHeader />
		<v-row no-gutters>
			<v-card class="pl-3" elevation="0" flat color="transparent">
				<v-card-text>
					<p class="text-bold">Agency Sales Support are a team of specialised consultants available to assist you<br />
					by phone or email for any questions that you have about Air New Zealand products, fares and services.</p> <br />
					<p class="duo"><b>Hours:</b> Monday to Friday 9:00am–5:30pm EST (Friday from 9:30am)</p>
					<div class="ma-8">
						<!-- <p class="duo"><v-icon class="mr-4">mdi-email</v-icon> <a class="duo" href="mailto:agencysupport@airnz.co.nz">agencysupport@airnz.co.nz</a></p> -->
						<p class="duo"><img class="mr-6" src="/icon/icon-email-black.svg" style="width: 18px; height: 14px;" /><a class="duo" href="mailto:agencysupport@airnz.co.nz" style="color: #2d2926">agencysupport@airnz.co.nz</a></p>
						<!-- <p class="duo"><v-icon class="mr-4">mdi-phone</v-icon> 1800 737 747</p> -->
						<p class="duo"><img class="mr-6" src="/icon/icon-phone-black.svg" style="width: 15px; height: 15px;" />1800 737 747</p>
					</div>
					<p class="text-bold mb-1">Frequently Asked Questions (FAQs)</p>
					<p class="duo">You may find the answer to your question in our FAQs, so in the first instance, refer to our <a class="duo" target="_blank" href="https://airnzagent.com.au/agency-sales-support" style="color: #2d2926">agent website.</a></p>
				</v-card-text>
			</v-card>
		</v-row>
	</v-container>
</template>

<style scoped>
.v-card__text {
	color: #2d2926 !important;
}
.text-bold {
	font-family: 'AirNZ Sans Semibold';
	font-size: 17px;
	line-height: 21px;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 0%;
		margin-right: 0%;
	}
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
// import axios from "axios";
import subHeader from "./subHeader.vue";

export default {
	components: {
		subHeader
	},

	data() {
		return {};
	},
	mounted: function () {},
	methods: {}
};
</script>
