<template>
  <v-container fluid>
    <subHeaderMobile />
    <v-container fluid style="max-width: 1271px">
      <v-divider />
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img class="mx-auto" max-width="1271px" max-height="376px" :src="$bucket + '/image/toolkit/HeroTile.jpg'" />
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-cols style="max-width: 1130px;" cols="12">
          <p class="anz-reguler"><b>We've created an Air New Zealand Trade Toolkit made specificaly for you - our valued
              business partners. Manaaki (care) exemplifies our culture
              <br />and commitment to our partners and customers, and we appreciate your ongoing support, inspiring
              travellers to book their next getaway accross the Tasman.
            </b></p>
          <p>
            So, we've created this handy toolkit full of content that you can share across your own channels.
          </p>
          <p>
            With epic social media assets showcasing the best of New Zealand - through to Air New Zealand brand images and
            logos, we're here to help you sell with confidence.
          </p>
          <p><b>
              To find out what the toolkit is all about, check out the video below.
            </b></p>
        </v-cols>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" class="ma-0 pa-0">
          <iframe class="mx-auto" width="100%" height="300" src="https://www.youtube.com/embed/mckOvzKz0c0"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-wrap justify-center mb-4">
        <v-col cols="12">
          <h1 class="anz-italic text-center">Download the toolkit to get started!</h1>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="12" class="d-flex justify-center text-center">
          <p class="toolkit-paragraph">First step is to download this PDF to understand the Toolkit, then download the five social media content kits and start sharing inspiring content across your own social channels - happy posting!</p>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn @click="trackAndDownload($base + '/asset/file/toolkit/AirNZ Toolkit.pdf', 'Air NZ Toolkit')"
            width="320px" height="50px" color="duoSecondary" :elevation="0" dark>Toolkit download</v-btn>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-wrap  justify-center text-center">
        <v-col cols="12" class="text-center pb-0">
          <h1 class="anz-italic">Content to inspire your customers</h1>
          <v-divider class="mx-auto mt-3" style="width: 450px;"></v-divider>
        </v-col>
        <v-col cols="12" class="d-flex justify-center text-center pt-0">
          <p class="toolkit-paragraph">Whether you're inspiring customers to enjoy a New Zealand getaway, sharing the latest Air New Zealand deals or
            providing some tips before they fly - we've got you covered with five easy-to-use social media content kits.
          </p>
        </v-col>
        <v-col v-for="section in sections" :key="section" class="d-flex flex-column justify-center text-center">
          <v-img class="mx-auto" max-height="283px" :src="$bucket + '/image/toolkit/' + section.image" />
          <div class="mt-4" style="height: 50px; width: 100%;"><span style="width: 134px;" v-html="section.description" />
          </div>
          <v-btn @click="trackAndDownload($base + '/asset/file/toolkit' + section.link, section.title)" width="193px"
            height="50px" outlined color="duoSecondary" :elevation="0" dark class="my-4 mx-auto">Download</v-btn>
        </v-col>
      </v-row>
      
      <v-row class="mb-8">
        <v-col v-for="(tile, index) in tiles" :key="index" cols="12" md="6">
          <v-card v-if="index == 0" tile elevation="0" style="background-color: #2D2927;">
            <v-img :src="$bucket + '/image/toolkit/' + tile.image" height="150" class="d-flex flex-wrap align-end">
            </v-img>

            <div class="d-flex flex-wrap align-end pa-2" style="position: relative;">
              <v-col cols="12" class="pl-4" style="position: absolute; top: -80px;">
                <v-toolbar-title class=" pt-3" style="font-size: 28px; color: white;">
                  <div class="d-flex flex-column" >
                    <span><b>{{ tile.title }}</b></span>
                    <span class="duo-underline"></span>
                  </div>
                </v-toolbar-title>
              </v-col>
              <v-col class="pt-1 pb-0 pl-4" cols="9">
                <span class="duo-description white--text">{{ tile.description }}</span>
              </v-col>
              <v-col cols="12">
                <v-btn @click="trackAndDownload($base + '/asset/file/toolkit' + tile.link, tile.title)" width="193px"
                  height="50px" color="white" :elevation="0" class="my-4 mx-auto">Download</v-btn>
              </v-col>
            </div>
          </v-card>

          <v-card v-else tile elevation="0">
            <v-img :src="$bucket + '/image/toolkit/' + tile.image" height="100%" class="d-flex flex-wrap align-end pa-2">
              <v-col cols="12" class="py-0 pl-4">
                <v-toolbar-title class=" pt-3" style="font-size: 28px; color: white;">
                  <div class="d-flex flex-column" >
                    <span><b>{{ tile.title }}</b></span>
                    <span class="duo-underline"></span>
                  </div>
                </v-toolbar-title>
              </v-col>
              <v-col class="pt-1 pb-0 pl-4" cols="9">
                <span class="duo-description white--text">{{ tile.description }}</span>
              </v-col>
              <v-col cols="12">
                <v-btn @click="trackAndDownload($base + '/asset/file/toolkit' + tile.link, tile.title)" width="193px"
                  height="50px" color="white" :elevation="0" class="my-4 mx-auto">Download</v-btn>
              </v-col>
            </v-img>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>
<style scoped>
.toolkit-paragraph {
  max-width: 900px;
}
</style>
<script>
/* eslint-disable no-console */
import subHeaderMobile from "./subHeaderMobile.vue";

export default {
  components: {
    subHeaderMobile,
  },
  data() {
    return {
      sections: [
        {
          title: "Choose your New Zealand getaway",
          image: "01.Choose.png",
          description: "Interactive travel <br/> polls",
          link: "/Choose your NZ getaway.zip",
        },
        {
          title: "Discover New Zealand",
          image: "02.Discover.png",
          description: "Check out some <br/> New Zealand highlights",
          link: "/Discover NZ.zip",
        },
        {
          title: "Tips on the fly",
          image: "03.Tips.png",
          description: "Customer questions, <br/> answered",
          link: "/Tips onthe fly.zip",
        },
        {
          title: "Reveal a great deal",
          image: "04.LoveaDeal.png",
          description: "Flights and deals",
          link: "/Reveal a great deal.zip",
        },
        {
          title: "Air New Zealand stickers",
          image: "05.Stickers.png",
          description: "Stickers to make your <br/> content standout",
          link: "/Air New Zealand Stickers.zip",
        },
      ],
      tiles: [
        {
          title: "Logo",
          image: "T1.Logo.jpg",
          description: "The Air New Zealand wordmark is our core brand logo - we use it for everything.",
          link: "/Logo.zip",
        },
        {
          title: "Imagery",
          image: "T2.Imagery.jpg",
          description: "We've selected some approved images for you to use.",
          link: "/Imagery.zip",
        }
      ]
    };
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {
    trackAndDownload(url, title) {
      this.$root.saveActivityLog({
        type: "Download_toolkit",
        value: title,
      });
      window.open(url, '_blank');
    },
  },
};
</script>