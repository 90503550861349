<template>
  <v-main
    :style="{ backgroundImage: 'url(https://duonz.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg)', backgroundSize: 'cover', margin: '10px' }"
    fluid>
    <div>
      <duo-training-tile-view v-if="this.$route.query.linkType === 'Training_module'" :entity="this.$route.query"></duo-training-tile-view>
      <duo-video-tile-view v-else-if="this.$route.query.type === 'Video'" :entity="this.$route.query"></duo-video-tile-view>
      <duo-tile-view v-else :entity="this.$route.query"></duo-tile-view>
    </div>
  </v-main>
</template>

<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
import duoTileView from './creator/duoTileView.vue';
import duoTrainingTileView from './creator/duoTrainingTileView.vue';
import duoVideoTileView from './creator/duoVideoTileView.vue';

export default {
  components: {
    duoTileView,
    duoTrainingTileView,
    duoVideoTileView
  },
  data() {
    return {
      entity: this.$route.query
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
  }
};
</script>