<template>
	<v-container fluid>
		<subHeader />
		<div class="d-flex justify-center align-center">
			<v-card width="758">
				<v-card-title style="background-color: #2D2926; color: white">
					<span 
						style="font-size: 28px !important; font-family: 'AirNZ Sans Semibold' !important;margin-left: 20px"
					> Virtual card </span>
				</v-card-title>
				<v-card-text>
					<v-row no-gutters class="d-flex justify-center align-center" style="margin-top: 40px">
						<v-col cols="9" class="fill-height d-flex flex-column">
							<v-row
								no-gutters
								align="center"
								style="font-size: 14px !important; font-family: 'AirNZ Sans Semibold' !important; color: #2D2926; padding-left: 80px"
								>Starts in</v-row
							>
							<v-row class="d-flex justify-center align-center">
								<v-col style="max-width: 145px; padding: 0px">
									<v-row justify="end" style="padding: 0px 15px 0px 0px">
										<v-col class="mr-1 text-center time-square" style="padding: 22px">0</v-col>
										<v-col class="text-center time-square" style="padding: 22px">0</v-col>
									</v-row>
									<v-row justify="center" style="padding: 2px 0; font-size: 14px; font-family: 'AirNZ Sans Semibold'; color: #2D2926"
										>Day</v-row
									>
								</v-col>
								<v-col style="max-width: 145px; padding: 0px">
									<v-row justify="center">
										<v-col class="mr-1 text-center time-square" style="padding: 22px">0</v-col>
										<v-col class="text-center time-square" style="padding: 22px">0</v-col>
									</v-row>
									<v-row justify="center" style="padding: 2px 0; font-size: 14px; font-family: 'AirNZ Sans Semibold'; color: #2D2926"
										>Month</v-row
									>
								</v-col>
								<v-col style="max-width: 145px; padding: 0px 0px 0px 15px">
									<v-row justify="start">
										<v-col class="mr-1 text-center time-square" style="padding: 22px">0</v-col>
										<v-col class="text-center time-square" style="padding: 22px">0</v-col>
									</v-row>
									<v-row justify="center" style="padding: 2px 0; font-size: 14px; font-family: 'AirNZ Sans Semibold'; color: #2D2926"
										>Year</v-row
									>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="d-flex justify-center align-center" style="margin: 45px 0px 20px 0px">
						<v-col cols="8" class="text-center" style="padding: 0px; color: black;font-size: 17px; font-family: 'AirNZ Sans Regular';">
							Join us to learn all about the Air New Zealand
						</v-col>
						<v-col cols="8" class="text-center" style="padding: 0px; color: black; font-size: 17px; font-family: 'AirNZ Sans Regular';"
							>Virtual Card and how to redeem customer credits.</v-col
						>
						<v-col cols="8" class="text-center" style="margin-top: 25px">
							<v-btn class="btn-dashboard" style="text-transform: none; font-size: 17px; font-family: 'AirNZ Sans Regular'" large>Join now</v-btn>.</v-col
						>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
	</v-container>
</template>

<style scoped>
.time-square {
	font-size: 35px !important;
	font-family: "AirNZ Sans Regular" !important;
	background-color: #2D2926;
	color: white;
	max-width: 61px;
	max-height: 61px;
}
.time-square-sm {
	font-size: 25px !important;
	font-family: "AirNZ Sans Regular" !important;
	background-color: #2D2926;
	color: white;
	max-width: 61px;
	max-height: 61px;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 0%;
		margin-right: 0%;
	}
}
@media (max-width: 576px) {
	.info-card {
		padding: 10px 0px !important;
	}
	.v-card__text {
		padding: 5px 10px !important;
	}
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 5%;
		margin-right: 5%;
	}
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 40px;
		margin-left: 0%;
		margin-right: 0%;
	}
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 18%;
		margin-right: 18%;
	}
	.info-row {
		margin: 0 150px !important;
	}
}
@media (min-width: 1680px) {
	.info-row {
		margin: 0 250px !important;
	}
}
@media (min-width: 1881px) {
	.info-row {
		margin: 0 400px !important;
	}
}
</style>
<script>
import subHeader from "./subHeader.vue";

export default {
	components: {
		subHeader
	}
};
</script>