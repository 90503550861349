<template>
	<v-container fluid>
		<subHeaderMobile />

		<v-container style="border: 1px solid #221651 !important;" class="mb-2">
			<div class="d-flex justify-center align-center">
				<div>
					<span style="color: #221651 !important;">For the best Training Experiences,</span> <br />
					<span style="color: #221651 !important;">please use your desktop or laptop.</span>
				</div>
			</div>
		</v-container>

	</v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import subHeaderMobile from "./subHeaderMobile.vue";


export default {
	components: {
		subHeaderMobile,
	},
};
</script>