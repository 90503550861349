<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px; overflow: hidden;">
      <v-row class="d-flex justify-center text-center">
        <iframe
          class="pa-0 ma-o"
          src="https://xd.wayin.com/display/container/dc/13b4e220-a652-4571-866c-ce1f78294dee"
          frameborder="0"
          scrolling="no"
          width="100%"
          :height="$vuetify.breakpoint.mdAndUp ? '2724':'6000'"
          allowTransparency="true"
          :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: -120px;':'margin-top: -260px;'"
        ></iframe>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
