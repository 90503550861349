<template>
	<v-main style="margin: 10px">
		<v-data-table :headers="headers" :items="bdmList" :items-per-page="20" :search="keyword" class="elevation-1">
			<template v-slot:top>
				<v-row no-gutters style="padding: 20px 10px 15px">
					<v-col cols="12" sm="8">
						<div class="d-flex px-2">
							<h2>BDM list</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="4">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details style="margin-right: 10px"></v-text-field>
						</div>
					</v-col>
				</v-row>
			</template>

			<template v-slot:item.states="props" v-if="$store.state.principal.role === 'bdm'">
				<v-row class="text-center my-1">
					<div class="state-badge py-1 px-3 secondary text-no-wrap rounded-pill" v-for="state of props.item.states" :key="state">{{ state }}</div>
				</v-row>
			</template>

			<template v-slot:item.states="props" v-else>
				<v-edit-dialog :return-value.sync="props.item.states" large persistent @save="save" @cancel="cancel" @open="open(props.item)" @close="close">
					<v-row class="text-center my-1">
						<div class="state-badge py-1 px-3 secondary text-no-wrap rounded-pill" v-for="state of props.item.states" :key="state">{{ state }}</div>
						<v-icon v>mdi-plus-circle-multiple-outline</v-icon>
					</v-row>
					<template v-slot:input>
						<div class="mt-4 mb-2 text-h6">Update States</div>
						<v-combobox v-model="props.item.states" :items="stateList" label="BDM States" multiple outlined dense></v-combobox>
					</template>
				</v-edit-dialog>
			</template>
		</v-data-table>
	</v-main>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";

export default {
	data: function() {
		return {
			headers: [
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "name",
					width: "30%"
				},
				{ text: "Email", value: "email", width: "40%" },
				{ text: "States", value: "states", sortable: false, width: "30%" }
			],
			stateList: ["NSW", "ACT", "QLD", "VIC", "SA", "TAS", "WA", "NT"],
			bdmList: [],
			bdm: null,
			keyword: ""
		};
	},
	mounted: function() {
		this.getList();
	},
	methods: {
		getList() {
			axios
				.get("/system/common/admin/list")
				.then(response => {
					// console.log("BDM API", response);
					this.bdmList = response.data;
				})
				.catch(() => {});
		},
		save() {
			// console.log("save update", this.bdm);
			axios
				.post("/system/common/admin/states/edit", { administrator: this.bdm })
				.then(response => {
					// console.log("update state response", response);
				})
				.catch(() => {});
		},
		cancel() {
			// this.snack = true;
			// this.snackColor = "error";
			// this.snackText = "Canceled";
			// console.log("cancel", this.bdm);
		},
		open(value) {
			this.bdm = value;
		},
		close() {
			// 	console.log("Dialog closed");
		}
	}
};
</script>

<style scoped>
.state-badge {
	font-size: 12px;
	color: white;
	letter-spacing: 2px;
	font-weight: medium;
	margin: 4px 5px 4px 0px;
}
</style>